import { IProcedureTemplateItem } from "modules/procedures/types";
import { Heading, InlineHint } from "shared/components/Typography";
import styled from "styled-components";
import { t } from "i18next";
import { pattern } from "@test-data";

interface ItemProps {
  item: IProcedureTemplateItem;
}

const GenericItem = ({ item }: ItemProps) => (
  <div key={item.index}>
    <Heading data-testid={pattern.itemNameId(item.index)}>
      <InlineHint>#{item.index}</InlineHint> - {item.name}
    </Heading>
    <Description>
      {t("procedures.items.type_label")}:{" "}
      <em data-testid={pattern.itemTypeId(item.index)}>
        {t(`procedures.items.types.${item.item_type}`)}
      </em>
    </Description>
  </div>
);

const MultipleChoiceItem = ({ item }: ItemProps) => (
  <div key={item.index}>
    <Heading data-testid={pattern.itemNameId(item.index)}>
      <InlineHint>#{item.index}</InlineHint> - {item.name}
    </Heading>
    <Description>
      <p>
        {t("procedures.items.type_label")}:{" "}
        <em data-testid={pattern.itemTypeId(item.index)}>
          {t(`procedures.items.types.${item.item_type}`)}
        </em>
      </p>
      <p>{t("procedures.items.multiple_choice.header")}:</p>
      <ChoiceList>
        {item?.options?.choices?.map((choice, index) => (
          <Choice
            key={index}
            data-testid={pattern.itemChoiceId(item.index, index + 1)}
          >
            {choice}
          </Choice>
        ))}
      </ChoiceList>
    </Description>
  </div>
);

export const Item = ({ item }: ItemProps) => {
  switch (item.item_type) {
    case "multiple_choice":
      return <MultipleChoiceItem item={item} />;

    default:
      return <GenericItem item={item} />;
  }
};

const ChoiceList = styled.ul`
  margin-left: 1rem;
`;

const Choice = styled.li`
  font-style: italic;
`;

const Description = styled.div`
  color: var(--color_text_color);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 0;
  white-space: pre-wrap;
`;
