import { ReportKey } from "modules/reporting/types/ReportKey";
import { ReactiveVsPreventiveReportChart } from "./ReactiveVsPreventiveReportChart";
import { ReactiveVsPreventiveReportTable } from "./ReactiveVsPreventiveReportTable";
import { useReactiveVsPreventiveReportKPIs } from "./useReactiveVsPreventiveReportKPIs";
import { ReactiveVsPreventiveReportActionBar } from "./ReactiveVsPreventiveReportActionBar";
import { ReportPageLayout } from "modules/reporting/components/ReportPageLayout";
import { ReportPageTopBar } from "modules/reporting/components/ReportPageTopBar";
import { ReportPageProvider } from "modules/reporting/lib/ReportPageContext";
import { useReportPeriod } from "modules/reporting/lib/useReportPeriod";
import { KPIGrid } from "modules/reporting/components/KPIGrid";
import { useGetChartData } from "modules/reporting/lib/useGetChartData";
import {
  ReactiveVsPreventiveChartData,
  ReactiveVsPreventiveTableData,
} from "./types";
import { useGetTableData } from "modules/reporting/lib/useGetTableData";
import {
  PDFReportDownloader,
  PDFReportDownloaderHandle,
} from "modules/reporting/components/PDFReportDownloader/PDFReportDownloader";
import { getReportFilename } from "modules/reporting/lib/getReportFilename";
import { useTranslation } from "react-i18next";
import { ExportMenu } from "modules/reporting/components/ExportMenu";
import { useDownloadReportXLS } from "modules/reporting/lib/useDownloadReportXLS";
import { usePersistentState } from "modules/reporting/lib/usePersistentState";
import { ReportBucket } from "modules/reporting/types/ReportBucket";
import { ChartWithBucketOptionsWrapper } from "modules/reporting/components/ReportBucketOptions";
import { getBucketedChartsList } from "modules/reporting/lib/getBucketedChartsList";
import { useRef } from "react";
import { useFiltersParam } from "shared/lib/hooks";
import { stringifyFilters } from "modules/filters/lib/stringifyFilters";
import { Entity } from "modules/reporting/types/Entity";

const reportKey = ReportKey.ReactiveVsPreventive;
const entity = Entity.workOrders;

export const ReactiveVsPreventiveReport = () => {
  const { t } = useTranslation();
  const [reportPeriod, setReportPeriod] = useReportPeriod();
  const [bucket, setBucket] = usePersistentState<ReportBucket>(
    "report_bucket",
    "day"
  );
  const pdfDownloaderRef = useRef<PDFReportDownloaderHandle>(null);

  const kpiRes = useReactiveVsPreventiveReportKPIs(reportPeriod);
  const chartRes = useGetChartData<ReactiveVsPreventiveChartData[]>(
    reportKey,
    reportPeriod,
    { bucket: "day" }
  );
  const tableRes = useGetTableData<ReactiveVsPreventiveTableData[]>(
    reportKey,
    reportPeriod
  );

  const [filters] = useFiltersParam([]);
  const stringifiedFilters = stringifyFilters(filters, entity);
  const downloadXLS = useDownloadReportXLS(
    reportKey,
    reportPeriod,
    stringifiedFilters
  );

  const isExportReady = !chartRes.isFetching && !kpiRes.isFetching;
  return (
    <ReportPageProvider value={{ reportPeriod, setReportPeriod }}>
      <ReportPageTopBar />
      <ReportPageLayout
        reportKey={reportKey}
        kpis={<KPIGrid isLoading={kpiRes.isFetching} kpis={kpiRes.data} />}
        chart={
          <ChartWithBucketOptionsWrapper {...{ bucket, setBucket }}>
            <ReactiveVsPreventiveReportChart
              bucket={bucket}
              data={chartRes.data}
              isFetching={chartRes.isFetching}
            />
          </ChartWithBucketOptionsWrapper>
        }
        actionBar={
          <ReactiveVsPreventiveReportActionBar
            buttons={
              <ExportMenu
                downloadXLS={() => downloadXLS()}
                downloadPDF={() => pdfDownloaderRef.current?.doDownload()}
                isPDFReady={isExportReady}
              />
            }
            entity={entity}
          />
        }
        table={
          <ReactiveVsPreventiveReportTable
            data={tableRes.data?.data}
            paginationData={tableRes.data?.meta.pagination}
            isFetching={tableRes.isFetching}
          />
        }
      />
      <PDFReportDownloader
        ref={pdfDownloaderRef}
        filename={getReportFilename(reportKey, "pdf", reportPeriod)}
        exportProps={{
          reportName: t(`reporting.${reportKey}.title`),
          reportPeriod,
          kpis: kpiRes.data,
        }}
        charts={getBucketedChartsList(t).map((b) => ({
          title: t(`reporting.${reportKey}.title`) as string,
          subtitle: b.label,
          chart: (
            <div style={{ width: "1000px", height: "420px" }}>
              <ReactiveVsPreventiveReportChart
                bucket={b.bucket}
                data={chartRes.data}
                isFetching={false}
              />
            </div>
          ),
        }))}
      />
    </ReportPageProvider>
  );
};
