import { ReportActionBarProps } from "modules/reporting/types/ReportActionBarProps";
import { ReportActionBar } from "modules/reporting/components/ReportActionBar";
import { useReportFilterOptions } from "modules/reporting/lib/useReportFilterOptions";

export const AssetCostsAndDowntimeReportActionBar = ({
  buttons,
  entity,
}: ReportActionBarProps) => {
  const filterOptions = useReportFilterOptions(entity);
  const filters = [
    filterOptions.name,
    filterOptions.id,
    filterOptions.qrCode,
    filterOptions.assetTypes,
    filterOptions.assignTo,
    filterOptions.warrantyExpDate,
    filterOptions.location,
  ];

  return (
    <ReportActionBar
      filterEntity={entity}
      filters={filters}
      buttons={buttons}
    />
  );
};
