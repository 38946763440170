import { useTranslation } from "react-i18next";
import { useReportPeriod } from "modules/reporting/lib/useReportPeriod";
import { useRef } from "react";
import {
  PDFReportDownloader,
  PDFReportDownloaderHandle,
} from "modules/reporting/components/PDFReportDownloader/PDFReportDownloader";
import { ReportPageProvider } from "modules/reporting/lib/ReportPageContext";
import { ReportPageTopBar } from "modules/reporting/components/ReportPageTopBar";
import { ReportPageLayout } from "modules/reporting/components/ReportPageLayout";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { useAssetCostsAndDowntimeReportKPIs } from "./useAssetCostsAndDowntimeReportKPIs";
import { AssetCostsAndDowntimeReportTable } from "./AssetCostsAndDowntimeReportTable";
import { AssetCostsAndDowntimeReportActionBar } from "./AssetCostsAndDowntimeReportActionBar";
import {
  AssetCostsAndDowntimeReportChart,
  AssetsCostsAndDowntimeReportChartMode,
} from "./AssetCostsAndDowntimeReportChart";
import {
  AssetCostsAndDowntimeChartData,
  AssetCostsAndDowntimeTableData,
} from "./types";
import { KPIGrid } from "modules/reporting/components/KPIGrid";
import { useGetTableData } from "modules/reporting/lib/useGetTableData";
import { useGetChartData } from "modules/reporting/lib/useGetChartData";
import { useDownloadReportXLS } from "modules/reporting/lib/useDownloadReportXLS";
import { ExportMenu } from "modules/reporting/components/ExportMenu";
import { getReportFilename } from "modules/reporting/lib/getReportFilename";
import { usePersistentState } from "modules/reporting/lib/usePersistentState";
import { useFiltersParam } from "shared/lib/hooks";
import { stringifyFilters } from "modules/filters/lib/stringifyFilters";
import { Entity } from "modules/reporting/types/Entity";

const reportKey = ReportKey.AssetCostsAndDowntime;
const entity = Entity.assets;

export const AssetCostsAndDowntimeReport = () => {
  const { t } = useTranslation();
  const [reportPeriod, setReportPeriod] = useReportPeriod();
  const [mode, setMode] =
    usePersistentState<AssetsCostsAndDowntimeReportChartMode>(
      "asset-cost-downtime-mode",
      "cost"
    );

  const pdfDownloaderRef = useRef<PDFReportDownloaderHandle>(null);

  const kpiRes = useAssetCostsAndDowntimeReportKPIs(reportPeriod);
  let tableRes = useGetTableData<AssetCostsAndDowntimeTableData[]>(
    reportKey,
    reportPeriod,
    entity
  );
  let chartRes = useGetChartData<AssetCostsAndDowntimeChartData>(
    reportKey,
    reportPeriod,
    {},
    entity
  );

  const [filters] = useFiltersParam([]);
  const stringifiedFilters = stringifyFilters(filters, entity);
  const downloadXLS = useDownloadReportXLS(
    ReportKey.AssetCostsAndDowntime,
    reportPeriod,
    stringifiedFilters
  );

  const isExportReady = !chartRes.isFetching && !kpiRes.isFetching;
  return (
    <ReportPageProvider value={{ reportPeriod, setReportPeriod }}>
      <ReportPageTopBar />
      <ReportPageLayout
        kpis={<KPIGrid kpis={kpiRes.data} isLoading={kpiRes.isFetching} />}
        chart={
          <AssetCostsAndDowntimeReportChart
            data={chartRes.data}
            isFetching={chartRes.isFetching}
            mode={mode}
            setMode={setMode}
          />
        }
        actionBar={
          <AssetCostsAndDowntimeReportActionBar
            buttons={
              <ExportMenu
                downloadXLS={() => downloadXLS()}
                downloadPDF={() => pdfDownloaderRef.current?.doDownload()}
                isPDFReady={isExportReady}
              />
            }
            entity={entity}
          />
        }
        table={
          <AssetCostsAndDowntimeReportTable
            data={tableRes.data?.data}
            paginationData={tableRes.data?.meta.pagination}
            isFetching={tableRes.isFetching}
          />
        }
        reportKey={reportKey}
      />
      <PDFReportDownloader
        ref={pdfDownloaderRef}
        filename={getReportFilename(reportKey, "pdf", reportPeriod)}
        exportProps={{
          reportName: t(`reporting.${reportKey}.title`),
          reportPeriod,
          kpis: kpiRes.data,
        }}
        charts={[
          {
            title: t(`reporting.${reportKey}.buttons.cost`) ?? "",
            chart: (
              <div style={{ width: "1000px", height: "420px" }}>
                <AssetCostsAndDowntimeReportChart
                  data={chartRes.data}
                  isFetching={false}
                  mode="cost"
                  setMode={() => null}
                />
              </div>
            ),
          },
          {
            title: t(`reporting.${reportKey}.buttons.downtime`) ?? "",
            chart: (
              <div style={{ width: "1000px", height: "420px" }}>
                <AssetCostsAndDowntimeReportChart
                  data={chartRes.data}
                  isFetching={false}
                  mode="downtime"
                  setMode={() => null}
                />
              </div>
            ),
          },
        ]}
      />
    </ReportPageProvider>
  );
};
