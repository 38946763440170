import { TooltipProps } from "recharts";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { formatPercent } from "../../../lib/formatters";
import { useLocaleSettings } from "../../../lib/useLocaleSettings";
import { Payload } from "recharts/types/component/DefaultTooltipContent";

/**
 * Custom Recharts Tooltip content. Props are automatically passed by Recharts.
 *
 * [Recharts documentation](https://recharts.org/en-US/api/Tooltip)
 */
export const BacklogByAssigneeReportWidgetTooltip = ({
  active,
  payload,
  label,
  formatter,
}: TooltipProps<number, string>) => {
  const { t } = useTranslation();
  const { language } = useLocaleSettings();
  if (!active || !payload) {
    return null;
  }

  const buildTooltipRow = (
    payload: Payload<number, string>[],
    key: string,
    formatter?: (value: number) => string
  ) => {
    if (payload.length === 0 || payload[0].payload[key] == null) {
      return null;
    }
    let value: string | number = payload[0].payload[key];
    if (formatter) {
      value = formatter(Number(value));
    }
    return (
      <span key={key}>
        {t(`reporting.wo_by_assignee.tooltip.${key}`, { value: value })}
      </span>
    );
  };

  const percentageFormatter = (value: number) => {
    return formatPercent(value, language);
  };

  const buildOverduePercentRow = (payload: Payload<number, string>[]) => {
    if (payload.length === 0 || !payload[0].payload["active"]) {
      return null;
    }
    const active = payload[0].payload["active"];
    const overdue = payload[0].payload["overdue"] || 0;

    let value: number | string = (overdue * 1.0) / active;
    value = isNaN(value) ? 0 : value;
    value = percentageFormatter(Number(value));
    return (
      <span key={"overdue_percentage"}>
        {t(`reporting.wo_by_assignee.tooltip.overdue_percentage`, {
          value: value,
        })}
      </span>
    );
  };

  return (
    <TooltipWrapper>
      <span>{label}</span>
      {buildTooltipRow(payload, "active")}
      {buildTooltipRow(payload, "overdue")}
      {buildOverduePercentRow(payload)}
    </TooltipWrapper>
  );
};

const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;

  border: 1px solid var(--color_grey200);
  border-radius: 4px;
  background-color: #fff;
  font-size: 14px;

  padding: 8px;
`;
