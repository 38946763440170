import React from "react";
import styled from "styled-components";
import { IconButton } from "shared/components/IconButton";
import CloseIcon from "../../../assets/icon/close.svg?react";
import { useTranslation } from "react-i18next";

export const CloseButton = ({
  variant = "primary",
  background = "dark",
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <IconButtonStyled
      variant={variant}
      background={background}
      {...props}
      aria-label={t(`ariaLabel.button.closeModal`)}
    >
      <CloseIcon />
    </IconButtonStyled>
  );
};

const IconButtonStyled = styled(IconButton)`
  width: 24px;
  height: 24px;
`;
