import { useTranslation } from "react-i18next";
import { EntityType } from "../types/Entity";

interface FilterConfig {
  field: string;
  name: string;
}

type FilterOptions = Record<string, FilterConfig>;

/** Filterable properties for reports */
export function useReportFilterOptions(entity: EntityType): FilterOptions {
  const { t } = useTranslation();

  const filtersByEntity: Record<string, FilterOptions> = {
    assets: {
      name: {
        field: "name",
        name: t("assets.columns.name"),
      },
      id: {
        field: "id",
        name: t("assets.columns.id"),
      },
      qrCode: {
        field: "qr_code",
        name: t("assets.columns.code"),
      },
      assetTypes: {
        field: "asset_types",
        name: t("assets.columns.assetTypes"),
      },
      assignTo: {
        field: "assign_to",
        name: t("assets.columns.assignedTo"),
      },
      warrantyExpDate: {
        field: "warranty_exp_date",
        name: t("assets.columns.warrantyExpDate"),
      },
      location: {
        field: "location",
        name: t("assets.columns.location"),
      },
    },
    parts: {
      partType: {
        field: "part_type",
        name: "Part Type",
      },
      partName: {
        field: "part_name",
        name: "Name",
      },
    },
    work_orders: {
      title: {
        field: "title",
        name: t("work_orders.columns.title"),
      },
      id: {
        field: "id",
        name: t("work_orders.columns.id"),
      },
      status: {
        field: "status",
        name: t("work_orders.columns.status"),
      },
      priority: {
        field: "priority",
        name: t("work_orders.columns.priority"),
      },
      createdAt: {
        field: "created_at",
        name: t("work_orders.columns.createdAt"),
      },
      type: {
        field: "type",
        name: t("work_orders.columns.type"),
      },
      assignTo: {
        field: "assign_to",
        name: t("work_orders.columns.assignedTo"),
      },
      categories: {
        field: "categories",
        name: t("work_orders.columns.categories"),
      },
      location: {
        field: "location",
        name: t("work_orders.columns.location"),
      },
      asset: {
        field: "asset",
        name: t("work_orders.columns.asset"),
      },
      vendors: {
        field: "vendors",
        name: t("work_orders.columns.vendors"),
      },
      createdBy: {
        field: "created_by_name",
        name: t("work_orders.columns.createdBy"),
      },
      dueDate: {
        field: "due_date",
        name: t("work_orders.columns.dueDate"),
      },
      completedAt: {
        field: "completed_at",
        name: t("work_orders.columns.completedAt"),
      },
    },
  };

  return filtersByEntity[entity];
}
