import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { H1 } from "shared/components/Typography";
import { Button } from "shared/components/Button";
import Arrow from "shared/assets/icon/arrow.svg?react";
import { propTypesFormik } from "shared/lib/hooks/useForm";
import { Search } from "widgets/Header/components/Search";
import { Link } from "../Link";
import AddIcon from "shared/assets/icon/add.svg?react";
import { heading } from "@test-data";

const ElementsMap = {
  form: { title: true, search: false, save: true, discard: true, add: false },
  table: { title: true, search: true, save: false, discard: false, add: true },
  view: {
    title: true,
    search: false,
    save: false,
    discard: false,
    add: false,
  },
};

export const AdminSettingsFormHeader = ({
  title,
  onSubmit,
  onCancel,
  form,
  onClose,
  module,
  type,
}) => {
  const { t } = useTranslation();

  const returnHandler = () => {
    if (onClose) onClose();
  };

  return (
    <Box>
      <ArrowBox>
        <ButtonStyled onClick={returnHandler} variant="secondary">
          <Arrow />
        </ButtonStyled>
        <Title data-testid={heading.manageModule.id}>{title}</Title>
      </ArrowBox>

      {ElementsMap[type].search && (
        <SearchBox>
          <Search />
        </SearchBox>
      )}

      <ActionBlock>
        <>
          {ElementsMap[type].discard && (
            <Button onClick={onCancel} variant="secondary">
              {t("discardChanges")}
            </Button>
          )}
          {ElementsMap[type].save && (
            <Button
              onClick={onSubmit}
              disabled={!form.dirty}
              variant="primaryDark"
            >
              {t("save")}
            </Button>
          )}
          {ElementsMap[type].add && (
            <CustomLink
              type="button"
              icon={<AddIcon />}
              to={`/admin-settings/manage-modules/${module}/add`}
            >
              {t(`settings.referenceLists.${module}.add`)}
            </CustomLink>
          )}
        </>
      </ActionBlock>
    </Box>
  );
};

AdminSettingsFormHeader.propTypes = {
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  form: propTypesFormik,
};

const Box = styled.header`
  position: sticky;
  background: var(--color_header_background);
  top: 0;
  right: 0;
  border-bottom: 1px solid var(--color_header_border);
  align-items: center;
  z-index: 2;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 24px 40px 12px 40px;
  margin-right: 40px;
  width: 100%;
`;

const Title = styled(H1)`
  font-size: 32px;
  font-weight: 600;
  line-height: 37px;
  letter-spacing: -0.02em;
  color: var(--color_header_title_color);
  margin: 0;
  padding: 0;
`;

const ArrowBox = styled.div`
  display: flex;
  gap: 16px;
`;

const SearchBox = styled.div`
  margin: auto;
`;

const ActionBlock = styled.div`
  display: flex;
  & > button {
    margin-right: 8px;
  }
  & > button:last-child {
    margin-right: 0;
  }
`;

const ButtonStyled = styled(Button)`
  justify-content: center;
  align-items: center;
  padding: 12px 8px;
  width: 32px;
  height: 40px;
  background: var(--color_background_light);
  border: 1px solid var(--color_grey100);
  border-radius: 4px;
`;

const CustomLink = styled(Link)`
  border-radius: 10px;
`;
