import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import QRCodeStyling from "qr-code-styling";
import logo from "../../assets/qr-logo.svg?url";
import { commonQrCodePropTypes } from "./propTypes";

const options = {
  type: "svg",
  image: logo,
  qrOptions: {
    errorCorrectionLevel: "H",
  },
  imageOptions: {
    margin: 3,
    imageSize: 0.5,
    hideBackgroundDots: true,
  },
};

export const LogoQrCode = memo(({ data, size }) => {
  const ref = useRef(null);

  const optionsData = useMemo(
    () => ({
      ...options,
      data,
      width: size ?? 150,
      height: size ?? 150,
    }),
    [data, size]
  );

  const [qrCode] = useState(new QRCodeStyling(optionsData));

  useEffect(() => {
    if (ref.current) {
      qrCode.append(ref.current);
    }
  }, [qrCode, ref]);

  useEffect(() => {
    if (!qrCode) {
      return;
    }

    qrCode.update(optionsData);
  }, [qrCode, optionsData]);

  return <div ref={ref} />;
});

LogoQrCode.propTypes = commonQrCodePropTypes;
