import {
  getUserFormatForService,
  useUpdateUserDetailsMutation,
} from "modules/users";

import { useState } from "react";

import { useTranslation } from "react-i18next";
import { Button } from "shared/components/Button";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";

import {
  Name,
  NameContainer,
  UserInfoContainer as Container,
  Box,
  FunctionalityLine,
} from "../styled";
import { Modal } from "./Modal";
import { UserAvatar } from "shared/components/UserAvatar";
import Skeleton from "react-loading-skeleton";

export const PersonalInfo = ({ user, isLoading, form }) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [updateUserDetails] = useUpdateUserDetailsMutation();
  const { addSnackBar } = useSnackBar();

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    form.resetForm();
    setShowModal(false);
  };

  const handleSubmit = async () => {
    const userData = getUserFormatForService(form.values);

    updateUserDetails({
      id: user.id,
      body: userData,
    })
      .unwrap()
      .then(() => {
        setShowModal(false);
        addSnackBar({
          content: t("profile.personalInfo.successUpdate"),
          type: "success",
        });
      })
      .catch(({ message: messages }) => {
        // Multiple error messages can be included in the response message, so we split them up for individual handling.
        let unhandledErrors = false;
        Object.entries(messages).forEach(([field, message]) => {
          if ("cannot contain a URL" === message[0]) {
            form.setFieldError(field, t("m111", { ns: "errors" }));
          } else {
            unhandledErrors = true;
          }
        });

        if (true === unhandledErrors) {
          // If any errors weren't handled, alert the user with a generic toast.
          addSnackBar({
            content: JSON.stringify(messages),
            type: "error",
            secondaryButtonLabel: t("close"),
          });
        }
      });
  };

  return (
    <>
      <Box>
        <FunctionalityLine>
          <Container>
            {isLoading ? (
              <Skeleton circle width={96} height={96} />
            ) : (
              <UserAvatar user={user} size="xxl" />
            )}
            <NameContainer>
              <Name>{user?.full_name}</Name>
            </NameContainer>
          </Container>
          <Button
            variant="secondary"
            onClick={handleOpenModal}
            disabled={isLoading}
          >
            {t("profile.editPersonalInfo")}
          </Button>
        </FunctionalityLine>
      </Box>
      <Modal
        show={showModal}
        form={form}
        onClose={handleCloseModal}
        handleSubmit={handleSubmit}
      />
    </>
  );
};
