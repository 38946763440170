import styled, { css } from "styled-components";
import { LabeledQrCode } from "shared/components/QrCode";

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;
`;

export const LastUpdated = styled.span`
  color: var(--color_background);
  opacity: 0.5;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin-left: auto;
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  gap: 16px;
  margin-top: 18px;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: initial;
  column-gap: 8px;
  align-items: ${({ multiline }) => (multiline ? "flex-start" : "center")};

  & > *:nth-child(odd) {
    flex-basis: 110px;
    flex-grow: 0;
    flex-shrink: 0;
    ${({ multiline }) =>
      multiline
        ? css`
            margin-top: 4px;
          `
        : null}
  }

  & > *:nth-child(even) {
    flex-grow: 1;
    flex-shrink: 1;
  }

  & > span:nth-child(odd) {
    color: var(--color_grey500);
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  }

  & > span:nth-child(even) {
    color: var(--color_grey900);
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }
`;

export const LabeledQRCodeStyled = styled(LabeledQrCode)`
  margin-top: 20px;
  margin-left: auto;
  flex-grow: 0;
  flex-shrink: 0;
`;
