import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import SwitchCheckmarkIcon from "../../assets/switch-checkmark.svg?react";
import SwitchXIcon from "../../assets/switch-x.svg?react";

export const Switch = (props) => {
  const {
    className,
    activated,
    disabled,
    onClick = () => null,
    children,
    activateIcon,
    nonActivateIcon,
    isLoading,
    dataTestId,
    ariaLabel,
  } = props;

  const activatedIcon = activateIcon ?? <SwitchCheckmarkIcon />;
  const nonActivatedIcon = nonActivateIcon ?? <SwitchXIcon />;

  let icon;
  if (isLoading) {
    icon = <Loader />;
  } else if (activated) {
    icon = activatedIcon;
  } else icon = nonActivatedIcon;

  return (
    <Box className={className}>
      <Button
        activated={activated}
        disabled={disabled}
        onClick={onClick}
        aria-pressed={activated ? "true" : "false"}
        aria-label={ariaLabel}
        data-testid={dataTestId}
      >
        <div>{icon}</div>
      </Button>
      {children && <span>{children}</span>}
    </Box>
  );
};

Switch.propTypes = {
  className: PropTypes.string,
  activated: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.string,
  activateIcon: PropTypes.element,
  nonActivateIcon: PropTypes.element,
  dataTestId: PropTypes.string,
  ariaLabel: PropTypes.string,
};

Switch.defaultProps = {
  className: "",
  activated: false,
  disabled: false,
  activateIcon: null,
  nonActivateIcon: null,
  dataTestId: "",
  ariaLabel: "",
};

const Box = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    color: var(--color_grey900);
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
  }
`;

const Button = styled.button`
  border-radius: 14px;
  width: 40px;
  min-width: 40px;
  height: 24px;
  position: relative;

  > div:first-of-type {
    background-color: var(--color_background_light);
    border-radius: 100%;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${({ activated }) =>
    activated
      ? css`
          background-color: var(--color_primary);
          color: var(--color_primary_text);

          &:hover {
            background-color: var(--color_primary_dark);
          }

          div {
            right: 2px;
          }
        `
      : css`
          background-color: var(--color_grey500);
          color: var(--color_grey500);

          &:hover {
            background-color: var(--color_grey700);
          }

          div {
            left: 2px;
          }
        `}

  ${({ disabled, activated }) =>
    disabled &&
    css`
      &,
      &:hover {
        background-color: ${activated
          ? "var(--color_primary_dark_40)"
          : "var(--color_grey200)"};
        color: ${activated
          ? "var(--color_primary_dark_40)"
          : "var(--color_grey200)"};
      }

      &:hover {
        cursor: default;
      }
    `}
`;

const Loader = styled.div`
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top: 3px solid #767676;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  animation: spin 0.6s linear infinite;
`;
