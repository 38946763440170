import React from "react";
import { useTranslation } from "react-i18next";
import { Text, View, Image } from "@react-pdf/renderer";

import { pageStyle } from "shared/components/BulkPdfExport/BulkPdfExport.styled.js";
import { useDisplayDate, useGetLocaleSettings } from "shared/lib/hooks";
import {
  formatCurrency,
  formatDateWithDayOfWeek,
} from "shared/lib/helpers/common";
import { getRepeatsText } from "modules/preventiveMaintenances";
import { getTimes } from "../../../workOrderTime/lib/helpers";

import DocumentIcon from "shared/assets/icon/pdf/document.png";
import CompletedIcon from "shared/assets/icon/pdf/completed.png";
import HoldIcon from "shared/assets/icon/pdf/hold.png";
import OpenIcon from "shared/assets/icon/pdf/open.png";
import ProgressIcon from "shared/assets/icon/pdf/progress.png";
import ReviewIcon from "shared/assets/icon/pdf/review.png";
import WaitingCostIcon from "shared/assets/icon/pdf/waitingCost.png";
import WaitingInvoiceIcon from "shared/assets/icon/pdf/waitingInvoice.png";
import WaitingPartsIcon from "shared/assets/icon/pdf/waitingParts.png";

import CriticalIcon from "shared/assets/icon/pdf/critical.png";
import HighIcon from "shared/assets/icon/pdf/high.png";
import LowIcon from "shared/assets/icon/pdf/low.png";
import MediumIcon from "shared/assets/icon/pdf/medium.png";
import NoneIcon from "shared/assets/icon/pdf/none.png";
import { PDFChecklist } from "shared/components/PDFChecklist";
import { useGetProcedureItemValue } from "shared/lib/hooks/useGetProcedureItemValue";

const StatusMap = {
  open: OpenIcon,
  on_hold: HoldIcon,
  completed: CompletedIcon,
  in_review: ReviewIcon,
  in_progress: ProgressIcon,
  waiting_for_cost: WaitingCostIcon,
  waiting_for_parts: WaitingPartsIcon,
  waiting_for_invoice: WaitingInvoiceIcon,
};

const PriorityMap = {
  none: NoneIcon,
  low: LowIcon,
  medium: MediumIcon,
  high: HighIcon,
  critical: CriticalIcon,
};

export const WorkOrdersPdfPage = ({ resource = {} }) => {
  const { t } = useTranslation();
  const displayDate = useDisplayDate();
  const { currency } = useGetLocaleSettings();
  const getProcedureItemValue = useGetProcedureItemValue();

  const {
    title: name,
    id,
    asset,
    description,
    status,
    location,
    due_date,
    created_at,
    type,
    priority,
    images = [],
    documents = [],
    categories = [],
    technicians = [],
    vendors = [],
    comments = [],
    checklist,
    updated_at,
    created_by,
    work_order_request,
    pm_schedule,
    parts_transactions,
    work_order_times,
    other_costs,
    procedures,
  } = resource;

  const files = images
    .map((file) => ({ ...file, isImage: true }))
    .concat(documents);

  const partsTransactions = parts_transactions.map((val) => ({
    ...val,
    part: { ...val.part, label: val.part.name, value: val.part.id },
  }));

  const StatusIcon = StatusMap[status?.name];
  const PriorityIcon = PriorityMap[priority];

  const createdByName =
    created_by && `${created_by.name || ""} ${created_by.surname || ""}`;
  const created = created_at && `on ${displayDate(created_at)}`;

  const worName =
    work_order_request &&
    `${work_order_request?.created_by.name || ""} ${
      work_order_request?.created_by.surname || ""
    }`;
  const worCreated =
    work_order_request && `on ${displayDate(work_order_request.created_at)}`;
  const worNumber =
    work_order_request && work_order_request?.created_by.phone_number;
  const worEmail = work_order_request && work_order_request?.created_by.email;

  let repeatText = "";
  if (pm_schedule) {
    repeatText =
      pm_schedule.type === "one_time"
        ? t(
            "work_orders.form.pm_schedule_attributes.information.work_order_detail",
            {
              value: pm_schedule.next_order_date
                ? formatDateWithDayOfWeek(pm_schedule.next_order_date, true)
                : "",
            }
          )
        : getRepeatsText({
            repeat_occurrence: pm_schedule.repeat_occurrence,
            repeat_frequency: pm_schedule.repeat_frequency,
            on_month_day: pm_schedule.on_month_day,
            on_week_days: pm_schedule.on_week_days,
            in_months: pm_schedule.in_months,
            scheduleType: pm_schedule.type,
          });
  }
  return (
    <>
      <View style={pageStyle.row}>
        <div style={pageStyle.row}>
          <Image src={PriorityIcon} style={pageStyle.icon} />
          <Text style={{ ...pageStyle.smallText, ...pageStyle.boldText }}>
            Priority {t(`work_orders.priority.${priority}`)}
          </Text>
        </div>
        <Text style={{ ...pageStyle.smallText, ...pageStyle.boldText }}>
          ID {id}
        </Text>
      </View>
      <div style={pageStyle.section}>
        <View style={pageStyle.description}>
          <Text style={pageStyle.h3}>{name}</Text>
          <Text style={pageStyle.mediumText}>{description}</Text>
        </View>
      </div>
      <View style={pageStyle.border} />
      <div style={pageStyle.section}>
        <View style={pageStyle.row}>
          <Text style={pageStyle.h4}>Details</Text>
          <Text style={pageStyle.tinyText}>
            Last update on {displayDate(updated_at)}
          </Text>
        </View>
        <div style={pageStyle.detailColumns}>
          <div style={pageStyle.detailColumn}>
            <View style={pageStyle.detail}>
              <Text style={pageStyle.tinyText}>Status</Text>
              <div style={pageStyle.row}>
                <View style={pageStyle.row}>
                  <Image src={StatusIcon} style={pageStyle.icon} />
                  <Text style={pageStyle.boldText}>
                    {status?.name && t(`work_orders.status.${status?.name}`)}
                  </Text>
                </View>
              </div>
            </View>
            <View style={pageStyle.detail}>
              <Text style={pageStyle.tinyText}>Work Type</Text>
              <Text style={pageStyle.boldText}>
                {" "}
                {type && t(`work_orders.type.${type}`)}
              </Text>
            </View>
            <View style={pageStyle.detail}>
              <Text style={pageStyle.tinyText}>Starting from</Text>
              <Text style={pageStyle.boldText}>{displayDate(created_at)}</Text>
            </View>
            <View style={pageStyle.detail}>
              <Text style={pageStyle.tinyText}>Due Date</Text>
              <Text style={pageStyle.boldText}>
                {displayDate(due_date, true)}
              </Text>
            </View>
            <View style={pageStyle.detail}>
              <Text style={pageStyle.tinyText}>Category</Text>
              <Text style={pageStyle.boldText}>
                {categories.map(({ name }) => name).join(", ")}
              </Text>
            </View>
          </div>
          <div style={pageStyle.detailColumn}>
            <View style={pageStyle.detail}>
              <Text style={pageStyle.tinyText}>Location</Text>
              <Text style={pageStyle.boldText}>{location?.path}</Text>
            </View>
            <View style={pageStyle.detail}>
              <Text style={pageStyle.tinyText}>Asset</Text>
              <Text style={pageStyle.boldText}>{asset?.name}</Text>
            </View>
            <View style={pageStyle.detail}>
              <Text style={pageStyle.tinyText}>Assigned to</Text>
              <Text style={pageStyle.boldText}>
                {technicians
                  .map(({ name, surname }) => `${name} ${surname}`)
                  .join(", ")}
              </Text>
            </View>
            <View style={pageStyle.detail}>
              <Text style={pageStyle.tinyText}>Created</Text>
              <Text style={pageStyle.boldText}>{createdByName}</Text>
              <Text>{created}</Text>
              <Text>{worCreated}</Text>
            </View>
          </div>
          <div style={pageStyle.detailColumn}>
            <View style={pageStyle.detail}>
              <Text style={pageStyle.tinyText}>Vendor</Text>
              {vendors.map((vendor) => (
                <View>
                  <Text style={pageStyle.boldText}>{vendor.name}</Text>
                  {vendor.vendor_contacts.map((contact) => (
                    <View>
                      {!!contact.is_primary && (
                        <View>
                          <Text>{contact?.name}</Text>
                          <Text>{contact?.phone}</Text>
                          <Text>{contact?.email}</Text>
                        </View>
                      )}
                    </View>
                  ))}
                </View>
              ))}
            </View>
            <View style={pageStyle.detail}>
              <Text style={pageStyle.tinyText}>Schedule</Text>
              <Text style={pageStyle.boldText}>
                {pm_schedule && `PM #${pm_schedule.id}`}
              </Text>
              <Text>{repeatText}</Text>
            </View>
            {work_order_request && (
              <View style={pageStyle.detail}>
                <Text style={pageStyle.tinyText}>Requested by</Text>
                <Text style={pageStyle.boldText}>{worName}</Text>
                <Text>{worCreated}</Text>
                <Text>{worNumber}</Text>
                <Text>{worEmail}</Text>
              </View>
            )}
          </div>
        </div>
      </div>
      {files.length > 0 && (
        <>
          <View style={pageStyle.border} />
          <div style={pageStyle.section}>
            <Text style={pageStyle.h4}>Media and files attached</Text>
            <div style={pageStyle.detail}>
              <View style={pageStyle.imageRow}>
                {images.map((image) => (
                  <Image src={image.url} style={pageStyle.image} />
                ))}
                {documents.map((file) => (
                  <View style={pageStyle.documentIcon}>
                    <Image src={DocumentIcon} style={pageStyle.logo} />
                    <Text style={pageStyle.tinyText}>{file.name}</Text>
                  </View>
                ))}
              </View>
            </div>
          </div>
        </>
      )}
      {(comments.length > 0 || (checklist && checklist.length > 0)) && (
        <View style={pageStyle.border} />
      )}
      <div style={pageStyle.section}>
        <div style={pageStyle.commentsColumns}>
          {comments.length > 0 && (
            <div style={pageStyle.commentsColumn}>
              <Text style={pageStyle.h4}>
                {comments.length} Comments (oldest first)
              </Text>
              {comments.map((comment) => (
                <View style={pageStyle.row}>
                  <View style={pageStyle.detail}>
                    <View style={pageStyle.row}>
                      <Text style={pageStyle.boldText}>
                        {`${comment?.created_by.name} ${comment?.created_by.surname}`}{" "}
                        {comment.updated_by_id &&
                          t("work_orders.comments.edited")}
                      </Text>
                      <Text
                        style={{ ...pageStyle.tinyText, ...pageStyle.greyText }}
                      >
                        {displayDate(comment.created_at)}
                      </Text>
                    </View>
                    <Text style={pageStyle.comment}>{comment.content}</Text>
                  </View>
                </View>
              ))}
            </div>
          )}
          {checklist && checklist.length > 0 && (
            <div style={pageStyle.commentsColumn}>
              <Text style={pageStyle.h4}>
                {t("work_orders.details.checklist")}
              </Text>
              <PDFChecklist items={checklist} />
            </div>
          )}
        </div>
      </div>
      {work_order_times.length > 0 && (
        <>
          <View style={pageStyle.border} />
          <div style={pageStyle.section}>
            <Text style={pageStyle.h4}>{t("work_order_time.title")}</Text>
            <div style={pageStyle.detail}>
              <View style={pageStyle.detail}>
                {work_order_times.map(
                  ({
                    amount,
                    amount_in_minutes,
                    created_at,
                    user,
                    total_time_cost,
                  }) => (
                    <View style={pageStyle.row}>
                      <Text>{user?.full_name}</Text>
                      <Text>
                        {displayDate(
                          created_at ? created_at : new Date(),
                          true
                        )}
                      </Text>
                      <Text>
                        {amount_in_minutes
                          ? getTimes(amount_in_minutes)
                          : amount}
                      </Text>
                      <Text>{formatCurrency(total_time_cost, currency)}</Text>
                    </View>
                  )
                )}
              </View>
            </div>
          </div>
        </>
      )}
      {partsTransactions.length > 0 && (
        <>
          <View style={pageStyle.border} />
          <div style={pageStyle.section}>
            <Text style={pageStyle.h4}>{t("work_order_parts.title")}</Text>
            <div style={pageStyle.detail}>
              <View style={pageStyle.detail}>
                {partsTransactions.map(({ part, quantity }) => (
                  <View style={pageStyle.row}>
                    <Text>{part.label}</Text>
                    <Text>
                      {quantity} {part.measurement_unit?.short_name || "unit"}
                    </Text>
                  </View>
                ))}
              </View>
            </div>
          </div>
        </>
      )}
      {other_costs.length > 0 && (
        <>
          <View style={pageStyle.border} />
          <div style={pageStyle.section}>
            <Text style={pageStyle.h4}>
              {t("work_order_other_costs.title")}
            </Text>
            <div style={pageStyle.detail}>
              <View style={pageStyle.detail}>
                {other_costs.map(({ title, vendor, cost }) => (
                  <View style={pageStyle.row}>
                    <Text>{title}</Text>
                    <Text>{vendor?.label || vendor?.name || ""}</Text>
                    <Text>{formatCurrency(cost, currency)}</Text>
                  </View>
                ))}
              </View>
            </div>
          </div>
        </>
      )}
      {procedures?.length > 0 && (
        <>
          <View style={pageStyle.border} />
          <div style={pageStyle.section}>
            <div style={pageStyle.procedures}>
              <Text style={pageStyle.h4}>Procedures</Text>
              {procedures.map(({ id, name, items, completed_at }) => (
                <div style={pageStyle.procedureDetails}>
                  <div>
                    <View style={pageStyle.row} key={id}>
                      <Text style={pageStyle.h4}>{name}</Text>
                      {completed_at != null ? (
                        <Text style={pageStyle.mediumText}>
                          {t("procedures.completed_at", {
                            date: displayDate(completed_at, true),
                          })}
                        </Text>
                      ) : (
                        <Text style={pageStyle.mediumText}>
                          {t("procedures.not_completed")}
                        </Text>
                      )}
                    </View>
                  </div>
                  <div style={pageStyle.procedureItems}>
                    {items.map((item) => (
                      <div>
                        <Text style={pageStyle.h5}>{item.name}</Text>
                        <Text style={pageStyle.mediumText}>
                          {getProcedureItemValue(item, t)}
                        </Text>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};
