import { useCallback, useState } from "react";
import styled from "styled-components";

import { Button } from "shared/components/Button";
import { ConfirmationDialog } from "shared/components/Dialog";
import { Hint } from "shared/components/Typography";
import { IProcedure, IProcedureItem } from "modules/procedures/types";

import CheckIcon from "shared/assets/Check.svg?react";
import GreenCheckCircleIcon from "shared/assets/icon/green-checked-circle.svg?react";
import EmptyCheckCircleIcon from "shared/assets/icon/empty-checked-circle.svg?react";

import { useCaptureExceptionWithBreadcrumb } from "shared/lib/hooks";
import {
  useDestroyProcedureMutation,
  useGetProcedureQuery,
  useReopenProcedureMutation,
} from "modules/procedures/state/proceduresApi";
import { usePermission } from "app/providers/PermissionsProvider";
import { useSetModal } from "widgets/ModalController";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { useTranslation } from "react-i18next";
import {
  Disclosure,
  DisclosureGroup,
  Button as AriaButton,
  DisclosurePanel,
  Heading,
} from "react-aria-components";
import { useGetProcedureItemValue } from "shared/lib/hooks/useGetProcedureItemValue";
import { ContextMenu, ContextMenuEntry } from "shared/components/ContextMenu";

interface ProcedureProps {
  procedure: IProcedure;
  workOrder: { id: string | number; technicians: { id: string | number }[] };
  "data-testid": string | number;
}

export const Procedure = ({
  procedure,
  workOrder,
  "data-testid": dataTestId,
}: ProcedureProps) => {
  const setModal = useSetModal();
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();
  const {
    procedureDeletePermit,
    procedureItemUpdatePermit,
    procedureCompletePermit,
  } = usePermission() as unknown as {
    procedureCompletePermit: (
      compareUserId: string | number,
      technicians: unknown[]
    ) => boolean;
    procedureItemUpdatePermit: (compareUserId: string | number) => boolean;
    procedureDeletePermit: (compareUserId: string | number) => boolean;
  };
  const captureException = useCaptureExceptionWithBreadcrumb({
    showGenericErrorSnack: true,
  });
  const [destroyProcedure] = useDestroyProcedureMutation();
  const getProcedureItemValue = useGetProcedureItemValue();
  const [reopenProcedure] = useReopenProcedureMutation();

  const { technicians } = workOrder || {};

  const { data } = useGetProcedureQuery(procedure.id) as { data: IProcedure };
  const { items } = data || {};

  const [procedureToDelete, setProcedureToDelete] =
    useState<IProcedure | null>();

  const allowDelete = useCallback(
    (createdById: number | string) => procedureDeletePermit(createdById),
    [procedureDeletePermit]
  );

  const allowReopen = useCallback(
    (createdById: number | string) =>
      procedureCompletePermit(createdById, technicians),
    [procedureCompletePermit, technicians]
  );

  const canUpdateProcedure = useCallback(
    (procedure: IProcedure) => {
      return (
        procedureItemUpdatePermit as (
          createdById: string | number,
          workOrderTechnicians: unknown[]
        ) => boolean
      )(procedure.created_by_id, technicians);
    },
    [procedureItemUpdatePermit, technicians]
  );

  const onProcedureDestroy = async (id: number | string) => {
    try {
      await destroyProcedure(id).unwrap();
      addSnackBar({
        title: t("procedures.snackbars.procedure_destroyed.success.title"),
        type: "success",
      });
    } catch (e) {
      captureException(e, "Failed to delete procedure", { procedure_id: id });
    }

    setProcedureToDelete(null);
  };

  const onProcedureReopen = async () => {
    if (procedure == null) return;

    try {
      const res = await reopenProcedure(procedure.id).unwrap();
      console.dir(res);
    } catch (e) {
      console.error(e);
    }
  };

  const renderItem = useCallback(
    (item: IProcedureItem) => {
      const value = getProcedureItemValue(item);
      return value as string;
    },
    [getProcedureItemValue]
  );

  const itemHasValue = useCallback(
    (item: IProcedureItem) => {
      const value = getProcedureItemValue(item);

      return value != null && value !== t("procedures.items.checkbox.false");
    },
    [getProcedureItemValue, t]
  );

  return (
    <>
      <li key={procedure?.id} data-testid={dataTestId}>
        <DisclosureGroup>
          <StyledDisclosure id={procedure?.id}>
            <ProcedureEntry>
              <Heading level={4} style={{ fontWeight: "normal", margin: 0 }}>
                <AriaButton
                  slot="trigger"
                  style={{ fontWeight: "normal", outline: "none" }}
                >
                  <svg viewBox="0 0 24 24">
                    <path d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                  </svg>{" "}
                  {procedure?.name}
                </AriaButton>
              </Heading>
              <FlexContainer>
                {procedure?.completed_at == null && (
                  <Button
                    onClick={() =>
                      setModal({
                        type: canUpdateProcedure(procedure)
                          ? "procedure"
                          : "procedureTemplate",
                        id: canUpdateProcedure(procedure)
                          ? procedure.id
                          : procedure.procedure_template_id,
                      })
                    }
                  >
                    {t("procedures.view_procedure")}
                  </Button>
                )}
                {procedure?.completed_at && (
                  <>
                    <Hint>{t("completed")}</Hint>
                    <CheckIcon />
                    <ContextMenu
                      buttonSlot={<Button variant="secondary">...</Button>}
                    >
                      <ContextMenuEntry
                        onClick={onProcedureReopen}
                        disabled={!allowReopen(procedure?.created_by_id)}
                      >
                        Reopen procedure
                      </ContextMenuEntry>
                    </ContextMenu>
                  </>
                )}
                {procedure?.completed_at == null && (
                  <>
                    <ContextMenu
                      buttonSlot={<Button variant="secondary">...</Button>}
                    >
                      <ContextMenuEntry
                        onClick={() => setProcedureToDelete(procedure)}
                        disabled={!allowDelete(procedure?.created_by_id)}
                      >
                        Delete procedure
                      </ContextMenuEntry>
                    </ContextMenu>
                  </>
                )}
              </FlexContainer>
            </ProcedureEntry>
            <DisclosurePanel>
              <ul>
                {items?.map((item: IProcedureItem) => {
                  const hasValue = itemHasValue(item);

                  return (
                    <Item $hasValue={hasValue} key={item.id}>
                      {hasValue ? (
                        <GreenCheckCircleIcon />
                      ) : (
                        <EmptyCheckCircleIcon />
                      )}
                      <span>{item.name}</span>
                      <ItemValue>{renderItem(item)}</ItemValue>
                    </Item>
                  );
                })}
              </ul>
            </DisclosurePanel>
          </StyledDisclosure>
        </DisclosureGroup>
      </li>

      {procedureToDelete != null && (
        <ConfirmationDialog
          title={t("procedures.delete_dialog.confirm_title")}
          description={t("procedures.delete_dialog.confirm_description")}
          confirmButtonLabel={
            t("procedures.delete_dialog.confirm_button") as string
          }
          cancelButtonLabel={t("cancel") as string}
          onProceed={() => onProcedureDestroy(procedureToDelete.id)}
          onCancel={() => setProcedureToDelete(null)}
          onClose={() => setProcedureToDelete(null)}
          isDestructive
        />
      )}
    </>
  );
};

const Item = styled.li<{ $hasValue?: boolean }>`
  display: grid;
  align-items: center;
  grid-template-columns: 16px 1fr 2fr;
  gap: 1rem;
  padding: 0.75rem 0;
  border-bottom: 1px solid var(--color_grey100);
  filter: ${(props) => (props.$hasValue ? "none" : "opacity(0.5)")};
`;

const ItemValue = styled.strong`
  text-align: right;
  font-weight: 500;
`;

const ProcedureEntry = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
`;

const StyledDisclosure = styled(Disclosure)`
  .react-aria-Button[slot="trigger"] {
    background: none;
    border: none;
    box-shadow: none;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      rotate: 0deg;
      transition: rotate 200ms;
      width: 12px;
      height: 12px;
      fill: none;
      stroke: currentColor;
      stroke-width: 3px;
    }
  }

  &[data-expanded] .react-aria-Button[slot="trigger"] svg {
    rotate: 90deg;
  }
`;
