/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useMemo } from "react";
import ReactSelect from "react-select";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  SelectStyles,
  ThemeStyles,
  ErrorMessage,
} from "./SelectDropdownStyled";

import { Control } from "./Control";
import { ClearIndicator } from "./ClearIndicator";
import { Option, NoOptionsMessage } from "./Option";

export const Select = ({
  options,
  setValue,
  value,
  isMulti,
  name,
  errorMessage,
  menuPlacement = "auto",
  noOptionsMessage,
  onBlur,
  onSearch,
  placeholder,
  onTouch,
  isSearchable = true,
  isClearable = true,
  isDisabled = false,
  ariaLabel,
}) => {
  const { t } = useTranslation();

  const onSelectBlur = (value) => {
    onBlur(value);
    onSearch("");
    onTouch(name);
  };

  const showError = useMemo(() => {
    return errorMessage && (isMulti ? !value.length : !value);
  }, [errorMessage, isMulti, value]);

  return (
    <>
      <ReactSelect
        options={options}
        value={value}
        isClearable={isClearable}
        isSearchable={isSearchable}
        closeMenuOnSelect={!isMulti}
        isMulti={isMulti}
        placeholder={placeholder ?? t("startTyping")}
        components={{
          IndicatorSeparator: null,
          Control,
          ClearIndicator,
          Option,
          NoOptionsMessage,
        }}
        styles={SelectStyles}
        onChange={(value) => setValue(name, value)}
        hideSelectedOptions={false}
        menuPlacement={menuPlacement}
        noOptionsMessage={() => noOptionsMessage}
        theme={ThemeStyles}
        onBlur={onSelectBlur}
        onInputChange={onSearch}
        filterOption={null}
        isDisabled={isDisabled}
        aria-label={ariaLabel}
      />
      {showError && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  setValue: PropTypes.func.isRequired,
  value: PropTypes.any,
  isMulti: PropTypes.bool,
  name: PropTypes.string,
  errorMessage: PropTypes.string,
  menuPlacement: PropTypes.oneOf(["bottom", "top", "auto"]),
  noOptionsMessage: PropTypes.string,
  onBlur: PropTypes.func,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  onTouch: PropTypes.func,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

Select.defaultProps = {
  onBlur: () => {},
  onSearch: () => {},
  onTouch: () => {},
};
