import {
  useCompleteProcedureMutation,
  useGetProcedureQuery,
} from "modules/procedures/state/proceduresApi";
import { IProcedure } from "modules/procedures/types";
import { useCallback, useContext, useState } from "react";
import { EditorModal } from "shared/components/Modals";
import { Hint } from "shared/components/Typography";
import styled from "styled-components";
import { useSetModal } from "widgets/ModalController";
import { ModalParams } from "widgets/ModalController/types";
import { ProceduresContext } from "./context/ProceduresProvider";
import { ProcedureItem } from "./ItemInputs/ProcedureItem";
import CheckIcon from "shared/assets/Check.svg?react";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import {
  BuildProcedureInitialValues,
  BuildProcedureForm,
} from "modules/procedures/lib/form";
import { useFormik } from "formik";
import { pattern } from "@test-data";

interface ProcedureModalProps {
  params: ModalParams;
}

export const ProcedureModal = ({ params }: ProcedureModalProps) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();
  const { id } = params;
  const setModal = useSetModal();

  const [submitting, setSubmitting] = useState(false);
  const {
    state: { isSaving: isItemSaving },
  } = useContext(ProceduresContext);

  const { data, isLoading } = useGetProcedureQuery(id);
  const procedure = (data as IProcedure) || {};

  const onClose = useCallback(() => {
    switch (procedure.target_type) {
      case "WorkOrder":
        setModal({
          type: "wo",
          id: procedure.target_id,
        });
        break;

      case "PmSchedule":
        setModal({
          type: "pm",
          id: procedure.target_id,
        });
        break;

      default:
        setModal();
        break;
    }
  }, [procedure.target_id, procedure.target_type, setModal]);

  const [completeProcedure] = useCompleteProcedureMutation();

  // We're only using the form for input validation here.
  // Procedure responses are saved in near-realtime via individual PUT requests
  const form = useFormik({
    validationSchema: BuildProcedureForm(t, procedure),
    initialValues: BuildProcedureInitialValues(procedure),
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async () => {
      setSubmitting(true);

      try {
        await completeProcedure(id).unwrap();

        addSnackBar({
          title: t("procedures.snackbars.procedure_completed.success.title"),
          type: "success",
        });

        onClose();
      } catch (e) {
        const { data } = (e as { data?: { message?: string } }) || {};
        const { message } = data || {};

        addSnackBar({
          title: t("procedures.snackbars.procedure_completed.error.title"),
          type: "error",
          content: message,
        });
      }

      setSubmitting(false);
    },
  });

  const disableSubmit =
    form.isValidating || isItemSaving || !form.isValid || form.dirty;

  return (
    <EditorModal
      label={procedure.name}
      isSubmitLoading={submitting}
      form={form}
      isOpen={true}
      onClose={onClose}
      subHeader={
        <SubHeaderContainer>
          <Hint>{t("procedures.modal_feedback.save_hint")}</Hint>
          <SaveHint />
        </SubHeaderContainer>
      }
      primaryButtonLabel={t("complete")}
      secondaryButtonLabel={t("close")}
      onSubmit={form.submitForm}
      disabledSubmit={disableSubmit}
    >
      {isLoading ? (
        <Skeleton height={20} count={12} style={{ marginBottom: 10 }} />
      ) : (
        <ItemContainer>
          {procedure.items?.map((item, index) => (
            <ItemBackground
              index={index}
              key={item.id}
              data-testid={pattern.itemFormId(index + 1)}
            >
              <ProcedureItem item={item} key={item.id} form={form} />
            </ItemBackground>
          ))}
        </ItemContainer>
      )}
    </EditorModal>
  );
};

const SaveHint = () => {
  const { t } = useTranslation();
  const {
    state: { isSaving, error: saveError },
  } = useContext(ProceduresContext);

  if (isSaving) {
    return (
      <SaveIndicatorContainer>
        <Hint>{t("procedures.modal_feedback.saving")}</Hint>
      </SaveIndicatorContainer>
    );
  } else if (saveError) {
    return <Hint>{saveError}</Hint>;
  } else {
    return (
      <SaveIndicatorContainer>
        <Hint>{t("procedures.modal_feedback.saved")}</Hint>
        <CheckIcon />
      </SaveIndicatorContainer>
    );
  }
};

const SaveIndicatorContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  filter: grayscale(100%);
  width: 4rem;
`;

const ItemBackground = styled.div`
  background-color: ${(props: { index: number }) =>
    props.index % 2 === 0 ? "white" : "#f5f5f5"};
  padding: 32px 16px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SubHeaderContainer = styled.div`
  height: 1.5rem;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
