import styled from "styled-components";

export const Container = styled.div`
  margin-top: 24px;
`;

export const Name = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: var(--color_primary_text);
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 32px 5fr 2fr;
  grid-template-areas:
    "img title date "
    "img desc desc"
    "img content content";
  gap: 2px 8px;
  position: relative;
  width: 100%;
`;

export const HeaderProfile = styled.div`
  grid-area: img;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const Title = styled.div`
  font-weight: 600;
  margin-right: 2px;
`;

export const Description = styled.div`
  grid-area: desc;
  margin-bottom: 8px;
`;

export const Header = styled.div`
  grid-area: title;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  font-size: 14px;
  color: var(--color_grey900);
  width: 100%;
  grid-area: content;
  z-index: 1;
`;

export const ArrowContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const Box = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 16px 8px 0 8px;
  border-radius: 4px;
`;

export const Image = styled.div`
  min-width: 32px;
  min-height: 32px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: var(--color_table_missing_icon_color);

  > img {
    width: 100%;
    height: 100%;
  }
`;

export const CreatedAt = styled.span`
  color: var(--color_background);
  opacity: 0.5;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin-left: auto;
  grid-area: date;
`;

export const ChecklistContainer = styled.div<{ checked: unknown }>`
  display: flex;
  width: fit-content;
  gap: 8px;
  align-items: center;
  background: var(--color_grey100);
  padding: 2px 4px;
  border-radius: 6px;
  line-height: 20px;
  ${({ checked }) =>
    checked ? "text-decoration: line-through var(--color_grey500);" : ""};
`;
