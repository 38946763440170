const action = {
  fill: "fill",
  selectClick: "selectClick",
  selectFill: "selectFill",
  switch: "switch",
} as const;

const procedureItemType = {
  number: "Number",
  checkbox: "Checkbox",
  date: "Date",
  multipleChoice: "Multiple choice",
  text: "Text",
  yesNoNa: "Yes, No, or N/A",
} as const;

const proceduresKeys = ["description", "linkedTo", "name"] as const;

const portalKeys = [
  "assignedTo",
  "asset",
  "category",
  "description",
  "location",
  "neededBy",
  "notifications",
  "priorityRequests",
  "title",
  "vendors",
  "firstName",
  "lastName",
  "phone",
  "email",
] as const;

const requestPortalsKeys = [
  "allowAnonymous",
  "description",
  "entity",
  "instructions",
  "title",
  "welcomeText",
] as const;

const requestsKeys = [
  "assignedTo",
  "asset",
  "category",
  "comments",
  "createdBy",
  "createdWhen",
  "description",
  "id",
  "location",
  "neededBy",
  "notifications",
  "priorityRequests",
  "requestSource",
  "statusRequests",
  "title",
  "updatedBy",
  "updatedWhen",
  "vendors",
  "workOrder",
] as const;

const workOrdersKeys = [
  "assignedTo",
  "asset",
  "category",
  "comments",
  "completedWhen",
  "createdBy",
  "createdWhen",
  "description",
  "downtime",
  "dueDate",
  "id",
  "location",
  "name",
  "priorityWorkOrders",
  "procedures",
  "request",
  "schedule",
  "statusWorkOrders",
  "title",
  "updatedBy",
  "updatedWhen",
  "vendors",
  "workType",
] as const;

export {
  action,
  procedureItemType,
  proceduresKeys,
  portalKeys,
  requestsKeys,
  requestPortalsKeys,
  workOrdersKeys,
};
