import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { InlineLabel } from "shared/components/widgets";
import { Input } from "shared/components/Input";
import { Switch } from "shared/components/Switch";

import { QrCodeInput } from "shared/components/QrCodeInput";
import { FileManager } from "modules/fileManager";
import { HyperlinkManager } from "shared/components/HyperlinkManager";
import { VendorsInput } from "./VendorsInput";
import { useSearch } from "shared/lib/hooks/useSearch";
import { getOptions } from "shared/lib/helpers";

import { Select, SelectCreatable } from "shared/components/Select";
import { measurementUnits } from "modules/parts/configs/constant";

import {
  useGetPartTypesQuery,
  useGetStoreroomsQuery,
  useGetUnitsQuery,
} from "modules/parts";
import { AssetCreate, useGetAllAssetsQuery } from "modules/assets";
import { useGetAllVendorsQuery } from "modules/vendors";
import { useGetLocaleSettings } from "shared/lib/hooks";
import {
  getAssetsOptions,
  getVendorsOptions,
} from "shared/lib/helpers/data.js";

export const PartEditor = ({
  type,
  form,
  optionalFields,
  generateQr,
  setGenerateQr,
  isEditCostFields = true,
}) => {
  const { t } = useTranslation();
  const { currency } = useGetLocaleSettings();

  const [partTypes = { data: [] }, onPartTypesSearch] = useSearch(
    useGetPartTypesQuery,
    optionalFields.hasOwnProperty("part_type_id")
  );

  const [storerooms = { data: [] }, onStoreroomsSearch] = useSearch(
    useGetStoreroomsQuery,
    !form.values.is_non_stock
  );

  const [assets = [], onAssetsSearch] = useSearch(
    useGetAllAssetsQuery,
    optionalFields.hasOwnProperty("assets_ids")
  );
  const [units = { data: [] }, onUnitsSearch] = useSearch(
    useGetUnitsQuery,
    true
  );
  const [vendors = [], onVendorsSearch] = useSearch(
    useGetAllVendorsQuery,
    optionalFields.hasOwnProperty("vendors_ids"),
    { "filter[parts_supplier_true]": 1 }
  );

  // Use getAssetsOptions to bring assets that are linked to selected vendors to the top
  const assetsOptions = getAssetsOptions(assets, {
    vendors: form.values.vendors.map((v) => v.vendor).filter((v) => !!v),
  });

  /*
   * The vendors list is in a different format than elsewhere in the app to support the "Preferred" and "Vendor Part Number" fields
   * Because it's split into separate fields, we need to filter out selected vendors upfront, and convert to the format
   *  that `getVendorsOptions` expects
   */
  const selectedVendorIds = form.values.vendors.map((v) => v.vendor?.value);
  const unselectedVendors = vendors.filter(
    (v) => !selectedVendorIds.includes(v.id)
  );
  const vendorsOptions = getVendorsOptions(unselectedVendors, {});

  const partTypesOptions = getOptions(partTypes);
  const storeroomsOptions = getOptions(storerooms);
  const unitsOptions = units.data.map(({ id }) => ({
    value: id,
    label: measurementUnits[id].name,
  }));

  return (
    <>
      <WrapperFileManager
        name="uploads_attributes"
        value={form.values.uploads_attributes}
        setValue={form.handleSetValue}
      />
      <Input
        name="name"
        value={form.values.name}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        placeholder={t("parts.form.name")}
        errorMessage={form.touched.name && form.errors.name}
        height={50}
        fontSize={20}
        required
      />
      <InlineLabel label={t("parts.form.description")} labelWidth={110}>
        <Input
          name="description"
          value={form.values.description}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder={t("parts.form.description")}
          errorMessage={form.touched.description && form.errors.description}
          isTextArea
        />
      </InlineLabel>
      <InlineLabel label="" labelWidth={110}>
        <Switch
          disabled={type === "edit"}
          activated={form.values.is_non_stock}
          onClick={() => {
            const { handleSetValue } = form;
            handleSetValue("is_non_stock", !form.values.is_non_stock);
          }}
        >
          {t("parts.form.nonStock")}
        </Switch>
      </InlineLabel>
      {!form.values.is_non_stock && (
        <>
          <InlineLabel label={t("parts.form.onHand")} labelWidth={110}>
            <Input
              type="number"
              name="on_hand_qty"
              value={form.values.on_hand_qty}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              onKeyDown={(e) => e.key === "e" && e.preventDefault()}
              placeholder={t("parts.form.onHand")}
              errorMessage={form.touched.on_hand_qty && form.errors.on_hand_qty}
              disabled={!isEditCostFields}
            />
          </InlineLabel>
          <InlineLabel label={t("parts.form.measurementUnit")} labelWidth={110}>
            <Select
              options={unitsOptions}
              name="measurement_unit"
              value={form.values.measurement_unit}
              setValue={form.handleSetValue}
              onBlur={form.handleBlur}
              noOptionsMessage={t("noOptions")}
              errorMessage={
                form.touched.measurement_unit && form.errors.measurement_unit
              }
              onSearch={onUnitsSearch}
              onTouch={form.handleSetTouched}
            />
          </InlineLabel>
          <InlineLabel label={t("parts.form.minimum")} labelWidth={110}>
            <Input
              type="number"
              name="min_qty"
              value={form.values.min_qty}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              onKeyDown={(e) => e.key === "e" && e.preventDefault()}
              placeholder={t("parts.form.minimum")}
              errorMessage={form.touched.min_qty && form.errors.min_qty}
            />
          </InlineLabel>
          <InlineLabel label={t("parts.form.desired")} labelWidth={110}>
            <Input
              type="number"
              name="desired_qty"
              value={form.values.desired_qty}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              onKeyDown={(e) => e.key === "e" && e.preventDefault()}
              placeholder={t("parts.form.desired")}
              errorMessage={form.touched.desired_qty && form.errors.desired_qty}
            />
          </InlineLabel>
        </>
      )}
      {optionalFields.hasOwnProperty("unit_cost") && (
        <InlineLabel
          label={t("parts.form.unitCost")}
          labelWidth={110}
          isRequired={optionalFields.unit_cost.required}
        >
          <Input
            type="number"
            name="unit_cost"
            value={form.values.unit_cost}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            onKeyDown={(e) => e.key === "e" && e.preventDefault()}
            placeholder={t("parts.form.unitCost")}
            errorMessage={form.touched.unit_cost && form.errors.unit_cost}
            startAddon={currency}
            required={optionalFields.unit_cost.required}
            disabled={!isEditCostFields}
          />
        </InlineLabel>
      )}
      {form.values.is_non_stock && (
        <InlineLabel label={t("parts.form.measurementUnit")} labelWidth={110}>
          <Select
            options={unitsOptions}
            name="measurement_unit"
            value={form.values.measurement_unit}
            setValue={form.handleSetValue}
            onBlur={form.handleBlur}
            noOptionsMessage={t("noOptions")}
            errorMessage={
              form.touched.measurement_unit && form.errors.measurement_unit
            }
            onSearch={onUnitsSearch}
            onTouch={form.handleSetTouched}
          />
        </InlineLabel>
      )}
      {optionalFields.hasOwnProperty("number") && (
        <InlineLabel
          label={t("parts.form.partNumber")}
          labelWidth={110}
          isRequired={optionalFields.number.required}
        >
          <Input
            name="number"
            value={form.values.number}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder={t("parts.form.partNumber")}
            errorMessage={form.touched.number && form.errors.number}
            required={optionalFields.number.required}
          />
        </InlineLabel>
      )}
      {optionalFields.hasOwnProperty("part_type_id") && (
        <InlineLabel
          label={t("parts.form.partType")}
          labelWidth={110}
          isRequired={optionalFields.part_type_id.required}
        >
          <Select
            options={partTypesOptions}
            name="part_type"
            value={form.values.part_type}
            setValue={form.handleSetValue}
            onBlur={form.handleBlur}
            noOptionsMessage={t("noOptions")}
            errorMessage={form.touched.part_type && form.errors.part_type}
            onSearch={onPartTypesSearch}
            onTouch={form.handleSetTouched}
          />
        </InlineLabel>
      )}
      {!form.values.is_non_stock && (
        <>
          <InlineLabel label={t("parts.form.storeroom")} labelWidth={110}>
            <Select
              options={storeroomsOptions}
              name="storeroom"
              value={form.values.storeroom}
              setValue={form.handleSetValue}
              onBlur={form.handleBlur}
              noOptionsMessage={t("noOptions")}
              errorMessage={form.touched.storeroom && form.errors.storeroom}
              onSearch={onStoreroomsSearch}
              onTouch={form.handleSetTouched}
            />
          </InlineLabel>
          <InlineLabel label={t("parts.form.area")} labelWidth={110}>
            <Input
              name="area"
              value={form.values.area}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              placeholder={t("parts.form.area")}
              errorMessage={form.touched.area && form.errors.area}
            />
          </InlineLabel>
          <InlineLabel label={t("parts.form.qrCode")} labelWidth={110}>
            <QrCodeInput
              name="code"
              value={form.values.code}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              setValue={form.handleSetValue}
              generateQr={generateQr}
              setGenerateQr={setGenerateQr}
              errorMessage={form.touched.code && form.errors.code}
              onTouch={form.handleSetTouched}
            />
          </InlineLabel>
        </>
      )}
      {optionalFields.hasOwnProperty("assets_ids") && (
        <InlineLabel
          label={t("parts.form.assets")}
          labelWidth={110}
          isRequired={optionalFields.assets_ids.required}
        >
          <SelectCreatable
            options={assetsOptions}
            name="assets"
            value={form.values.assets}
            setValue={form.handleSetValue}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            noOptionsMessage={t("noOptions")}
            errorMessage={form.touched.assets && form.errors.assets}
            optionCreateModal={<AssetCreate />}
            onSearch={onAssetsSearch}
            required={optionalFields.assets_ids.required}
            isMulti
            onTouch={form.handleSetTouched}
          />
        </InlineLabel>
      )}
      {optionalFields.hasOwnProperty("vendors_ids") && (
        <InlineLabel
          label={t("parts.form.vendors")}
          labelWidth={110}
          isRequired={optionalFields.vendors_ids.required}
        >
          <VendorsInput
            name="vendors"
            vendors={form.values.vendors}
            onBlur={form.handleBlur}
            setValue={form.handleSetValue}
            required={optionalFields.vendors_ids.required}
            errorMessage={form.touched.vendors && form.errors.vendors}
            errors={form.errors.vendors}
            options={vendorsOptions}
            onSearch={onVendorsSearch}
          />
        </InlineLabel>
      )}
      {optionalFields.hasOwnProperty("lead_time") && (
        <InlineLabel
          label={t("parts.form.leadTime")}
          labelWidth={110}
          isRequired={optionalFields.lead_time.required}
        >
          <Input
            name="lead_time"
            value={form.values.lead_time}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder={t("parts.form.leadTime")}
            errorMessage={form.touched.lead_time && form.errors.lead_time}
            required={optionalFields.lead_time.required}
            endAddon={t("parts.form.leadTimeUnit")}
          />
        </InlineLabel>
      )}
      {optionalFields.hasOwnProperty("urls_attributes") && (
        <InlineLabel
          label={t("parts.form.url")}
          labelWidth={110}
          isRequired={optionalFields.urls_attributes.required}
        >
          <HyperlinkManager
            name="urls_attributes"
            value={form.values.urls_attributes}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            setValue={form.handleSetValue}
            errors={form.touched.urls_attributes && form.errors.urls_attributes}
          />
        </InlineLabel>
      )}
    </>
  );
};

const WrapperFileManager = styled(FileManager)`
  margin-bottom: 30px;
`;
