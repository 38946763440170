import { CMMSAPI } from "app/services";

export const proceduresApi = CMMSAPI.injectEndpoints({
  endpoints: (builder) => ({
    getItemTypes: builder.query({
      query: () => ({
        url: "procedure_template_items/item_types",
      }),
      providesTags: () => [{ type: "ProcedureItemTypes" }],
      transformResponse: (response: { data: object }) => response.data,
    }),
    getProcedure: builder.query({
      query: (id: number | string) => `/procedures/${id}`,
      providesTags: (_result, _error, query) => [
        { type: "ProcedureDetails", id: query as string },
      ],
      transformResponse: (response: { data: object }) => response.data,
    }),
    destroyProcedure: builder.mutation({
      query: (id) => ({
        url: `procedures/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "ProcedureDetails", id },
        { type: "WorkOrderDetails" },
        { type: "WorkOrderHistory", id: "WorkOrderHistories" },
      ],
    }),
    updateProcedureItem: builder.mutation({
      query: (body) => ({
        url: `procedure_items/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_result, _error, { procedure_template_id }) => [
        { type: "WorkOrderHistory", id: "WorkOrderHistories" },
        { type: "ProcedureDetails", id: procedure_template_id },
        { type: "WorkOrderDetails" },
      ],
    }),
    getProcedureTemplates: builder.query({
      query: (params) => ({
        url: "/procedure_templates",
        params,
      }),
      providesTags: () => [
        { type: "Entity" },
        { type: "ProcedureTemplate", id: "ProcedureTemplatesList" },
      ],
    }),
    getProcedureTemplate: builder.query({
      query: (id) => `/procedure_templates/${id}`,
      providesTags: (_result, _error, query) => [
        { type: "ProcedureTemplateDetails", id: query as string },
      ],
      transformResponse: (response: { data: object }) => response.data,
    }),
    createProcedureTemplate: builder.mutation({
      query: (body) => ({
        url: "procedure_templates",
        method: "POST",
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "ProcedureTemplate", id: "ProcedureTemplatesList" },
        { type: "ProcedureTemplateDetails", id },
      ],
    }),
    updateProcedureTemplate: builder.mutation({
      query: (body) => ({
        url: `procedure_templates/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "ProcedureTemplateDetails", id: id },
        { type: "ProcedureTemplate", id: "ProcedureTemplatesList" },
        { type: "ProcedureDetails" },
      ],
      transformResponse: (response: { data: object }) => response.data,
    }),
    destroyProcedureTemplate: builder.mutation({
      query: (id) => ({
        url: `procedure_templates/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        { type: "ProcedureTemplate", id: "ProcedureTemplatesList" },
      ],
    }),
    createProcedureTemplateItem: builder.mutation({
      query: (body) => ({
        url: "procedure_template_items",
        method: "POST",
        body,
      }),
      invalidatesTags: (_result, _error, { procedure_template_id }) => [
        { type: "ProcedureTemplateDetails", id: procedure_template_id },
        { type: "ProcedureDetails" },
      ],
    }),
    updateProcedureTemplateItem: builder.mutation({
      query: ({ id, body }) => ({
        url: `procedure_template_items/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_result, _error, { procedure_template_id }) => [
        { type: "ProcedureTemplateDetails", id: procedure_template_id },
        { type: "ProcedureDetails" },
      ],
    }),
    destroyProcedureTemplateItem: builder.mutation({
      query: (id) => ({
        url: `procedure_template_items/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, { procedure_template_id }) => [
        { type: "ProcedureTemplateDetails", id: procedure_template_id },
      ],
    }),
    completeProcedure: builder.mutation({
      query: (id) => ({
        url: `procedures/${id}/complete`,
        method: "PUT",
      }),
      invalidatesTags: (_result, _error, { id, target_id }) => [
        { type: "ProcedureDetails", id },
        { type: "WorkOrderDetails", target_id },
      ],
    }),
    reopenProcedure: builder.mutation({
      query: (id) => ({
        url: `procedures/${id}/reopen`,
        method: "PUT",
      }),
      invalidatesTags: (_result, _error, { id, target_id }) => [
        { type: "ProcedureDetails", id },
        { type: "WorkOrderDetails", target_id },
      ],
    }),
    linkProcedureTemplates: builder.mutation({
      query: ({ targetId, targetType, procedureTemplateIds }) => ({
        url: "/procedure_template_assignments",
        method: "POST",
        body: {
          procedure_template_assignment: {
            template_ids: procedureTemplateIds,
            target_id: targetId,
            target_type: targetType,
          },
        },
      }),
      invalidatesTags: [
        { type: "WorkOrderDetails" },
        { type: "PreventiveMaintenance" },
        { type: "WorkOrderHistory", id: "WorkOrderHistories" },
      ],
    }),
    unlinkProcedureTemplate: builder.mutation({
      query: ({ procedureTemplateId, targetId, targetType }) => ({
        url: "/procedure_template_assignments",
        method: "DELETE",
        body: {
          procedure_template_assignment: {
            procedure_template_id: procedureTemplateId,
            target_id: targetId,
            target_type: targetType,
          },
        },
      }),
      invalidatesTags: [
        { type: "WorkOrderDetails" },
        { type: "ProcedureTemplate", id: "ProcedureTemplatesList" },
        { type: "PreventiveMaintenance" },
        { type: "WorkOrderHistory", id: "WorkOrderHistories" },
      ],
    }),
  }),
});

export const {
  useCompleteProcedureMutation,
  useCreateProcedureTemplateItemMutation,
  useCreateProcedureTemplateMutation,
  useDestroyProcedureMutation,
  useDestroyProcedureTemplateItemMutation,
  useDestroyProcedureTemplateMutation,
  useGetItemTypesQuery,
  useGetProcedureQuery,
  useGetProcedureTemplateQuery,
  useGetProcedureTemplatesQuery,
  useLinkProcedureTemplatesMutation,
  useReopenProcedureMutation,
  useUnlinkProcedureTemplateMutation,
  useUpdateProcedureItemMutation,
  useUpdateProcedureTemplateItemMutation,
  useUpdateProcedureTemplateMutation,
} = proceduresApi;
