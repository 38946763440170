import React from "react";
import { useTranslation } from "react-i18next";

import { getHistoryContent } from "../../lib/helpers/index";
import { Box, Container } from "./History/WorkOrderHistoryList.styled";
import { IHistory } from "modules/workOrders/lib/helpers/getHistoryContent";
import { ChecklistItemView, GenericView } from "./History";

export const WorkOrderHistoryList = ({ value = [] }: { value: IHistory[] }) => {
  const { t } = useTranslation();

  return (
    <Container>
      {value.map((history) => {
        const contents = getHistoryContent(history, t);

        return contents?.map((content) => (
          <Box key={history.id}>
            <React.Fragment key={content.key}>
              {Object.prototype.hasOwnProperty.call(content, "value") ? (
                <ChecklistItemView change={content} history={history} />
              ) : (
                <GenericView change={content} history={history} />
              )}
            </React.Fragment>
          </Box>
        ));
      })}
    </Container>
  );
};
