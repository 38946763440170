import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { t } from "i18next";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import { IconButton } from "shared/components/IconButton";
import { ConfirmationDialog } from "shared/components/Dialog";
import { Dropdown } from "shared/components/Dropdowns";
import { useSearch } from "shared/lib/hooks";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { RESPONSE_MESSAGE } from "shared/lib/constants/api";
import { useGetAdminConfigurationQuery } from "modules/adminFieldsConfiguration";
import { useGetWorkOrderStatusesQuery } from "modules/workOrders";
import Icon from "../../../assets/icons/status.svg?react";
import { totangoStatusFormat } from "modules/workOrders/lib/helpers/totangoFormat";

const getOptions = (configuration, statuses) => {
  if (!statuses || !configuration) return [];

  const data = statuses.data;
  const isShowReview = !!configuration.data.payload?.in_review?.required;
  const options = isShowReview
    ? data
    : data.filter((status) => status.name !== "in_review");

  return options.map(({ id, name }) => ({
    key: id,
    value: t(`work_orders.bulkActions.updateStatus.options.${name}`),
  }));
};

export const BulkStatus = ({
  ids = [],
  onUpdate,
  count,
  setUpdateStatusError,
}) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();

  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [status, updateStatus] = useState("");

  const { data } = useGetAdminConfigurationQuery({
    type: "work_order_status",
  });

  const [statuses, onStatusesSearch] = useSearch(
    useGetWorkOrderStatusesQuery,
    true
  );

  const statusOptions = useMemo(
    () => getOptions(data, statuses),
    [data, statuses]
  );

  const statusChangeHandler = (name, value) => {
    updateStatus(value);
  };

  const clickHandler = () => {
    setIsConfirmationVisible(true);
  };

  const cancelHandler = () => {
    setIsConfirmationVisible(false);
    updateStatus("");
  };

  const proceedHandler = () => {
    if (ids.length > 0) {
      onUpdate({ ids, status_id: status })
        .unwrap()
        .then((response) => {
          const newValue = statusOptions?.find(
            (item) => item.key === status
          )?.value;

          let succeedItems = [];
          let itemsWithPartErrors = [];
          let itemsWithAssetError = [];
          response.results.forEach(({ body }) => {
            if (body.status === "error") {
              if (body.message === RESPONSE_MESSAGE.offline_asset) {
                itemsWithAssetError.push(body.record.title);
              } else itemsWithPartErrors.push(body.record.title);
            } else {
              succeedItems.push(body.data.title);
            }
          });

          if (itemsWithAssetError.length > 0) {
            setUpdateStatusError(true);
          } else if (itemsWithPartErrors.length > 0) {
            addBulkPartErrorSnackBar(succeedItems, itemsWithPartErrors);
          } else {
            addSnackBar({
              content: t("work_orders.bulkActions.updateStatus.success", {
                count,
                newValue,
              }),
              type: "success",
            });
          }
          setIsConfirmationVisible(false);

          window.totango.track(
            `Bulk WO Set to ${totangoStatusFormat(newValue)}`,
            "Web - Work Orders"
          );
        })
        .catch((error) => {
          addSnackBar({
            title: t("work_orders.bulkActions.updateStatus.fail"),
            type: "error",
          });
        });
    }
  };

  const addBulkPartErrorSnackBar = (succeedItems, itemsWithErrors) => {
    let errorMessage = t(
      "work_orders.bulkActions.updateStatus.invalidPartQty",
      {
        workOrders: itemsWithErrors.join(", "),
      }
    );

    let successMessage = "";
    if (succeedItems.length > 0) {
      successMessage = t("work_orders.bulkActions.updateStatus.bulkSuccess", {
        workOrders: succeedItems.join(", "),
      });
    }

    addSnackBar({
      title: errorMessage + " " + successMessage,
      type: "error",
    });
  };

  return (
    <>
      <IconButton onClick={clickHandler}>
        <StyledIcon data-tooltip-id="icon-tooltip-status" />
        <ReactTooltip
          id="icon-tooltip-status"
          place="bottom"
          content={t("work_orders.bulkActions.hint.status")}
          opacity="1"
        />
      </IconButton>
      {isConfirmationVisible && (
        <ConfirmationDialog
          title={t("work_orders.bulkActions.updateStatus.confirmation.title")}
          confirmButtonLabel={t("save")}
          cancelButtonLabel={t("discardChanges")}
          description={
            <Description>
              <SelectedLabel>
                {t(
                  `work_orders.bulkActions.updateStatus.confirmation.selected_${
                    count > 1 ? "other" : "one"
                  }`,
                  { count }
                )}
              </SelectedLabel>
              <FieldBox>
                <NewLabel>
                  {t("work_orders.bulkActions.updateStatus.confirmation.new")}
                </NewLabel>
                <Dropdown
                  name="status"
                  value={status}
                  options={statusOptions}
                  defaultOptions={[]}
                  setValue={statusChangeHandler}
                  onSearch={onStatusesSearch}
                  placeholder={t("startTyping")}
                  withClear={false}
                  withSearch={false}
                />
              </FieldBox>
            </Description>
          }
          onProceed={proceedHandler}
          onCancel={cancelHandler}
        />
      )}
    </>
  );
};

BulkStatus.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number),
  onUpdate: PropTypes.func.isRequired,
  count: PropTypes.number,
};

const Description = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  padding-bottom: 32px;
  gap: 16px;
`;

const SelectedLabel = styled.span`
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: var(--color_grey900);
`;

const FieldBox = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`;

const NewLabel = styled.span`
  flex-shrink: 0;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: var(--color_grey900);
`;

const StyledIcon = styled(Icon)`
  outline: none;
`;
