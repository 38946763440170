import { useMemo, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { FileManager } from "modules/fileManager";
import { InlineLabel } from "shared/components/widgets";
import { Input } from "shared/components/Input";
import { Switch } from "shared/components/Switch";

import { useSearch } from "shared/lib/hooks/useSearch";

import { DateTimePicker } from "shared/components/DatePicker";
import { Select, SelectCreatable } from "shared/components/Select";
import { ChecklistManager } from "shared/components/Checklist";
import { PriorityInput } from "shared/components/Priority";

import { useGetAllAssetsQuery, AssetCreate } from "modules/assets";
import { useGetAllVendorsQuery, VendorCreate } from "modules/vendors";
import { useGetAllLocationsQuery, LocationCreate } from "modules/locations";
import { useGetCategoriesQuery } from "modules/categories";
import { useGetTechniciansQuery } from "modules/users";

import { usePermission } from "app/providers/PermissionsProvider";

import {
  getOptions,
  getAssignToOptions,
  getTimezoneDateFormats,
} from "shared/lib/helpers";

import {
  getLocationsOptions,
  getAssetsOptions,
  getVendorsOptions,
  setAsset,
} from "shared/lib/helpers/data";
import { formatISO } from "date-fns";

import { CustomFormik } from "shared/lib/hooks/useForm";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useProcedureTemplateOptions } from "modules/procedures/hooks/useProcedureTemplateOptions";
import { useCreateProcedureTemplateMutation } from "modules/procedures/state/proceduresApi";
import { useCaptureExceptionWithBreadcrumb } from "shared/lib/hooks";
import { ProcedureTemplateEdit } from "modules/procedures/components/ProcedureTemplateModal";

interface RequestEditorProps {
  type: string;
  form: CustomFormik;
  optionalFields: Partial<
    Record<
      | "description"
      | "location_id"
      | "asset_id"
      | "categories_ids"
      | "due_date"
      | "assign_to"
      | "vendors_ids"
      | "checklist",
      { required?: boolean }
    >
  >;
}

export const RequestEditor = ({
  type,
  form,
  optionalFields,
}: RequestEditorProps) => {
  const { enableProcedures } = useFlags();
  const captureException = useCaptureExceptionWithBreadcrumb({
    showGenericErrorSnack: true,
  });

  const { t } = useTranslation();
  const { dateTimePickerFormat } = getTimezoneDateFormats();

  const { locationCreatePermit, assetCreatePermit } = usePermission();

  const { location, asset, vendors: formVendors } = form.values;

  // Procedures //
  const { onProcedureTemplateSearch, options: procedureTemplateOptions } =
    useProcedureTemplateOptions();
  const [procedureSearchTerm, setProcedureSearchTerm] = useState("");
  const [createProcedureTemplate] = useCreateProcedureTemplateMutation();
  const [modalTemplate, setModalTemplate] = useState<{ id: string | number }>();
  ////////////////

  const [locations = [], onLocationsSearch] = useSearch(
    useGetAllLocationsQuery,
    Object.prototype.hasOwnProperty.call(optionalFields, "location_id"),
    undefined,
    "path_name"
  );

  const [categories = { data: [] }, onCategoriesSearch] = useSearch(
    useGetCategoriesQuery,
    Object.prototype.hasOwnProperty.call(optionalFields, "categories_ids")
  );

  const [assets = [], onAssetsSearch] = useSearch(
    useGetAllAssetsQuery,
    Object.prototype.hasOwnProperty.call(optionalFields, "asset_id")
  ) as unknown as [
    assets: { id: string | number; status: string }[],
    onAssetsSearch: (value: string) => void
  ];

  const [vendors = [], onVendorsSearch] = useSearch(
    useGetAllVendorsQuery,
    Object.prototype.hasOwnProperty.call(optionalFields, "vendors_ids")
  );

  const [technicians, onTechniciansSearch] = useSearch(
    useGetTechniciansQuery,
    Object.prototype.hasOwnProperty.call(optionalFields, "assign_to"),
    {},
    "full_name"
  );

  const assignToOptions = getAssignToOptions(technicians);

  const locationsOptions = getLocationsOptions(
    locations,
    {
      location,
      asset,
      vendors: formVendors,
    },
    "path"
  );

  const assetsOptions = getAssetsOptions(assets, {
    location,
    asset,
    vendors: formVendors,
  });

  const vendorsOptions = getVendorsOptions(vendors, {
    location,
    asset,
    vendors: formVendors,
  });

  const categoriesOptions = getOptions(categories);

  const onAssignToSearch = (value: string) => {
    onTechniciansSearch(value);
  };

  const assetStatus = useMemo(
    () =>
      assets?.find((asset) => asset.id === form.values.asset?.value)?.status,
    [assets, form.values.asset]
  );

  return (
    <>
      <WrapperFileManager
        name="uploads_attributes"
        value={form.values.uploads_attributes}
        setValue={form.handleSetValue}
      />
      <Input
        name="title"
        value={form.values.title}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        placeholder={t("work_order_requests.form.title")}
        errorMessage={form.touched.title ? (form.errors.title as string) : null}
        height={50}
        fontSize={20}
        required
        aria-label={t("ariaLabel.input.title")}
      />
      {Object.prototype.hasOwnProperty.call(optionalFields, "description") && (
        <InlineLabel
          label={t("work_order_requests.form.description")}
          labelWidth={110}
          isRequired={optionalFields.description?.required}
        >
          <Input
            name="description"
            value={form.values.description}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder={t("work_order_requests.form.description")}
            errorMessage={
              form.touched.description
                ? (form.errors.description as string)
                : null
            }
            isTextArea
            aria-label={t("ariaLabel.input.description")}
          />
        </InlineLabel>
      )}
      {type !== "approve" && (
        <InlineLabel label="" labelWidth={110}>
          <Switch
            activated={form.values.notify}
            onClick={() => {
              const { handleSetValue } = form;
              handleSetValue("notify", !form.values.notify);
            }}
            ariaLabel={t("ariaLabel.switch.notifications") as string}
          >
            {t("work_order_requests.form.notify")}
          </Switch>
        </InlineLabel>
      )}
      {Object.prototype.hasOwnProperty.call(optionalFields, "priority") && (
        <InlineLabel
          label={t("work_order_requests.form.priority")}
          labelWidth={110}
        >
          <PriorityInput
            value={form.values.priority}
            onChange={(value: unknown) => {
              const { handleSetValue } = form;
              handleSetValue("priority", value);
            }}
          />
        </InlineLabel>
      )}
      {Object.prototype.hasOwnProperty.call(optionalFields, "location_id") && (
        <InlineLabel
          label={t("work_order_requests.form.location")}
          labelWidth={110}
          isRequired={optionalFields.location_id?.required}
        >
          {locationCreatePermit ? (
            <SelectCreatable
              options={locationsOptions}
              name="location"
              value={form.values.location}
              setValue={form.handleSetValue}
              onBlur={form.handleBlur}
              noOptionsMessage={
                t(
                  "work_order_requests.form.depended.locations.noOptions"
                ) as string
              }
              errorMessage={
                form.touched.location ? (form.errors.location as string) : null
              }
              optionCreateModal={<LocationCreate />}
              onSearch={onLocationsSearch}
              onTouch={form.handleSetTouched}
              ariaLabel={t("ariaLabel.input.location") as string}
            />
          ) : (
            <Select
              options={locationsOptions}
              name="location"
              value={form.values.location}
              setValue={form.handleSetValue}
              onBlur={form.handleBlur}
              noOptionsMessage={t(
                "work_order_requests.form.depended.locations.noOptions"
              )}
              errorMessage={
                form.touched.location ? (form.errors.location as string) : null
              }
              onSearch={onLocationsSearch}
              onTouch={form.handleSetTouched}
            />
          )}
        </InlineLabel>
      )}
      {Object.prototype.hasOwnProperty.call(optionalFields, "asset_id") && (
        <>
          <InlineLabel
            label={t("work_order_requests.form.assets")}
            labelWidth={110}
            isRequired={optionalFields.asset_id?.required}
          >
            {assetCreatePermit ? (
              <SelectCreatable
                options={assetsOptions}
                name="asset"
                value={form.values.asset}
                setValue={(name, asset) =>
                  setAsset(name, asset, locations, assets, form)
                }
                onBlur={form.handleBlur}
                noOptionsMessage={
                  t(
                    "work_order_requests.form.depended.assets.noOptions"
                  ) as string
                }
                errorMessage={
                  form.touched.asset ? (form.errors.asset as string) : null
                }
                optionCreateModal={<AssetCreate />}
                onSearch={onAssetsSearch}
                onTouch={form.handleSetTouched}
                ariaLabel={t("ariaLabel.input.asset") as string}
              />
            ) : (
              <Select
                options={assetsOptions}
                name="asset"
                value={form.values.asset}
                setValue={(name, asset) =>
                  setAsset(name, asset, locations, assets, form)
                }
                onBlur={form.handleBlur}
                noOptionsMessage={t(
                  "work_order_requests.form.depended.assets.noOptions"
                )}
                errorMessage={
                  form.touched.asset ? (form.errors.asset as string) : null
                }
                onSearch={onAssetsSearch}
                onTouch={form.handleSetTouched}
              />
            )}
          </InlineLabel>
          {type === "approve" && assetStatus === "online" && (
            <InlineLabel label="" labelWidth={110}>
              <Switch
                activated={form.values.set_asset_to_offline}
                onClick={() => {
                  const { handleSetValue } = form;
                  handleSetValue(
                    "set_asset_to_offline",
                    !form.values.set_asset_to_offline
                  );
                }}
              >
                {t("work_order_requests.form.set_asset_to_offline")}
              </Switch>
            </InlineLabel>
          )}
        </>
      )}
      {Object.prototype.hasOwnProperty.call(
        optionalFields,
        "categories_ids"
      ) && (
        <InlineLabel
          label={t("work_order_requests.form.category")}
          labelWidth={110}
          isRequired={optionalFields.categories_ids?.required}
        >
          <Select
            options={categoriesOptions}
            name="categories"
            value={form.values.categories}
            setValue={form.handleSetValue}
            isMulti
            onBlur={form.handleBlur}
            noOptionsMessage={t("noOptions")}
            errorMessage={
              form.touched.categories
                ? (form.errors.categories as string)
                : null
            }
            onSearch={onCategoriesSearch}
            onTouch={form.handleSetTouched}
            ariaLabel={t("ariaLabel.input.category")}
          />
        </InlineLabel>
      )}
      {Object.prototype.hasOwnProperty.call(optionalFields, "due_date") && (
        <InlineLabel
          label={t("work_order_requests.form.due_date")}
          labelWidth={110}
          isRequired={optionalFields.due_date?.required}
        >
          <DateTimePicker
            name="due_date"
            selected={form.values.due_date}
            onChange={(val) => form.handleSetValue("due_date", val)}
            dateFormat={dateTimePickerFormat}
            showIcon
            minDateTime={formatISO(new Date())}
            ariaLabel={t("ariaLabel.input.neededBy") as string}
          />
        </InlineLabel>
      )}
      {Object.prototype.hasOwnProperty.call(optionalFields, "assign_to") && (
        <InlineLabel
          label={t("work_order_requests.form.assign_to")}
          labelWidth={110}
          isRequired={optionalFields.assign_to?.required}
        >
          <Select
            options={assignToOptions}
            name="assign_to"
            value={form.values.assign_to}
            setValue={form.handleSetValue}
            isMulti
            onBlur={form.handleBlur}
            noOptionsMessage={t("noOptions")}
            errorMessage={
              form.touched.assign_to ? (form.errors.assign_to as string) : null
            }
            onSearch={onAssignToSearch}
            onTouch={form.handleSetTouched}
            ariaLabel={t("ariaLabel.input.assignedTo")}
          />
        </InlineLabel>
      )}
      {Object.prototype.hasOwnProperty.call(optionalFields, "vendors_ids") && (
        <InlineLabel
          label={t("work_order_requests.form.vendor")}
          labelWidth={110}
          isRequired={optionalFields.vendors_ids?.required}
        >
          <SelectCreatable
            options={vendorsOptions}
            name="vendors"
            value={form.values.vendors}
            setValue={form.handleSetValue}
            isMulti
            menuPlacement="top"
            onBlur={form.handleBlur}
            noOptionsMessage={
              t("work_order_requests.form.depended.vendors.noOptions") as string
            }
            errorMessage={
              form.touched.vendors ? (form.errors.vendors as string) : null
            }
            onSearch={onVendorsSearch}
            onTouch={form.handleSetTouched}
            ariaLabel={t("ariaLabel.input.vendors") as string}
            optionCreateModal={<VendorCreate />}
          />
        </InlineLabel>
      )}
      {enableProcedures &&
        Object.prototype.hasOwnProperty.call(optionalFields, "procedures") && (
          <InlineLabel
            label={t("work_orders.form.procedures")}
            labelWidth={110}
          >
            <SelectCreatable
              options={procedureTemplateOptions}
              name="procedure_templates"
              isMulti
              menuPlacement="top"
              onSearch={(value) => {
                setProcedureSearchTerm(value);
                onProcedureTemplateSearch(value);
              }}
              ariaLabel="Procedures"
              value={form.values.procedure_templates}
              setValue={form.handleSetValue}
              preCreateHook={async () => {
                if (!procedureSearchTerm) return;

                try {
                  const response = await createProcedureTemplate({
                    procedure_template: {
                      name: procedureSearchTerm,
                    },
                  }).unwrap();
                  setModalTemplate(response.data);
                } catch (e) {
                  captureException(e, "Failed to create procedure template", {
                    procedureSearchTerm,
                  });
                }
              }}
              optionCreateModal={
                modalTemplate?.id ? (
                  <ProcedureTemplateEdit
                    procedureTemplateId={modalTemplate?.id}
                  />
                ) : (
                  <></>
                )
              }
            />
          </InlineLabel>
        )}
      {type === "approve" &&
        Object.prototype.hasOwnProperty.call(optionalFields, "checklist") && (
          <InlineLabel
            label={t("work_orders.form.checklist")}
            labelWidth={110}
            isRequired={optionalFields.checklist?.required}
          >
            <ChecklistManager
              name="checklist"
              value={form.values.checklist}
              setValue={form.handleSetValue}
              errorMessage={
                form.touched.checklist
                  ? (form.errors.checklist as string[])
                  : []
              }
            />
          </InlineLabel>
        )}
    </>
  );
};

const WrapperFileManager = styled(FileManager)`
  margin-bottom: 30px;
`;
