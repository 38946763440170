import React from "react";
import { useUpdateRequestsStatusMutation } from "modules/requests";
import { usePermission } from "app/providers/PermissionsProvider";
import { BulkStatus } from "./BulkStatus";
import { useTranslation } from "react-i18next";

export const BulkActions = ({ requests }) => {
  const [updateRequests] = useUpdateRequestsStatusMutation();

  const { requestsDeclineListPermit, requestsPutOnHoldListPermit } =
    usePermission();

  const { t } = useTranslation();

  return (
    <>
      {requestsPutOnHoldListPermit && (
        <BulkStatus
          requests={requests}
          onUpdate={updateRequests}
          status="on_hold"
          ariaLabel={t(`ariaLabel.button.bulkOnHold`)}
        />
      )}
      {requestsDeclineListPermit && (
        <BulkStatus
          requests={requests}
          onUpdate={updateRequests}
          status="declined"
          ariaLabel={t(`ariaLabel.button.bulkDecline`)}
        />
      )}
    </>
  );
};
