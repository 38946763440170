import React, { useState } from "react";
import { format } from "date-fns";
import { renderToStaticMarkup } from "react-dom/server";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";

import { CalendarEvent } from "./CalendarEvent";
import { CalendarDateEvents } from "./CalendarDateEvents";
import {
  useGetWorkOrdersEventsQuery,
  useUpdateWorkOrderMutation,
} from "modules/workOrders";

import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { Spinner } from "shared/components/Spinner";

import {
  PrioritySortingMap,
  CalendarHeaderToolbar,
  CalendarDayHeaderFormat,
} from "../../config/constants";
import { usePermission } from "app/providers/PermissionsProvider";
import { useSearchParam } from "shared/lib/hooks";
import { stringifyFilters } from "modules/filters/lib/stringifyFilters.js";
import { PageFallback } from "pages/PageFallback";

import { useLocaleSettings } from "modules/reporting/lib/useLocaleSettings";
import esLocale from "@fullcalendar/core/locales/es";
import frLocale from "@fullcalendar/core/locales/fr";

const onEventOrder = (event, otherEvent) =>
  PrioritySortingMap[otherEvent.priority] - PrioritySortingMap[event.priority];

export const Calendar = ({ onOpenWorkOrder, onOpenPM, filters }) => {
  const { canRescheduleCalendar } = usePermission();
  const [dates, setDates] = useState({
    firstDay: "",
    lastDay: "",
  });

  const [search] = useSearchParam();
  const [eventsModal, setEventsModal] = useState();
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();
  const { language } = useLocaleSettings();

  const [updateWorkOrder] = useUpdateWorkOrderMutation();

  const {
    data: { events, timezone } = { events: [], timezone: "Etc/UTC" },
    error,
    isFetching,
  } = useGetWorkOrdersEventsQuery(
    {
      start_date: dates.firstDay,
      end_date: dates.lastDay,
      search,
      ...stringifyFilters(filters, "work_orders"),
    },
    { skip: !dates.lastDay || !dates.firstDay, refetchOnMountOrArgChange: true }
  );

  const onEventRender = ({
    event: {
      title,
      extendedProps: {
        status,
        isOverdue,
        woId,
        pmId,
        isPlanned,
        priority,
        isCompleted,
      },
    },
    view: { type },
  }) => ({
    html: renderToStaticMarkup(
      <CalendarEvent
        status={status}
        title={title}
        priority={priority}
        type={type}
        woId={woId}
        pmId={pmId}
        isOverdue={isOverdue}
        isPlanned={isPlanned}
        isCompleted={isCompleted}
      />
    ),
  });

  const onEventClick = ({
    event: {
      extendedProps: { woId, pmId, isPlanned },
    },
  }) => {
    if (isPlanned && pmId) {
      onOpenPM(pmId);
    } else {
      onOpenWorkOrder(woId);
    }
  };

  const onEventChange = ({
    event: {
      startStr,
      extendedProps: { status, createdById, woId },
    },
    revert,
  }) => {
    const reschedulingPermission = canRescheduleCalendar(createdById, status);

    if (
      typeof reschedulingPermission === "string" &&
      reschedulingPermission.length
    ) {
      return (
        revert(),
        addSnackBar({
          content: t(`m${reschedulingPermission}`, { ns: "errors" }),
          type: "error",
        })
      );
    }
    updateWorkOrder({ id: woId, body: { due_date: startStr } })
      .unwrap()
      .then(({ data }) => {
        addSnackBar({
          content: t("work_orders.snack.successRescheduleMessage", {
            name: data.title,
          }),
          type: "success",
        });
      })
      .catch(({ message }) => {
        revert();
        addSnackBar({
          title: t("work_orders.snack.failUpdateTitle"),
          content: JSON.stringify(message),
          type: "error",
          secondaryButtonLabel: t("close"),
        });
      });
  };

  const onDatesChange = ({ start, end }) =>
    setDates({
      firstDay: format(start, "yyyy-MM-dd"),
      lastDay: format(end, "yyyy-MM-dd"),
    });

  if (error) {
    return <PageFallback />;
  }

  const onMoreEventsClick = ({ date, allSegs: events }) => {
    setEventsModal({ date, events });

    return "none";
  };

  const locales = {
    es: esLocale,
    fr: frLocale,
  };

  const locale = locales[language] || null; // null === English

  return (
    <Wrapper>
      <CalendarWrapper>
        <FullCalendar
          timeZone={timezone}
          plugins={[momentTimezonePlugin, dayGridPlugin, interactionPlugin]}
          fixedWeekCount={false}
          initialView="dayGridMonth"
          headerToolbar={CalendarHeaderToolbar}
          dayHeaderFormat={CalendarDayHeaderFormat}
          events={events}
          dayMaxEventRows={true}
          eventOrderStrict={true}
          eventContent={onEventRender}
          eventBackgroundColor="transparent"
          eventTextColor="black"
          eventBorderColor="transparent"
          eventClick={onEventClick}
          eventDrop={onEventChange}
          datesSet={onDatesChange}
          editable={true}
          height="100%"
          eventOrder={onEventOrder}
          moreLinkClick={onMoreEventsClick}
          locale={locale}
        />
      </CalendarWrapper>
      {isFetching && (
        <SpinnerWrapper>
          <Spinner height="60px" />
        </SpinnerWrapper>
      )}
      {eventsModal && (
        <CalendarDateEvents
          date={eventsModal.date}
          events={eventsModal.events}
          onClose={() => setEventsModal(false)}
          onOpenWorkOrder={onOpenWorkOrder}
          onOpenPM={onOpenPM}
        />
      )}
    </Wrapper>
  );
};

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 80px;
  width: 80px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 24px;
  border: 1px solid var(--color_border);
  border-radius: var(--border_radius_lg);
  background-color: var(--color_background_light);
`;

const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 13px 40px 40px 0;
  height: 100%;
`;

const CalendarWrapper = styled.div`
  isolation: isolate;
  height: 100%;
`;
