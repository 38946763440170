import * as Yup from "yup";
import { emailRegexp, passwordRegexp } from "shared/lib/constants/forms";
import {
  InviteSignUpFormFields,
  LoginFormFields,
  SignUpFields,
} from "./constants";
import { getNameSchema, getStringSchema } from "shared/lib/helpers/validation";

export const getDefaultValidationSchema = (field, t) => {
  const schema = {
    name: getNameSchema(t, { required: true }),
    surname: getNameSchema(t, { required: true }),

    phone_number: getStringSchema(t, {
      required: false,
      max: 50,
    }),

    company: getNameSchema(t),

    email: Yup.string()
      .required(t("validation.required"))
      .max(255, t(`validation.stringMaxLength`))
      .matches(emailRegexp, t("validation.enterEmail")),

    password: Yup.string()
      .required("required")
      .min(8, "length")
      .matches(passwordRegexp),

    loginPassword: Yup.string().required(t("validation.required")),

    acceptTerms: Yup.boolean().oneOf([true], t("validation.required")),
  };

  return schema[field];
};

export const emailValidationSchema = (t) => {
  return Yup.object({ email: getDefaultValidationSchema("email", t) });
};

export const passwordValidationSchema = (t) => {
  return Yup.object({ password: getDefaultValidationSchema("password", t) });
};

export const inviteSignUpFormValidationSchema = (t) => {
  const userDetailsSchema = InviteSignUpFormFields.reduce((acc, item) => {
    acc[item] = getDefaultValidationSchema(item, t);

    return acc;
  }, {});

  return Yup.object(userDetailsSchema);
};

export const loginValidationSchema = (t) => {
  const userDetailsSchema = LoginFormFields.reduce((acc, item) => {
    acc[item] = getDefaultValidationSchema(item, t);

    return acc;
  }, {});

  return Yup.object(userDetailsSchema);
};

export const signUpValidationSchema = (t) => {
  const userDetailsSchema = SignUpFields.reduce((acc, item) => {
    acc[item] = getDefaultValidationSchema(item, t);

    return acc;
  }, {});

  return Yup.object(userDetailsSchema);
};
