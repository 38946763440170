import * as Yup from "yup";

import { UserValidationSchema } from "modules/users";
export const userDetailsValidationSchema = (t) => {
  const userDetailsSchema = [
    "email",
    "phone_number",
    "name",
    "surname",
    "language",
  ].reduce((acc, item) => {
    acc[item] = UserValidationSchema(item, t);
    return acc;
  }, {});

  return Yup.object(userDetailsSchema);
};
