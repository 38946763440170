import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";

import { ConfirmationDialog } from "shared/components/Dialog";
import { InlineLabel } from "shared/components/widgets";
import { Input } from "shared/components/Input";
import { Button } from "shared/components/Button";
import { FileManager } from "modules/fileManager";

import { useForm } from "shared/lib/hooks/useForm";

import { costValidationSchema } from "../../configs/validationSchema";

import { useAddTransactionMutation } from "modules/parts";
import { getCostFormatForService } from "../../lib/helpers";
import { useLocaleSettings } from "modules/reporting/lib/useLocaleSettings";

export const CostUpdate = ({
  nonStock,
  partId,
  unit = "",
  onHand,
  unitCost,
}) => {
  const { currency } = useLocaleSettings();
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();

  const [modal, toggleModal] = useState(false);

  const [addTransaction] = useAddTransactionMutation();

  const resolvedUnit = unit || t("parts.measurement_units.fallback.short_name");

  const form = useForm({
    initialValues: {
      unit_cost: unitCost ?? "",
      note: "",
      uploads_attributes: [],
      quantity: 1,
    },
    validationSchema: costValidationSchema(t),
    enableReinitialize: true,
    onSubmit: async (form) => {
      addTransaction({
        id: partId,
        body: getCostFormatForService(form, nonStock),
      })
        .unwrap()
        .then(({ data }) => {
          addSnackBar({
            title: t("parts.snack.successUpdateTitle"),
            content: t("parts.snack.successUpdateMessage", {
              name: data.part?.name,
            }),
            type: "success",
            secondaryButtonLabel: t("close"),
          });
          onCloseModal();
        })
        .catch(({ message }) => {
          addSnackBar({
            title: t("parts.snack.failUpdateTitle"),
            content: JSON.stringify(message),
            type: "error",
            secondaryButtonLabel: t("close"),
          });
        });
    },
  });

  const onCloseModal = () => {
    form.resetForm();
    toggleModal(false);
  };

  return (
    <>
      <ButtonLeft variant="primaryDark" onClick={() => toggleModal(true)}>
        {t(`parts.costButton.${nonStock ? "update" : "restock"}`)}
      </ButtonLeft>
      {modal && (
        <ConfirmationDialog
          title={t(`parts.costTitle.${nonStock ? "update" : "restock"}`)}
          confirmButtonLabel={t("submit")}
          cancelButtonLabel={t("cancel")}
          disabledSubmit={!!Object.values(form.errors).length}
          description={
            <BodyContainer>
              {!nonStock && (
                <InlineLabel label={t("parts.form.quantity")} labelWidth={110}>
                  <Adjustment>
                    <AdjustButton
                      variant="secondary"
                      onClick={() =>
                        form.handleSetValue(
                          "quantity",
                          form.values.quantity - 1
                        )
                      }
                      disabled={form.values.quantity < 1}
                    >
                      -
                    </AdjustButton>
                    <AdjustInputContainer>
                      <Input
                        type="number"
                        name="quantity"
                        value={form.values.quantity}
                        onChange={form.handleChange}
                        onBlur={() => {
                          if (!form.values.quantity) {
                            form.handleSetValue("quantity", 1);
                          }
                        }}
                        onKeyDown={(e) => e.key === "e" && e.preventDefault()}
                        placeholder={t("parts.form.quantity")}
                        errorMessage={
                          form.touched.quantity && form.errors.quantity
                        }
                        endAddon={resolvedUnit}
                      />

                      {!form.errors.quantity ? (
                        <AdjustQuantityContainer>
                          <span>
                            {t("parts.form.adjust.onHand", { onHand })}
                          </span>
                          <span>
                            {t("parts.form.adjust.newQty", {
                              newQty: Math.abs(onHand) + form.values.quantity,
                            })}
                          </span>
                        </AdjustQuantityContainer>
                      ) : (
                        <Error>{form.errors.quantity}</Error>
                      )}
                    </AdjustInputContainer>
                    <AdjustButton
                      variant="secondary"
                      onClick={() =>
                        form.handleSetValue(
                          "quantity",
                          form.values.quantity + 1
                        )
                      }
                    >
                      +
                    </AdjustButton>
                  </Adjustment>
                </InlineLabel>
              )}
              <InlineLabel label={t("parts.form.restockCost")} labelWidth={110}>
                <Input
                  type="number"
                  name="unit_cost"
                  value={form.values.unit_cost}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  onKeyDown={(e) => e.key === "e" && e.preventDefault()}
                  placeholder={t("parts.form.restockCost")}
                  errorMessage={form.touched.unit_cost && form.errors.unit_cost}
                  startAddon={currency}
                />
                {/* add indent cause of long error message
                TODO: update Input Container & remove absolute position for error message */}
                <br />
              </InlineLabel>
              <InlineLabel label={t("parts.form.note")} labelWidth={110}>
                <Input
                  name="note"
                  value={form.values.note}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  placeholder={t("parts.form.note")}
                  errorMessage={form.touched.note && form.errors.note}
                  isTextArea
                />
              </InlineLabel>
              <WrapperFileManager
                name="uploads_attributes"
                value={form.values.uploads_attributes}
                setValue={form.handleSetValue}
                errorMessage={
                  form.touched.uploads_attributes &&
                  form.errors.uploads_attributes
                }
                allowedNumberOfFiles={3}
              />
            </BodyContainer>
          }
          onProceed={form.handleSubmit}
          onCancel={() => {
            form.resetForm();
            toggleModal(false);
          }}
        />
      )}
    </>
  );
};

const ButtonLeft = styled(Button)`
  margin-right: auto;
`;

const WrapperFileManager = styled(FileManager)`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const Adjustment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
`;

const AdjustButton = styled(Button)`
  font-size: 18px;
`;

const AdjustInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const AdjustQuantityContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  color: var(--color_grey900);
  margin-top: 8px;
`;

const Error = styled.div`
  color: var(--color_alert);
  font-size: 14px;
  margin-top: 8px;
`;

const BodyContainer = styled.div`
  max-height: 512px;
  overflow-y: scroll;
`;
