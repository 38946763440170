/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useMemo, useState, useEffect } from "react";

import { useGetRequestPortalsQuery } from "modules/requestPortals";

import { Pagination } from "features/Pagination";

import { usePageParam, useSortParam } from "shared/lib/hooks";
import { Spinner } from "shared/components/Spinner";
import { Table } from "shared/components/Table";

import { EmptyRequestPortalsList } from "./EmptyRequestPortalsList";
import { ErrorRequestPortalsList } from "./ErrorRequestPortalsList";
import { useRequestPortalsTableTools } from "modules/requestPortals/lib/hooks/useRequestPortalsTableTools";
import { useTranslation } from "react-i18next";

export const List = ({ onOpen = () => {} }) => {
  const [paginationData, setPaginationData] = useState({});
  const [page] = usePageParam(1);
  const [sort] = useSortParam();
  const { t } = useTranslation();

  const { columns } = useRequestPortalsTableTools({ onOpen });

  const {
    data,
    currentData,
    error = false,
    isLoading = false,
  } = useGetRequestPortalsQuery({
    page,
    per_page: 25,
    sort,
  });

  useEffect(() => {
    if (data) {
      setPaginationData(data.meta.pagination);
    }
  }, [data]);

  const empty = useMemo(
    () => data?.data?.length === 0 && !isLoading,
    [data, isLoading]
  );

  const isPageLoading = !currentData;
  if (isPageLoading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorRequestPortalsList />;
  }

  if (empty) {
    return <EmptyRequestPortalsList />;
  }

  return (
    <>
      <Table
        columns={columns}
        rows={data?.data}
        showSkeleton={isLoading}
        entityTranslationKey="request_portals.title"
      />
      <Pagination
        paginationData={paginationData}
        label={t("request_portals.title")}
      />
    </>
  );
};
