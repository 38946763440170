import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useGetListQuery } from "modules/portals";

import { InlineLabel } from "shared/components/widgets";
import { Input } from "shared/components/Input";
import { Select } from "shared/components/Select";
import { useSearch } from "shared/lib/hooks/useSearch";
import { DateTimePicker } from "shared/components/DatePicker";
import { Switch } from "shared/components/Switch";
import { Button } from "shared/components/Button";
import { PriorityInput } from "shared/components/Priority";
import {
  getOptions,
  getAssignToOptions,
  getTimezoneDateFormats,
} from "shared/lib/helpers";
import {
  getLocationsOptions,
  getAssetsOptions,
  getVendorsOptions,
  setAsset,
} from "shared/lib/helpers/data";
import { PhoneInput } from "shared/components/PhoneInput";
import { priorityOptions } from "../../lib/constants";
import {
  WrapperFileManager,
  Container,
  Title,
  ContactTitle,
  DesktopPriority,
  MobilePriority,
} from "./PortalEditor.styled";
import { formatISO } from "date-fns";

import { heading } from "@test-data";

export const PortalEditor = ({
  onSubmit,
  disabledSubmit,
  isSubmitLoading,
  form,
  optionalFields,
  entity,
  portal,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { dateTimePickerFormat } = getTimezoneDateFormats(
    null,
    entity?.datetime_format
  );

  const { allow_anonymous, required_fields: requiredContactFields } = portal;

  const { location, asset, vendors: formVendors } = form.values;

  const [locations = { data: [] }, onLocationsSearch] = useSearch(
    useGetListQuery,
    Object.prototype.hasOwnProperty.call(optionalFields, "location_id"),
    { id, type: "locations", per_page: 10000, format_resource: "light" }
  );

  const [categories = { data: [] }, onCategoriesSearch] = useSearch(
    useGetListQuery,
    Object.prototype.hasOwnProperty.call(optionalFields, "categories_ids"),
    { id, type: "categories" }
  );

  const [assets = { data: [] }, onAssetsSearch] = useSearch(
    useGetListQuery,
    Object.prototype.hasOwnProperty.call(optionalFields, "asset_id"),
    { id, type: "assets", per_page: 10000, format_resource: "light" }
  );

  const [vendors = { data: [] }, onVendorsSearch] = useSearch(
    useGetListQuery,
    Object.prototype.hasOwnProperty.call(optionalFields, "vendors_ids"),
    { id, type: "vendors", per_page: 10000 }
  );

  // get admin, limited_admin, technician users, who can be assigned to work order
  const [technicians = { data: [] }, onTechniciansSearch] = useSearch(
    useGetListQuery,
    Object.prototype.hasOwnProperty.call(optionalFields, "assign_to"),
    {
      id,
      type: "users",
      "filter[can_be_assigned_to_wo_true]": 1,
      "filter[user_permissions_user_role_id_in]": [1, 2, 3],
    }
  );

  const assignToOptions = getAssignToOptions(technicians);

  const locationsOptions = getLocationsOptions(
    locations?.data,
    {
      location,
      asset,
      vendors: formVendors,
    },
    "path"
  );

  const assetsOptions = getAssetsOptions(assets?.data, {
    location,
    asset,
    vendors: formVendors,
  });

  const vendorsOptions = getVendorsOptions(vendors?.data, {
    location,
    asset,
    vendors: formVendors,
  });

  const categoriesOptions = getOptions(categories);

  const onAssignToSearch = (value) => {
    // onTeamsSearch(value);
    onTechniciansSearch(value);
  };

  const fieldRequired = (field) => {
    if (field == null) return false;
    if (allow_anonymous && !form.values.notify) return false;

    return !!field;
  };

  return (
    <Container>
      <Title data-testid={heading.editorModal.id}>{t("portal.details")}</Title>
      <WrapperFileManager
        name="uploads_attributes"
        value={form.values.uploads_attributes}
        setValue={form.handleSetValue}
      />
      <InlineLabel
        label={t("portal.form.title")}
        labelWidth={110}
        fontSize="14px"
        isRequired
        styles={{ fontWeight: 500 }}
        multiline
        className="label"
      >
        <Input
          name="title"
          value={form.values.title}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder={t("portal.form.placeholder.title")}
          errorMessage={form.touched.title && form.errors.title}
          height={50}
          required
          aria-label={t("ariaLabel.input.title")}
        />
      </InlineLabel>
      {Object.prototype.hasOwnProperty.call(optionalFields, "description") && (
        <InlineLabel
          label={t("portal.form.description")}
          labelWidth={110}
          isRequired={optionalFields.description.required}
          fontSize="14px"
          styles={{ fontWeight: 500 }}
          className="label"
        >
          <Input
            name="description"
            value={form.values.description}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder={t("portal.form.placeholder.description")}
            errorMessage={form.touched.description && form.errors.description}
            isTextArea
            aria-label={t("ariaLabel.input.description")}
          />
        </InlineLabel>
      )}
      {Object.prototype.hasOwnProperty.call(optionalFields, "priority") && (
        <InlineLabel
          label={t("portal.form.priority")}
          labelWidth={110}
          fontSize="14px"
          styles={{ fontWeight: 500 }}
          className="label"
        >
          <DesktopPriority>
            <PriorityInput
              value={form.values.priority}
              onChange={(value) => {
                const { handleSetValue } = form;
                handleSetValue("priority", value);
              }}
            />
          </DesktopPriority>
          <MobilePriority>
            <Select
              options={priorityOptions}
              name="priority"
              value={
                typeof form.values.priority === "object"
                  ? form.values.priority
                  : priorityOptions.find(
                      (el) => el.value === form.values.priority
                    )
              }
              setValue={form.handleSetValue}
              onBlur={form.handleBlur}
              noOptionsMessage={t("noOptions")}
              errorMessage={form.touched.priority && form.errors.priority}
              onSearch={() => null}
              onTouch={form.handleSetTouched}
            />
          </MobilePriority>
        </InlineLabel>
      )}
      {Object.prototype.hasOwnProperty.call(optionalFields, "location_id") && (
        <InlineLabel
          label={t("portal.form.location")}
          labelWidth={110}
          isRequired={optionalFields.location_id.required}
          fontSize="14px"
          styles={{ fontWeight: 500 }}
          className="label"
        >
          <Select
            options={locationsOptions}
            name="location"
            value={form.values.location}
            setValue={form.handleSetValue}
            onBlur={form.handleBlur}
            noOptionsMessage={t("portal.form.depended.locations.noOptions")}
            errorMessage={form.touched.location && form.errors.location}
            onSearch={onLocationsSearch}
            onTouch={form.handleSetTouched}
            ariaLabel={t("ariaLabel.input.location")}
          />
        </InlineLabel>
      )}
      {Object.prototype.hasOwnProperty.call(optionalFields, "asset_id") && (
        <>
          <InlineLabel
            label={t("portal.form.assets")}
            labelWidth={110}
            isRequired={optionalFields.asset_id.required}
            fontSize="14px"
            styles={{ fontWeight: 500 }}
            className="label"
          >
            <Select
              options={assetsOptions}
              name="asset"
              value={form.values.asset}
              setValue={(name, asset) =>
                setAsset(name, asset, locations?.data, assets?.data, form)
              }
              onBlur={form.handleBlur}
              noOptionsMessage={t("portal.form.depended.assets.noOptions")}
              errorMessage={form.touched.asset && form.errors.asset}
              onSearch={onAssetsSearch}
              onTouch={form.handleSetTouched}
              ariaLabel={t("ariaLabel.input.asset")}
            />
          </InlineLabel>
        </>
      )}
      {Object.prototype.hasOwnProperty.call(
        optionalFields,
        "categories_ids"
      ) && (
        <InlineLabel
          label={t("portal.form.category")}
          labelWidth={110}
          isRequired={optionalFields.categories_ids.required}
          fontSize="14px"
          styles={{ fontWeight: 500 }}
          className="label"
        >
          <Select
            options={categoriesOptions}
            name="categories"
            value={form.values.categories}
            setValue={form.handleSetValue}
            isMulti
            onBlur={form.handleBlur}
            noOptionsMessage={t("noOptions")}
            errorMessage={form.touched.categories && form.errors.categories}
            onSearch={onCategoriesSearch}
            onTouch={form.handleSetTouched}
            ariaLabel={t("ariaLabel.input.category")}
          />
        </InlineLabel>
      )}
      {Object.prototype.hasOwnProperty.call(optionalFields, "due_date") && (
        <InlineLabel
          label={t("portal.form.due_date")}
          labelWidth={110}
          isRequired={optionalFields.due_date.required}
          fontSize="14px"
          styles={{ fontWeight: 500 }}
          className="label"
        >
          <DateTimePicker
            name="due_date"
            selected={form.values.due_date}
            onChange={(val) => form.handleSetValue("due_date", val)}
            showIcon
            minDateTime={formatISO(new Date())}
            errorMessage={form.touched.due_date && form.errors.due_date}
            dateFormat={dateTimePickerFormat}
            timeZone={entity?.timezone}
            ariaLabel={t("ariaLabel.input.neededBy")}
          />
        </InlineLabel>
      )}
      {Object.prototype.hasOwnProperty.call(optionalFields, "assign_to") && (
        <InlineLabel
          label={t("portal.form.assign_to")}
          labelWidth={110}
          isRequired={optionalFields.assign_to.required}
          fontSize="14px"
          styles={{ fontWeight: 500 }}
          className="label"
        >
          <Select
            options={assignToOptions}
            name="assign_to"
            value={form.values.assign_to}
            setValue={form.handleSetValue}
            isMulti
            onBlur={form.handleBlur}
            noOptionsMessage={t("noOptions")}
            errorMessage={form.touched.assign_to && form.errors.assign_to}
            onSearch={onAssignToSearch}
            onTouch={form.handleSetTouched}
            ariaLabel={t("ariaLabel.input.assignedTo")}
          />
        </InlineLabel>
      )}
      {Object.prototype.hasOwnProperty.call(optionalFields, "vendors_ids") && (
        <InlineLabel
          label={t("portal.form.vendor")}
          labelWidth={110}
          isRequired={optionalFields.vendors_ids.required}
          fontSize="14px"
          styles={{ fontWeight: 500 }}
          className="label"
        >
          <Select
            options={vendorsOptions}
            name="vendors"
            value={form.values.vendors}
            setValue={form.handleSetValue}
            isMulti
            menuPlacement="top"
            onBlur={form.handleBlur}
            noOptionsMessage={t("portal.form.depended.vendors.noOptions")}
            errorMessage={form.touched.vendors && form.errors.vendors}
            onSearch={onVendorsSearch}
            onTouch={form.handleSetTouched}
            ariaLabel={t("ariaLabel.input.vendors")}
          />
        </InlineLabel>
      )}

      <ContactTitle>
        {allow_anonymous
          ? t("portal.contacts") + t("portal.contactsOptional")
          : t("portal.contacts")}
      </ContactTitle>
      <Switch
        activated={form.values.notify}
        onClick={() => {
          const { handleSetValue } = form;
          const newValue = !form.values.notify;
          if (!newValue && allow_anonymous) {
            handleSetValue("portal_user_name", "");
            handleSetValue("portal_user_surname", "");
            handleSetValue("portal_user_email", "");
            handleSetValue("portal_user_phone_number", "");
          }
          handleSetValue("notify", newValue);
        }}
        ariaLabel={t("ariaLabel.switch.notifications")}
      >
        {t("portal.form.notify")}
      </Switch>
      <InlineLabel
        label={t("portal.form.portal_user_name")}
        labelWidth={110}
        isRequired={fieldRequired(requiredContactFields?.name)}
        fontSize="14px"
        styles={{ fontWeight: 500 }}
        className="label"
      >
        <Input
          name="portal_user_name"
          value={form.values.portal_user_name}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder={t("portal.form.portal_user_name")}
          errorMessage={
            form.touched.portal_user_name && form.errors.portal_user_name
          }
          aria-label={t("ariaLabel.input.firstName")}
          disabled={allow_anonymous ? !form.values.notify : false}
          required={fieldRequired(requiredContactFields?.name)}
        />
      </InlineLabel>
      <InlineLabel
        label={t("portal.form.portal_user_surname")}
        labelWidth={110}
        isRequired={fieldRequired(requiredContactFields?.surname)}
        fontSize="14px"
        styles={{ fontWeight: 500 }}
        className="label"
      >
        <Input
          name="portal_user_surname"
          value={form.values.portal_user_surname}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder={t("portal.form.portal_user_surname")}
          errorMessage={
            form.touched.portal_user_surname && form.errors.portal_user_surname
          }
          aria-label={t("ariaLabel.input.lastName")}
          disabled={allow_anonymous ? !form.values.notify : false}
          required={fieldRequired(requiredContactFields?.surname)}
        />
      </InlineLabel>
      <InlineLabel
        label={t("portal.form.portal_user_phone_number")}
        labelWidth={110}
        fontSize="14px"
        styles={{ fontWeight: 500 }}
        className="label"
      >
        <PhoneInput
          name="portal_user_phone_number"
          value={form.values.portal_user_phone_number}
          setValue={(value) =>
            form.setFieldValue("portal_user_phone_number", value)
          }
          errorMessage={form.errors.portal_user_phone_number}
          touched={() =>
            form.touched.portal_user_phone_number &&
            form.setTouched({ portal_user_phone_number: true })
          }
          aria-label={t("ariaLabel.input.phone")}
          disabled={allow_anonymous ? !form.values.notify : false}
        />
      </InlineLabel>
      <InlineLabel
        label={t("portal.form.portal_user_email")}
        labelWidth={110}
        isRequired={
          fieldRequired(requiredContactFields?.email) || form.values.notify
        }
        fontSize="14px"
        styles={{ fontWeight: 500 }}
        className="label"
      >
        <Input
          name="portal_user_email"
          type="email"
          value={form.values.portal_user_email}
          onChange={form.handleChange}
          onBlur={(e) => {
            form.handleBlur(e);
          }}
          placeholder={t("portal.form.portal_user_email")}
          errorMessage={
            form.touched.portal_user_email && form.errors.portal_user_email
          }
          aria-label={t("ariaLabel.input.email")}
          disabled={allow_anonymous ? !form.values.notify : false}
          required={
            fieldRequired(requiredContactFields?.email) || form.values.notify
          }
        />
      </InlineLabel>
      <InlineLabel label="" labelWidth={110} className="label">
        <Button
          onClick={onSubmit}
          disabled={disabledSubmit}
          isLoading={isSubmitLoading}
          variant="secondary"
          type="submit"
        >
          {t("portal.submit")}
        </Button>
      </InlineLabel>
    </Container>
  );
};
