import { t } from "i18next";

export const partsListSettings = {
  restock: {
    name: "added",
    color: "lime",
    sign: "+",
    isShowCost: true,
    linkName: "RSTK #",
    linkValue: "restock",
    showNote: true,
    isShowEditIcon: true,
  },

  initial_inventory: {
    name: "created",
    color: "lime",
    sign: "+",
    isShowCost: true,
    showNote: false,
    details: "",
    linkValue: "",
    isShowEditIcon: false,
  },
  reservation: {
    name: "reserved",
    color: "grey400",
    sign: "-",
    isShowCost: false,
    details: "",
    linkName: "WO #",
    linkValue: "work_order_id",
    showNote: false,
    isShowEditIcon: false,
  },
  planned: {
    name: "planned",
    color: "grey400",
    sign: "-",
    isShowCost: false,
    details: "",
    linkName: "",
    linkValue: "",
    showNote: false,
    isShowEditIcon: false,
  },
  usage: {
    name: "used",
    color: "corp_red_shadow",
    sign: "-",
    isShowCost: true,
    linkName: "WO #",
    linkValue: "work_order_id",
    showNote: false,
    isShowEditIcon: false,
  },
  usage_reversal: {
    name: "reversal",
    color: "lime",
    sign: "+",
    isShowCost: true,
    details: "",
    linkName: "WO #",
    showNote: false,
    linkValue: "work_order_id",
    isShowEditIcon: false,
  },
  cost_update: {
    name: "reversal",
    color: "lime",
    sign: "+",
    isShowCost: true,
    details: "",
    showNote: false,
    linkName: "CU #",
    linkValue: "restock",
    isShowEditIcon: true,
  },
};

export const measurementUnits = {
  1: {
    name: t("parts.measurement_units.pc.name"),
    short_name: t("parts.measurement_units.pc.short_name"),
  },
  2: {
    name: t("parts.measurement_units.itm.name"),
    short_name: t("parts.measurement_units.itm.short_name"),
  },
  3: {
    name: t("parts.measurement_units.set.name"),
    short_name: t("parts.measurement_units.set.short_name"),
  },
  4: {
    name: t("parts.measurement_units.in.name"),
    short_name: t("parts.measurement_units.in.short_name"),
  },
  5: {
    name: t("parts.measurement_units.ft.name"),
    short_name: t("parts.measurement_units.ft.short_name"),
  },
  6: {
    name: t("parts.measurement_units.yd.name"),
    short_name: t("parts.measurement_units.yd.short_name"),
  },
  7: {
    name: t("parts.measurement_units.mi.name"),
    short_name: t("parts.measurement_units.mi.short_name"),
  },
  8: {
    name: t("parts.measurement_units.mm.name"),
    short_name: t("parts.measurement_units.mm.short_name"),
  },
  9: {
    name: t("parts.measurement_units.cm.name"),
    short_name: t("parts.measurement_units.cm.short_name"),
  },
  10: {
    name: t("parts.measurement_units.m.name"),
    short_name: t("parts.measurement_units.m.short_name"),
  },
  11: {
    name: t("parts.measurement_units.km.name"),
    short_name: t("parts.measurement_units.km.short_name"),
  },
  12: {
    name: t("parts.measurement_units.oz.name"),
    short_name: t("parts.measurement_units.oz.short_name"),
  },
  13: {
    name: t("parts.measurement_units.lb.name"),
    short_name: t("parts.measurement_units.lb.short_name"),
  },
  14: {
    name: t("parts.measurement_units.t.name"),
    short_name: t("parts.measurement_units.t.short_name"),
  },
  15: {
    name: t("parts.measurement_units.mg.name"),
    short_name: t("parts.measurement_units.mg.short_name"),
  },
  16: {
    name: t("parts.measurement_units.g.name"),
    short_name: t("parts.measurement_units.g.short_name"),
  },
  17: {
    name: t("parts.measurement_units.kg.name"),
    short_name: t("parts.measurement_units.kg.short_name"),
  },
  18: {
    name: t("parts.measurement_units.ml.name"),
    short_name: t("parts.measurement_units.ml.short_name"),
  },
  19: {
    name: t("parts.measurement_units.l.name"),
    short_name: t("parts.measurement_units.l.short_name"),
  },
  20: {
    name: t("parts.measurement_units.cu_in.name"),
    short_name: t("parts.measurement_units.cu_in.short_name"),
  },
  21: {
    name: t("parts.measurement_units.cu_ft.name"),
    short_name: t("parts.measurement_units.cu_ft.short_name"),
  },
  22: {
    name: t("parts.measurement_units.cu_yd.name"),
    short_name: t("parts.measurement_units.cu_yd.short_name"),
  },
  23: {
    name: t("parts.measurement_units.cu_m.name"),
    short_name: t("parts.measurement_units.cu_m.short_name"),
  },
  24: {
    name: t("parts.measurement_units.sq_in.name"),
    short_name: t("parts.measurement_units.sq_in.short_name"),
  },
  25: {
    name: t("parts.measurement_units.sq_ft.name"),
    short_name: t("parts.measurement_units.sq_ft.short_name"),
  },
  26: {
    name: t("parts.measurement_units.sq_yd.name"),
    short_name: t("parts.measurement_units.sq_yd.short_name"),
  },
  27: {
    name: t("parts.measurement_units.sq_m.name"),
    short_name: t("parts.measurement_units.sq_m.short_name"),
  },
  28: {
    name: t("parts.measurement_units.gal.name"),
    short_name: t("parts.measurement_units.gal.short_name"),
  },
  29: {
    name: t("parts.measurement_units.qt.name"),
    short_name: t("parts.measurement_units.qt.short_name"),
  },
  30: {
    name: t("parts.measurement_units.pt.name"),
    short_name: t("parts.measurement_units.pt.short_name"),
  },
  31: {
    name: t("parts.measurement_units.fl_oz.name"),
    short_name: t("parts.measurement_units.fl_oz.short_name"),
  },
  32: {
    name: t("parts.measurement_units.tsp.name"),
    short_name: t("parts.measurement_units.tsp.short_name"),
  },
  33: {
    name: t("parts.measurement_units.tbsp.name"),
    short_name: t("parts.measurement_units.tbsp.short_name"),
  },
  34: {
    name: t("parts.measurement_units.c.name"),
    short_name: t("parts.measurement_units.c.short_name"),
  },
};
