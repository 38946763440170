import { Header } from "widgets/Header";
import AddIcon from "shared/assets/icon/add.svg?react";
import { TablePageLayout } from "shared/components/TablePageLayout";
import { Container } from "shared/components/Container";
import { ModalLink } from "shared/components/ModalLink";
import { useTranslation } from "react-i18next";
import { List as ProceduresList } from "modules/procedures";
import { useSetModal } from "widgets/ModalController";
import { usePermission } from "app/providers/PermissionsProvider";
import { useMemo } from "react";

export const List = () => {
  const { t } = useTranslation();
  const setModal = useSetModal();
  const { procedureTemplateListView, procedureTemplateCreatePermit } =
    usePermission();

  const action = useMemo(() => {
    if (procedureTemplateCreatePermit) {
      return (
        <ModalLink
          type="button"
          icon={<AddIcon />}
          modalParams={{ type: "procedureTemplate", id: "new" }}
        >
          {t("procedures.title_singular")}
        </ModalLink>
      );
    }

    return;
  }, [procedureTemplateCreatePermit, t]);

  return (
    <TablePageLayout>
      {procedureTemplateListView && (
        <>
          <Header
            title={t("procedures.title")}
            searchPlaceholder={t("procedures.searchPlaceholder")}
            action={action}
            horizontallySpaciousPage
          />
          <Container>
            <ProceduresList
              onOpenProcedureTemplate={(id: string | number) =>
                setModal({ type: "procedureTemplate", id })
              }
            />
          </Container>
        </>
      )}
    </TablePageLayout>
  );
};
