import { t } from "i18next";

export const INITIAL_SCHEDULE_VALUES = {
  type: "one_time",
  time_to_complete: 1,
  start_date: "",
  on_week_days: ["1"],
  in_months: ["1"],
  on_month_day: "1",
  repeat_occurrence: "1",
  repeat_frequency: "day",
};

export const FilterStatusOptions = [
  { key: "planned", value: t("preventive_maintenances.status.planned") },
  { key: "paused", value: t("preventive_maintenances.status.paused") },
  {
    key: "completed",
    value: t("preventive_maintenances.status.completed"),
  },
];

export const weekDaysOptions = [
  {
    key: "7",
    label: t("work_orders.form.pm_schedule_attributes.week_days.sun"),
  },
  {
    key: "1",
    label: t("work_orders.form.pm_schedule_attributes.week_days.mon"),
  },
  {
    key: "2",
    label: t("work_orders.form.pm_schedule_attributes.week_days.tue"),
  },
  {
    key: "3",
    label: t("work_orders.form.pm_schedule_attributes.week_days.wed"),
  },
  {
    key: "4",
    label: t("work_orders.form.pm_schedule_attributes.week_days.thu"),
  },
  {
    key: "5",
    label: t("work_orders.form.pm_schedule_attributes.week_days.fri"),
  },
  {
    key: "6",
    label: t("work_orders.form.pm_schedule_attributes.week_days.sat"),
  },
];

export const monthOptions = [
  {
    key: "1",
    label: t("work_orders.form.pm_schedule_attributes.months.jan"),
  },
  {
    key: "2",
    label: t("work_orders.form.pm_schedule_attributes.months.feb"),
  },
  {
    key: "3",
    label: t("work_orders.form.pm_schedule_attributes.months.mar"),
  },
  {
    key: "4",
    label: t("work_orders.form.pm_schedule_attributes.months.apr"),
  },
  {
    key: "5",
    label: t("work_orders.form.pm_schedule_attributes.months.may"),
  },
  {
    key: "6",
    label: t("work_orders.form.pm_schedule_attributes.months.jun"),
  },
  {
    key: "7",
    label: t("work_orders.form.pm_schedule_attributes.months.jul"),
  },
  {
    key: "8",
    label: t("work_orders.form.pm_schedule_attributes.months.aug"),
  },
  {
    key: "9",
    label: t("work_orders.form.pm_schedule_attributes.months.sep"),
  },
  {
    key: "10",
    label: t("work_orders.form.pm_schedule_attributes.months.oct"),
  },
  {
    key: "11",
    label: t("work_orders.form.pm_schedule_attributes.months.nov"),
  },
  {
    key: "12",
    label: t("work_orders.form.pm_schedule_attributes.months.dec"),
  },
];

export const frequencyOptions = [
  {
    key: "hour",
    value: t("work_orders.form.pm_schedule_attributes.repeat_options.hour"),
  },
  {
    key: "day",
    value: t("work_orders.form.pm_schedule_attributes.repeat_options.day"),
  },
  {
    key: "week",
    value: t("work_orders.form.pm_schedule_attributes.repeat_options.week"),
  },
  {
    key: "month",
    value: t("work_orders.form.pm_schedule_attributes.repeat_options.month"),
  },
  {
    key: "year",
    value: t("work_orders.form.pm_schedule_attributes.repeat_options.year"),
  },
];

export const lastDaysOfMonth = ["28", "29", "30", "31"];
export const extraMonthOptions = [29, 30, 31];
export const exceptionalMonths = [1, 2, 3, 21, 22, 23, 31];
export const extraDayOptions = ["45", "60", "90", "120", "150", "180"];
export const maxNumbersOptions = {
  year: 50,
  hour: 24,
  day: 30,
  week: 52,
  month: 36,
};
