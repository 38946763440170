import { useUpdateUserDetailsMutation } from "modules/users";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "shared/components/Button";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { Box, Title, FunctionalityLine, Label, Value } from "../styled";
import { LanguageEditor } from "./LanguageEditor";
import { ConfirmationDialog } from "shared/components/Dialog";
import i18n from "app/i18n";
import { getUser, setUser } from "shared/components/Auth";

export const Language = ({ value, form, userId }) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [updateUserDetails] = useUpdateUserDetailsMutation();
  const { addSnackBar } = useSnackBar();
  const user = getUser();

  const initialLanguage = form.initialValues.language;
  const setLanguage = form.values.language?.value;
  const isDirty = initialLanguage !== setLanguage;
  const isValid = ["en", "es", "fr"].includes(setLanguage);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    form.resetForm();
    setShowModal(false);
  };

  const handleSubmit = async () => {
    updateUserDetails({
      id: userId,
      body: {
        language: setLanguage,
      },
    })
      .unwrap()
      .then(({ data }) => {
        setUser({ ...user, language: data.language });
        handleCloseModal();

        i18n.changeLanguage(data.language);
        localStorage.setItem(
          "languageChangeMessage",
          t("profile.language.successUpdate")
        );

        window.location.reload();
      })
      .catch(({ message }) => {
        addSnackBar({
          content: JSON.stringify(message),
          type: "error",
          secondaryButtonLabel: t("close"),
        });
      });
  };

  useEffect(() => {
    const message = localStorage.getItem("languageChangeMessage");
    if (message) {
      addSnackBar({
        content: message,
        type: "success",
      });
      localStorage.removeItem("languageChangeMessage");
    }
  }, [addSnackBar]);

  return (
    <>
      <Box>
        <Title>{t("profile.language.title")}</Title>
        <FunctionalityLine>
          <div>
            <Label margin="30">{t("profile.language.title")}</Label>
            <Value>{t(`profile.language.options.${value}`)}</Value>
          </div>
          <Button variant="secondary" onClick={handleOpenModal}>
            {t("profile.change")}
          </Button>
        </FunctionalityLine>
      </Box>
      {showModal && (
        <ConfirmationDialog
          title={t("profile.language.modalTitle")}
          confirmButtonLabel={t("save")}
          cancelButtonLabel={t("discardChanges")}
          form={form}
          disabledSubmit={!isDirty || !isValid}
          description={<LanguageEditor form={form} />}
          onProceed={handleSubmit}
          onCancel={handleCloseModal}
        />
      )}
    </>
  );
};
