import {
  IFormattedChange,
  IHistory,
} from "modules/workOrders/lib/helpers/getHistoryContent";
import { Hint } from "shared/components/Typography";
import { useDisplayDate } from "shared/lib/hooks";
import {
  Header,
  Wrapper,
  Name,
  Title,
  Description,
  CreatedAt,
  Content,
  ArrowContainer,
} from "./WorkOrderHistoryList.styled";
import { isNotBlank } from "./helpers";
import { ProfileIcon } from "./ProfileIcon";
import styled from "styled-components";
import ArrowIcon from "shared/assets/icon/arrow-right.svg?react";
import { View } from "./View";

interface GenericViewProps {
  change: IFormattedChange;
  history: IHistory;
}

export const GenericView = ({ history, change }: GenericViewProps) => {
  const displayDate = useDisplayDate();
  const { wideDisplay } = change;
  const { modifier, modifier_name, created_at } = history;

  const name =
    modifier_name === "System" ? "Scheduled event" : modifier?.full_name;

  return (
    <Wrapper>
      <ProfileIcon modifier={modifier} />

      <Header>
        <Name>{name}</Name>
        <Title>{change?.key}</Title>
      </Header>
      {change.description && (
        <Description>
          <Hint>{change.description}</Hint>
        </Description>
      )}
      <CreatedAt>{displayDate(created_at, true) as string}</CreatedAt>
      {wideDisplay ? <Wide change={change} /> : <Narrow change={change} />}
    </Wrapper>
  );
};

const Wide = ({ change }: { change: IFormattedChange }) => (
  <Content>
    {isNotBlank(change.from as unknown[]) && (
      <View value={change.from as unknown[]} wide />
    )}
    {isNotBlank(change.from as unknown[]) &&
      isNotBlank(change.to as unknown[]) && (
        <ArrowContainer>
          <ArrowIconStyled />
        </ArrowContainer>
      )}
    {isNotBlank(change.to as unknown[]) && (
      <View value={change.to as unknown[]} wide />
    )}
  </Content>
);

const Narrow = ({ change }: { change: IFormattedChange }) => (
  <Content>
    <Row>
      {isNotBlank(change.from) && (
        <View value={change.from} isDate={change.isDate} />
      )}
      {isNotBlank(change.from) && isNotBlank(change.to) && (
        <ArrowContainer>
          <ArrowIconStyled />
        </ArrowContainer>
      )}
      {isNotBlank(change.to) && (
        <View value={change.to} isDate={change.isDate} />
      )}
    </Row>
  </Content>
);

const Row = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: flex-start;
`;

const ArrowIconStyled = styled(ArrowIcon)`
  height: 20px;
  width: 20px;
  color: var(--color_grey700);
`;
