import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import EditIcon from "shared/assets/icon/edit.svg?react";

import { IconButton } from "shared/components/IconButton";

export const useAdminReferenceTableTools = ({ onOpen, entities, module }) => {
  const { t } = useTranslation();

  const entitiesLength = entities?.length;

  const columns = useMemo(() => {
    return [
      {
        field: "name",
        name: t(`settings.referenceLists.${module}.columnName`),
        sortable: true,
        width: 450,
        notPrimary: true,
      },
      {
        field: "entities",
        name: t("request_portals.columns.entity"),
        formatValue: (value) =>
          value?.length === entitiesLength
            ? "All"
            : value?.map(
                ({ name }, index) =>
                  `${name}${index !== value?.length - 1 ? ", " : " "}`
              ),
        width: 350,
      },
      {
        field: "edit",
        renderCell: (row, value) => (
          <IconButton onClick={() => onOpen(row.id)}>
            <EditIcon />
          </IconButton>
        ),
        edit: true,
        width: 50,
      },
    ];
  }, [t, entities]);

  return {
    columns,
  };
};
