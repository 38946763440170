import { usePermission } from "app/providers/PermissionsProvider";
import { Deletion } from "features/Deletion";
import {
  useDestroyProcedureTemplateMutation,
  useGetProcedureTemplateQuery,
} from "modules/procedures/state/proceduresApi";
import { IProcedureTemplate } from "modules/procedures/types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "shared/components/Button";
import { HeaderContextMenu, ViewModal } from "shared/components/Modals";
import {
  HeaderDividerStyled,
  HintWrapper,
  ViewHeader,
  ViewHeaderWrapper,
  ViewName,
} from "shared/components/Modals/components/Modals.styled";
import { Description, H3, Hint } from "shared/components/Typography";
import styled from "styled-components";
import { Item } from "./TemplateItems/Item";
import { heading, field } from "@test-data";

interface ProcedureViewProps {
  isOpen: boolean;
  onClose: () => void;
  onEdit: () => void;
  procedureTemplateId: string | number;
}

export const ProcedureTemplateView = ({
  isOpen,
  onEdit,
  procedureTemplateId,
  onClose,
}: ProcedureViewProps) => {
  const { data } = useGetProcedureTemplateQuery(procedureTemplateId);
  const procedureTemplate = data as IProcedureTemplate;
  const { procedureTemplateEditPermit } = usePermission();
  const { t } = useTranslation();

  const showEdit = useMemo(() => {
    return (
      procedureTemplateEditPermit as (createdById: string | number) => boolean
    )(procedureTemplate?.created_by_id);
  }, [procedureTemplate?.created_by_id, procedureTemplateEditPermit]);

  return (
    <ViewModal
      isOpen={isOpen}
      onClose={onClose}
      label={t("procedures.view", { label: procedureTemplate?.name })}
      subHeader={
        showEdit ? (
          <SubHeader
            onEdit={onEdit}
            procedureTemplate={procedureTemplate}
            onClose={onClose}
          />
        ) : null
      }
    >
      <ViewHeaderWrapper>
        <ViewHeader>
          <HintWrapper data-testid={heading.viewModalHint.id}>
            <Hint>#{procedureTemplateId}</Hint>
          </HintWrapper>
          <ViewName data-testid={field.name.viewModalId}>
            {procedureTemplate?.name}
          </ViewName>
          <Description data-testid={field.description.viewModalId}>
            {procedureTemplate?.description}
          </Description>
        </ViewHeader>
      </ViewHeaderWrapper>
      <HeaderDividerStyled />
      <ProcedureItemList>
        <H3>{t("procedures.items.view_title")}</H3>
        {procedureTemplate?.items?.map((item) => (
          <Item item={item} key={item?.id} />
        ))}
      </ProcedureItemList>
    </ViewModal>
  );
};

const SubHeader = ({
  procedureTemplate,
  onEdit,
  onClose,
}: {
  procedureTemplate: IProcedureTemplate;
  onEdit: () => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  const [destroyTemplate] = useDestroyProcedureTemplateMutation();

  const { procedureTemplateDeletePermit } = usePermission();

  const handleDelete = () => {
    return destroyTemplate(procedureTemplate?.id);
  };

  const allowDelete = useMemo(() => {
    return (
      procedureTemplateDeletePermit as (createdById: string | number) => boolean
    )(procedureTemplate?.created_by_id);
  }, [procedureTemplate?.created_by_id, procedureTemplateDeletePermit]);

  return (
    <SubHeaderContainer>
      <Button variant="secondary" onClick={onEdit}>
        {t("edit")}
      </Button>
      {allowDelete && (
        <HeaderContextMenu disabled={!allowDelete}>
          <Deletion
            id={procedureTemplate?.id}
            module={t("procedures.title_singular") as string}
            onSuccess={onClose}
            confirmation={{
              title: t("procedures.deletion.confirmation.title"),
              description: t("procedures.deletion.confirmation.description"),
              confirmButtonLabel: t(
                "procedures.deletion.confirmation.confirmation_button"
              ),
            }}
            onDelete={handleDelete}
          />
        </HeaderContextMenu>
      )}
    </SubHeaderContainer>
  );
};

const SubHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

const ProcedureItemList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
