import * as Styled from "./WorkOrdersKPIs.styled";
import { WorkOrdersKPIsDonutChart } from "modules/workOrders/components/WorkOrdersKPIs/components/WorkOrdersKPIsDonutChart";
import { useTranslation } from "react-i18next";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { StatusMap } from "modules/workOrders/config/constants";
import { WorkOrdersKPIsReactivePreventiveChart } from "modules/workOrders/components/WorkOrdersKPIs/components/WorkOrdersKPIsReactivePreventiveChart";
import { WorkOrdersKPIsTextItem } from "modules/workOrders/components/WorkOrdersKPIs/components/WorkOrdersKPIsTextItem";

interface WorkOrdersKPIData {
  overdue?: {
    overdue: number;
    active: number;
  };
  unassigned?: {
    unassigned: number;
    active: number;
  };
  by_status?: {
    key: string;
    value_percentage: number;
  }[];
  by_priority?: {
    key: string;
    value_percentage: number;
  }[];
  reactive?: {
    reactive: number;
    reactive_percentage: number;
  };
  preventive?: {
    preventive: number;
    preventive_percentage: number;
  };
}

interface WorkOrdersKPIsProps {
  data?: WorkOrdersKPIData;
  isLoading?: boolean;
}

/** The KPI panel at the top of the Work Order list page */
export const WorkOrdersKPIs = ({ data, isLoading }: WorkOrdersKPIsProps) => {
  const { t } = useTranslation();
  const { byStatusConfigs, byPriorityConfigs } = useSliceConfigs();

  if (!isLoading && (!data || Object.keys(data).length !== 6)) {
    return <WorkOrdersKPIsFallback />;
  }

  return (
    <Styled.Wrapper>
      <WorkOrdersKPIsTextItem
        value={data?.overdue?.overdue}
        label={t("work_orders.kpis.overdue")}
        description={
          t("work_orders.kpis.description", { total: data?.overdue?.active }) ??
          ""
        }
        isLoading={isLoading}
      />
      <WorkOrdersKPIsTextItem
        value={data?.unassigned?.unassigned}
        label={t("work_orders.kpis.unassigned")}
        description={
          t("work_orders.kpis.description", { total: data?.overdue?.active }) ??
          ""
        }
        isLoading={isLoading}
      />
      <WorkOrdersKPIsDonutChart
        label={t("work_orders.kpis.backlogByStatus")}
        data={data?.by_status ?? []}
        sliceConfigs={byStatusConfigs}
        isLoading={isLoading}
      />
      <WorkOrdersKPIsDonutChart
        label={t("work_orders.kpis.backlogByPriority")}
        data={data?.by_priority ?? []}
        sliceConfigs={byPriorityConfigs}
        isLoading={isLoading}
      />
      <WorkOrdersKPIsReactivePreventiveChart
        data={data}
        isLoading={isLoading}
      />
    </Styled.Wrapper>
  );
};

export const WorkOrdersKPIsFallback = () => {
  const { t } = useTranslation();
  return <Styled.Error>{t("work_orders.kpis.error")}</Styled.Error>;
};

function useSliceConfigs() {
  const { t } = useTranslation();
  const byStatusConfigs = {
    open: {
      fill: "var(--color_open)",
      label: t(`reporting.${ReportKey.BacklogByStatus}.labels.open`),
      icon: StatusMap.open.iconUrl,
    },
    on_hold: {
      fill: "var(--color_on_hold)",
      label: t(`reporting.${ReportKey.BacklogByStatus}.labels.on_hold`),
    },
    in_progress: {
      fill: "var(--color_in_progress)",
      label: t(`reporting.${ReportKey.BacklogByStatus}.labels.in_progress`),
    },
    in_review: {
      fill: "var(--color_in_review)",
      label: t(`reporting.${ReportKey.BacklogByStatus}.labels.in_review`),
    },
    waiting_for_parts: {
      fill: "var(--color_waiting_for_parts)",
      label: t(
        `reporting.${ReportKey.BacklogByStatus}.labels.waiting_for_parts`
      ),
    },
    waiting_for_cost: {
      fill: "var(--color_waiting_for_cost)",
      label: t(
        `reporting.${ReportKey.BacklogByStatus}.labels.waiting_for_cost`
      ),
    },
    waiting_for_invoice: {
      fill: "var(--color_waiting_for_invoice)",
      label: t(
        `reporting.${ReportKey.BacklogByStatus}.labels.waiting_for_invoice`
      ),
    },
  };
  const byPriorityConfigs = {
    none: {
      fill: "var(--color_grey400)",
      label: t(`reporting.${ReportKey.BacklogByPriority}.labels.none`),
    },
    low: {
      fill: "var(--color_priority_lime)",
      label: t(`reporting.${ReportKey.BacklogByPriority}.labels.low`),
    },
    medium: {
      fill: "var(--color_priority_yellow)",
      label: t(`reporting.${ReportKey.BacklogByPriority}.labels.medium`),
    },
    high: {
      fill: "var(--color_priority_orange)",
      label: t(`reporting.${ReportKey.BacklogByPriority}.labels.high`),
    },
    critical: {
      fill: "var(--color_priority_red)",
      label: t(`reporting.${ReportKey.BacklogByPriority}.labels.critical`),
    },
  };

  return { byStatusConfigs, byPriorityConfigs };
}
