import { ReportKey } from "modules/reporting/types/ReportKey";
import { ReportPageLayout } from "modules/reporting/components/ReportPageLayout";
import { ReportPageTopBar } from "modules/reporting/components/ReportPageTopBar";
import { ReportPageProvider } from "modules/reporting/lib/ReportPageContext";
import { useReportPeriod } from "modules/reporting/lib/useReportPeriod";
import { useGetChartData } from "modules/reporting/lib/useGetChartData";
import { useGetTableData } from "modules/reporting/lib/useGetTableData";
import {
  PDFReportDownloader,
  PDFReportDownloaderHandle,
} from "modules/reporting/components/PDFReportDownloader/PDFReportDownloader";
import { getReportFilename } from "modules/reporting/lib/getReportFilename";
import { useTranslation } from "react-i18next";
import { ExportMenu } from "modules/reporting/components/ExportMenu";
import { useDownloadReportXLS } from "modules/reporting/lib/useDownloadReportXLS";
import { usePersistentState } from "modules/reporting/lib/usePersistentState";
import { useRef } from "react";
import { PartsInventoryChartData, PartsInventoryTableData } from "./types";
import { usePartsInventoryKPIs } from "./usePartsInventoryReportKPIs";
import { PartsInventoryReportChart } from "./PartsInventoryReportChart";
import styled from "styled-components";
import { ToggleButtonGroup } from "../../ToggleButtonGroup";
import { PartsInventoryReportActionBar } from "./PartsInventoryReportActionBar";
import { PartsInventoryReportTable } from "./PartsInventoryReportTable";
import { PartsInventoryKPIGrid } from "./PartsInventoryKPIGrid";
import { useFiltersParam } from "shared/lib/hooks";
import { stringifyFilters } from "modules/filters/lib/stringifyFilters";
import { Entity } from "modules/reporting/types/Entity";

type PartsInventoryReportChartMode = "parts" | "part_types";

const reportKey = ReportKey.PartsInventory;
const entity = Entity.parts;

export const PartsInventoryReport = () => {
  const { t } = useTranslation();
  const [reportPeriod, setReportPeriod] = useReportPeriod();
  const pdfDownloaderRef = useRef<PDFReportDownloaderHandle>(null);
  const kpiRes = usePartsInventoryKPIs(reportPeriod);
  const chartRes = useGetChartData<PartsInventoryChartData>(
    reportKey,
    reportPeriod,
    undefined,
    entity
  );
  const tableRes = useGetTableData<PartsInventoryTableData[]>(
    reportKey,
    reportPeriod,
    entity
  );

  const [filters] = useFiltersParam([]);
  const stringifiedFilters = stringifyFilters(filters, entity);
  const downloadXLS = useDownloadReportXLS(
    reportKey,
    reportPeriod,
    stringifiedFilters
  );

  const [mode, setMode] = usePersistentState("parts", "part_types");
  const isExportReady = !chartRes.isFetching && !kpiRes.isFetching;
  return (
    <ReportPageProvider value={{ reportPeriod, setReportPeriod }}>
      <ReportPageTopBar />
      <ReportPageLayout
        reportKey={reportKey}
        kpis={
          <PartsInventoryKPIGrid
            isLoading={kpiRes.isFetching}
            totalCosts={kpiRes.total_costs}
            part_counts={kpiRes.top_part_counts}
            part_type_counts={kpiRes.top_part_type_counts}
            mode={mode}
            parts={kpiRes.top_parts_costs}
            part_types={kpiRes.top_part_types_costs}
          />
        }
        chart={
          <Wrapper>
            <ModeOptions
              value={mode}
              onChange={(val) => setMode(val as PartsInventoryReportChartMode)}
              ariaLabel={t(`reporting.${ReportKey.PartsInventory}.chart.mode`)}
              options={[
                {
                  label: t(`reporting.${ReportKey.PartsInventory}.chart.parts`),
                  value: "parts",
                },
                {
                  label: t(
                    `reporting.${ReportKey.PartsInventory}.chart.part_types`
                  ),
                  value: "part_types",
                },
              ]}
            />
            <PartsInventoryReportChart
              mode={mode}
              setMode={setMode}
              data={chartRes.data}
              isFetching={chartRes.isFetching}
            />
          </Wrapper>
        }
        actionBar={
          <PartsInventoryReportActionBar
            buttons={
              <ExportMenu
                downloadXLS={() => downloadXLS()}
                downloadPDF={() => pdfDownloaderRef.current?.doDownload()}
                isPDFReady={isExportReady}
              />
            }
            entity={entity}
          />
        }
        table={
          <PartsInventoryReportTable
            data={tableRes.data?.data}
            paginationData={tableRes.data?.meta?.pagination}
            isFetching={tableRes.isFetching}
          />
        }
      />
      <PDFReportDownloader
        ref={pdfDownloaderRef}
        filename={getReportFilename(reportKey, "pdf", reportPeriod)}
        exportProps={{
          reportName: t(`reporting.${reportKey}.title`),
          reportPeriod,
          kpis: [
            ...kpiRes.top_part_counts,
            ...kpiRes.top_part_type_counts,
            ...kpiRes.top_parts_costs,
            ...kpiRes.top_part_types_costs,
          ],
        }}
        charts={[
          {
            title: t(`reporting.${reportKey}.chart.parts`) as string,
            chart: (
              <div style={{ width: "1000px", height: "400px" }}>
                <PartsInventoryReportChart
                  mode={"parts"}
                  setMode={setMode}
                  data={chartRes.data}
                  isFetching={chartRes.isFetching}
                />
              </div>
            ),
          },
          {
            title: t(`reporting.${reportKey}.chart.part_types`) as string,
            chart: (
              <div style={{ width: "1000px", height: "400px" }}>
                <PartsInventoryReportChart
                  mode={"part_types"}
                  setMode={setMode}
                  data={chartRes.data}
                  isFetching={chartRes.isFetching}
                />
              </div>
            ),
          },
        ]}
      />
    </ReportPageProvider>
  );
};
const ModeOptions = styled(ToggleButtonGroup)`
  position: absolute;
  margin-bottom: 3px;
  transform: translateY(-100%);
  right: 0;
  top: 0;
`;

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  box-sizing: border-box;
`;
