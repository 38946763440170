import {
  WorkOrderView,
  WorkOrderProvider,
} from "modules/workOrders/components/WorkOrderView";
import { usePermission } from "app/providers/PermissionsProvider";
import { WorkOrderCreate } from "modules/workOrders/components/WorkOrderCreate";
import { WorkOrderEdit } from "modules/workOrders/components/WorkOrderEdit";
import { useSetModal } from "widgets/ModalController";
import { ModalParams } from "widgets/ModalController/types";

interface WorkOrderModalProps {
  params: ModalParams;
}

export const WorkOrderModal = ({ params }: WorkOrderModalProps) => {
  const { type, id, state = "view" } = params;
  const { woCreatePermit, woViewPermit } = usePermission();
  const setModal = useSetModal();
  const onClose = () => setModal();
  const onView = () => setModal({ type, id });

  if (id === "new" && woCreatePermit) {
    return (
      <WorkOrderCreate isShow={true} onOpen={setModal} onClose={onClose} />
    );
  } else if (state === "view" && woViewPermit) {
    return (
      <WorkOrderProvider>
        <WorkOrderView
          isShow={true}
          onClose={onClose}
          onEdit={() => setModal({ type, id, state: "edit" })}
          workOrderId={id}
        />
      </WorkOrderProvider>
    );
  } else if (state === "edit") {
    return (
      <WorkOrderEdit
        isShow={true}
        onClose={onView}
        onOpen={onView}
        workOrderId={id}
      />
    );
  }

  return null;
};
