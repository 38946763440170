import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import SearchIcon from "shared/assets/icon/search.svg?react";
import ClearIcon from "shared/assets/icon/clear.svg?react";
import PropTypes from "prop-types";
import { useSearchParam } from "shared/lib/hooks";
import { IconButton } from "shared/components/IconButton/index.js";
import { useTranslation } from "react-i18next";

export const Search = ({ placeholder = "Search" }) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [search, setSearch] = useSearchParam();

  useEffect(() => {
    // Updates the search value if it changes externally
    if (ref.current) {
      ref.current.value = search ?? "";
    }
  }, [search]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (ref.current.value !== search) {
      setSearch(ref.current.value);
    }
  };

  return (
    <Wrapper onSubmit={onSubmit}>
      <Box>
        <input
          ref={ref}
          placeholder={placeholder}
          type="search"
          aria-label="Search"
        />
        <ClearButton
          tabIndex={-1}
          aria-label={t("clear")}
          onClick={() => setSearch(null)}
        >
          <ClearIcon />
        </ClearButton>
      </Box>
      <SearchButton
        aria-label={t(`ariaLabel.button.submitSearch`)}
        onClick={onSubmit}
      >
        <SearchIcon />
      </SearchButton>
    </Wrapper>
  );
};

Search.propTypes = {
  placeholder: PropTypes.string,
};

const Wrapper = styled.form`
  display: flex;
`;

const ClearButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 44px;
  color: var(--color_header_search_clear_color);
  border: none;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
  background: transparent;
  display: none;

  outline-color: var(--color_primary);

  &:hover:not(:placeholder-shown) {
    cursor: pointer;
    display: flex;
  }
`;

const Box = styled.div`
  width: 387px;
  height: 44px;
  position: relative;

  input {
    background-color: var(--color_header_search_background);
    border-top-left-radius: var(--border_radius_lg);
    border-bottom-left-radius: var(--border_radius_lg);
    box-sizing: border-box;
    outline: none;
    border: none;
    height: 100%;
    width: 100%;
    padding: 0 16px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: var(--color_header_search_text);

    &:focus,
    &:active {
      box-shadow: inset 0 0 0 2px var(--color_primary);
      background-color: var(--color_header_search_background_hover);
    }

    &:focus:not(:placeholder-shown),
    &:active:not(:placeholder-shown),
    &:hover:not(:placeholder-shown) {
      & ~ ${ClearButton} {
        display: flex;
      }
    }

    &::placeholder {
      color: var(--color_header_search_text);
      opacity: 0.5;
    }

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button {
      appearance: none;
    }
  }
`;

const SearchButton = styled(IconButton)`
  padding: 8px 16px;
  border-left: 1px solid var(--color_header_border);
  border-top-right-radius: var(--border_radius_lg);
  border-bottom-right-radius: var(--border_radius_lg);

  background-color: var(--color_header_search_background);
  outline: none;

  &:hover,
  &:focus-visible {
    background-color: var(--color_primary_hover);
  }

  &:focus-visible {
    box-shadow: inset 0 0 0 2px var(--color_primary);
  }
`;
