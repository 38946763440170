import { t } from "i18next";
import { ProcedureTemplateItemValidationSchema } from "modules/procedures/lib/form";
import { useUpdateProcedureTemplateItemMutation } from "modules/procedures/state/proceduresApi";
import { IProcedureTemplateItem } from "modules/procedures/types";
import { EditorModal } from "shared/components/Modals";
import { useForm } from "shared/lib/hooks/useForm";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { Editor } from "./Editor";
import * as Sentry from "@sentry/react";
import { button } from "@test-data";

interface TemplateItemCreateProps {
  procedureTemplateItem: IProcedureTemplateItem;
  isOpen: boolean;
  onClose: () => void;
}

export const TemplateItemEditModal = ({
  procedureTemplateItem,
  isOpen,
  onClose,
}: TemplateItemCreateProps) => {
  const [updateTemplateItem] = useUpdateProcedureTemplateItemMutation();
  const { addSnackBar } = useSnackBar();

  const closeModal = () => {
    form.resetForm();
    onClose();
  };

  const form = useForm({
    initialValues: {
      name: procedureTemplateItem.name,
      item_type: procedureTemplateItem.item_type,
      options: procedureTemplateItem.options,
    },
    validationSchema: ProcedureTemplateItemValidationSchema(t),
    enableReinitialize: true,
    onSubmit: async (data) => {
      const body = {
        procedure_template_item: {
          ...data,
        },
      };

      try {
        await updateTemplateItem({
          id: procedureTemplateItem.id,
          body,
        }).unwrap();

        addSnackBar({
          title: t("procedures.snackbars.item_updated.success.title"),
          type: "success",
        });

        closeModal();
      } catch (error) {
        Sentry.addBreadcrumb({
          category: "procedures",
          message: "Failure while attempting to edit ProcedureTemplateItem",
          data: body,
        });
        Sentry.captureException(error);
        addSnackBar({
          title: t("fallback.error"),
          content: (
            <>
              <p>{t("fallback.description_a")}</p>
              <p>{t("fallback.description_b")}</p>
            </>
          ),
          type: "error",
        });
      }
    },
  });

  const submit = () => {
    form.handleSubmit();
  };

  return (
    <EditorModal
      isOpen={isOpen}
      isSubmitLoading={form.isSubmitting}
      onClose={closeModal}
      onSubmit={submit}
      label={t("procedures.items.edit_title")}
      form={form}
      dataTestId={button.saveEditProcedureItem.id}
    >
      <Editor form={form} />
    </EditorModal>
  );
};
