import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Name } from "shared/components/Table";
import { useDisplayDate } from "shared/lib/hooks";
import { RequestLink } from "modules/requestPortals/components/RequestLink";

export const useRequestPortalsTableTools = ({ onOpen }) => {
  const { t } = useTranslation();
  const displayDate = useDisplayDate();

  const columns = useMemo(() => {
    return [
      {
        field: "title",
        name: t("request_portals.columns.title"),
        width: 400,
        sortable: true,
        renderCell: (row, value) => (
          <Name onClick={() => onOpen(row.uuid)} value={value} />
        ),
        primary: true,
        order: 0,
      },
      {
        field: "description",
        name: t("request_portals.columns.description"),
        width: 320,
        sortable: true,
      },
      {
        field: "entity",
        sortQueryParam: "entity_name",
        name: t("request_portals.columns.entity"),
        width: 200,
        sortable: true,
        renderCell: ({ entity }) => (
          <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            {entity?.name ?? ""}
          </span>
        ),
      },
      {
        field: "uuid",
        name: t("request_portals.columns.link"),
        width: 200,
        sortable: false,
        renderCell: ({ uuid }) => <RequestLink uuid={uuid} />,
      },
      {
        field: "allow_anonymous",
        name: t("request_portals.columns.allowAnonymous"),
        width: 125,
        sortable: false,
        renderCell: ({ allow_anonymous }) => (
          <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            {allow_anonymous
              ? t("request_portals.allow_anonymous.yes")
              : t("request_portals.allow_anonymous.no")}
          </span>
        ),
      },
      {
        field: "created_at",
        name: t("request_portals.columns.createdAt"),
        width: 200,
        sortable: true,
        formatValue: (value) => displayDate(value),
      },
    ];
  }, [t, onOpen, displayDate]);

  return {
    columns,
  };
};
