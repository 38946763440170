import { t } from "i18next";
import { pdf } from "@react-pdf/renderer";

export const getProgressionOptions = ({ status = "", percentage, id }) => {
  let containerColor = "var(--color_grey100)";
  let percentageColor = "var(--color_grey100)";

  if (["paused", "completed"].includes(status) && !id) {
    return {
      containerColor,
      percentageColor,
      percentageValue: 0,
      showPercentage: false,
    };
  }

  if (percentage >= 76) {
    percentageColor = "var(--color_corp_red)";
    containerColor = "var(--color_corp_red_light)";
  }

  if (percentage < 76) {
    percentageColor = "var(--color_corp_lime_dark)";
    containerColor = "var(--color_sheen_green)";
  }

  return {
    containerColor,
    percentageColor,
    percentageValue: percentage,
    showPercentage: true,
  };
};

export const getComingUpDate = (
  status,
  upcomingDate = "",
  type,
  hasUncompletedWO,
  isNextDate = false
) => {
  if (type === "one_time" && status === "planned" && hasUncompletedWO) return;

  if (["paused", "completed"].includes(status)) {
    return;
  }

  const timeDifference = new Date(upcomingDate) - new Date();

  // TODO: Temporary decision according to {https://clickmaint.atlassian.net/browse/CMFB-1483}
  if (timeDifference < 0) return;

  if (isNextDate) {
    return new Date(upcomingDate);
  }

  const millisecondsInMinute = 60 * 1000;
  const millisecondsInHour = 60 * millisecondsInMinute;
  const millisecondsInDay = 24 * millisecondsInHour;
  const millisecondsInWeek = 7 * millisecondsInDay;
  const millisecondsInMonth = 30 * millisecondsInDay;

  const months = Math.floor(timeDifference / millisecondsInMonth);
  const weeks = Math.floor(
    (timeDifference % millisecondsInMonth) / millisecondsInWeek
  );
  const days = Math.floor(
    (timeDifference % millisecondsInWeek) / millisecondsInDay
  );
  const hours = Math.floor(
    (timeDifference % millisecondsInDay) / millisecondsInHour
  );
  const minutes = Math.floor(
    (timeDifference % millisecondsInHour) / millisecondsInMinute
  );

  if (months >= 2) {
    return `${months}mos+`;
  } else if (months === 1) {
    return `1mo ${weeks}w`;
  } else if (weeks >= 1) {
    return `${weeks}w ${days}d ${hours}h`;
  } else {
    return `${days}d ${hours}h ${minutes}m`;
  }
};

const filterTimeToCompleteOptions = (
  options,
  scheduleType,
  repeatFrequency,
  repeatOccurrence,
  onWeekDays,
  inMonths
) => {
  let multiplier = 1;
  let repeats = repeatOccurrence;

  if (repeatFrequency === "day") {
    multiplier = 24;
  } else if (repeatFrequency === "week") {
    if (
      scheduleType === "floating" ||
      !onWeekDays?.length ||
      onWeekDays.length === 1
    ) {
      multiplier = 24 * 7;
    } else {
      const sortedDays = onWeekDays
        .map((v) => (v === "7" ? "0" : v))
        .sort((a, b) => a - b);
      const minGap = sortedDays.reduce((acc, curr, i, arr) => {
        if (i === arr.length - 1) return acc;
        const gap = arr[i + 1] - curr;
        return gap < acc ? gap : acc;
      }, 6);
      multiplier = minGap * 24;
      repeats = 1;
    }
  } else if (repeatFrequency === "month") {
    if (scheduleType === "floating" || repeatOccurrence !== "custom") {
      multiplier = 24 * 7 * 5;
    } else if (repeatOccurrence === "custom" && inMonths.length === 1) {
      multiplier = 24 * 7 * 5;
      repeats = 1;
    } else {
      const sortedMonths = inMonths.slice().sort((a, b) => a - b);
      const minGap = sortedMonths.reduce((acc, curr, i, arr) => {
        if (i === arr.length - 1) return acc;
        const gap = arr[i + 1] - curr;
        return gap < acc ? gap : acc;
      }, 13);
      multiplier = minGap * 24 * 7 * 5;
      repeats = 1;
    }
  }

  return options.filter((value) => value.key <= repeats * multiplier);
};

export const getFilterTimeToCompleteOptions = (
  scheduleType,
  repeatFrequency,
  repeatOccurrence,
  onWeekDays,
  inMonths,
  time_to_complete
) => {
  const FilterDaysOptions = [
    {
      key: 1080,
      value: t("preventive_maintenances.time_to_complete.days", {
        value: 45,
      }),
    },
    {
      key: 1440,
      value: t("preventive_maintenances.time_to_complete.days", {
        value: 60,
      }),
    },
    {
      key: 2160,
      value: t("preventive_maintenances.time_to_complete.days", {
        value: 90,
      }),
    },
    {
      key: 2880,
      value: t("preventive_maintenances.time_to_complete.days", {
        value: 120,
      }),
    },
    {
      key: 3600,
      value: t("preventive_maintenances.time_to_complete.days", {
        value: 150,
      }),
    },
    {
      key: 4320,
      value: t("preventive_maintenances.time_to_complete.days", {
        value: 180,
      }),
    },
  ];
  const FilterTimesOptions = [
    {
      key: 1,
      value: t("preventive_maintenances.time_to_complete.hour", {
        value: 1,
      }),
    },
    {
      key: 2,
      value: t("preventive_maintenances.time_to_complete.hours", {
        value: 2,
      }),
    },
    {
      key: 4,
      value: t("preventive_maintenances.time_to_complete.hours", {
        value: 4,
      }),
    },
    {
      key: 8,
      value: t("preventive_maintenances.time_to_complete.hours", {
        value: 8,
      }),
    },
    {
      key: 12,
      value: t("preventive_maintenances.time_to_complete.hours", {
        value: 12,
      }),
    },
    {
      key: 24,
      value: t("preventive_maintenances.time_to_complete.hours", {
        value: 24,
      }),
    },
  ];
  for (let days = 2; days <= 30; days++) {
    FilterTimesOptions.push({
      key: days * 24,
      value: t("preventive_maintenances.time_to_complete.days", {
        value: days,
      }),
    });
  }
  let options = [...FilterTimesOptions, ...FilterDaysOptions];

  const defaultOption = options.find((opt) => time_to_complete === opt.key);

  if (scheduleType !== "one_time" && repeatFrequency !== "year") {
    options = filterTimeToCompleteOptions(
      options,
      scheduleType,
      repeatFrequency,
      repeatOccurrence,
      onWeekDays,
      inMonths
    );
  }

  const existDefaultOption = options.some(
    (obj) => obj?.key === defaultOption?.key
  );

  return defaultOption && !existDefaultOption
    ? [defaultOption, ...options]
    : options;
};

export const getFilterScheduleOptions = () => {
  const FilterScheduleOptions = [];
  const types = {
    persistent: "Persistent",
    floating: "Floating",
    "one-time": "One-time",
  };
  const intervals = {
    hour: "Hourly",
    day: "Daily",
    week: "Weekly",
    month: "Monthly",
    year: "Yearly",
  };

  Object.keys(types).forEach((key) => {
    if (key === "one-time") {
      FilterScheduleOptions.push({ key: "one-time", value: "One-time" });
    } else {
      Object.keys(intervals).forEach((intervalKey) => {
        const intervalValue = intervals[intervalKey];
        FilterScheduleOptions.push({
          key: `${key}_${intervalKey}`,
          value: `${types[key]}: ${intervalValue}`,
        });
      });
    }
  });

  return FilterScheduleOptions;
};

export const convertTimeToCompleteHoursToDays = (hours) => {
  if (hours === 1) {
    return `${hours} hour`;
  } else if (hours <= 24) {
    return `${hours} hours`;
  } else {
    return `${Math.floor(hours / 24)} days`;
  }
};

export const downloadFile = async (doc) => {
  const asPdf = pdf([]);
  asPdf.updateContainer(doc);
  const pdfBlob = await asPdf.toBlob();
  const url = URL.createObjectURL(pdfBlob);
  const link = document.createElement("a");
  link.href = url;
  link.download = "preventive_maintenance.pdf";
  link.click();
};
