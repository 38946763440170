import { User } from "modules/users/types/User";
import { ShiftBy } from "shared/components/ShiftBy";
import { UserAvatar } from "shared/components/UserAvatar";
import { HeaderProfile } from "./WorkOrderHistoryList.styled";
import { Image } from "./WorkOrderHistoryList.styled";
import SystemAvatar from "../../../assets/icons/systemAvatar.svg?react";

export const ProfileIcon = ({ modifier }: { modifier: User | void }) => (
  <HeaderProfile>
    {modifier ? (
      <ShiftBy y={4}>
        <UserAvatar user={modifier} size="md" />
      </ShiftBy>
    ) : (
      <Image>
        <SystemAvatar />
      </Image>
    )}
  </HeaderProfile>
);
