import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import Link from "shared/assets/icon/link.svg?react";
import TrashCanIcon from "shared/assets/icon/trashcan.svg?react";
import { useTranslation } from "react-i18next";

export const Hyperlink = ({
  value = {},
  errors,
  onChange,
  onBlur,
  onDelete,
  index,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <InputBox>
        <InputField
          placeholder={t("hyperlink_manager.placeholders.text")}
          value={value.label}
          onChange={(e) => onChange("label", index, e.target.value)}
          onBlur={onBlur}
        />
        {errors?.label && <Error>{errors.label}</Error>}
        <InputContainer>
          <IconContainer>
            <Link />
          </IconContainer>
          <InputField
            placeholder={t("hyperlink_manager.placeholders.url")}
            value={value.url}
            onChange={(e) => onChange("url", index, e.target.value)}
            onBlur={onBlur}
          />
          {errors?.url && <Error>{errors.url}</Error>}
        </InputContainer>
      </InputBox>
      <TrashCanIconBox>
        <TrashCanIconStyled onClick={() => onDelete(index)} />
      </TrashCanIconBox>
    </Container>
  );
};

Hyperlink.propTypes = {
  value: PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
  }),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  errors: PropTypes.array,
  setValue: PropTypes.func,
};

const IconContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 12px;
  left: 12px;
`;

const InputContainer = styled.div`
  position: relative;
  margin-top: 12px;
`;

const InputStyle = css`
  font-family: inherit;
  box-sizing: border-box;
  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : "40px")};
  padding-left: 30px;
  outline: transparent;
  font-weight: 400;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "14px")};
  border-radius: ${({ currency }) => (currency ? "0 5px 5px 0" : "5px")};
  border-width: 1px;
  border-style: solid;
  border-color: var(--color_grey500);

  &::placeholder {
    color: var(--color_grey500);
  }

  &:placeholder-shown {
    border-color: var(--color_grey200);
  }

  &:focus {
    box-shadow: 0px 0px 0px 1px var(--color_background_light),
      0px 0px 0px 3px var(--color_primary);

    &,
    &:placeholder-shown {
      border-color: var(--color_grey900);
    }
  }

  ${(props) =>
    props.isInvalid &&
    css`
      &,
      &:placeholder-shown {
        border-width: 2px;
        border-color: var(--color_alert);
      }
    `}
`;

const InputField = styled.input`
  ${InputStyle}
`;

const Container = styled.div`
  background: #ececef;
  padding: 12px;
  border-radius: 4px;
  display: flex;
`;

const InputBox = styled.div`
  width: 100%;
`;
const TrashCanIconBox = styled.div`
  padding: 10px;
  cursor: pointer;
`;

const Error = styled.div`
  color: var(--color_alert);
  font-size: 14px;
  margin-top: 6px;
`;

const TrashCanIconStyled = styled(TrashCanIcon)`
  color: var(--color_primary_text);
`;
