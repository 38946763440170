import { useGetKPIs } from "modules/reporting/lib/useGetKPIs";
import { useTranslation } from "react-i18next";
import { ReportKey } from "modules/reporting/types/ReportKey";
import {
  formatCurrency,
  formatNumber,
  truncateString,
} from "modules/reporting/lib/formatters";
import { PartsInventoryKPIEntry, PartsInventoryKPIs } from "./types";
import { ReportPeriod } from "modules/reporting/types/ReportPeriod";
import { useLocaleSettings } from "../../../lib/useLocaleSettings";
import { cloneDeep } from "lodash";

const reportKey = ReportKey.PartsInventory;
const MAX_DISPLAYED_KPIS = 8;
export const usePartsInventoryKPIs = (reportPeriod?: ReportPeriod) => {
  const { data, ...rest } = useGetKPIs<PartsInventoryKPIs>(
    reportKey,
    reportPeriod,
    "parts"
  );
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const { currency } = useLocaleSettings();

  if (!data) {
    return {
      ...rest,
      total_costs: "0",
      top_part_counts: [],
      top_part_type_counts: [],
      top_parts_costs: [],
      top_part_types_costs: [],
    };
  }

  const createCurrencyEntry = (item: PartsInventoryKPIEntry) => {
    const label = t(`reporting.${reportKey}.labels.spent`, {
      value: item.label,
    });
    return {
      label: truncateString(label),
      value: formatCurrency(parseFloat(item.value), language, currency),
    };
  };

  const createCountEntry = (item: PartsInventoryKPIEntry) => {
    const label = t(`reporting.${reportKey}.labels.count`, {
      value: item.label,
    });
    return {
      label: truncateString(label),
      value: formatNumber(parseFloat(item.value), language),
    };
  };
  const sortResults = (results: PartsInventoryKPIEntry[]) => {
    return results?.sort(
      (a: PartsInventoryKPIEntry, b: PartsInventoryKPIEntry) => {
        return parseFloat(b.value) - parseFloat(a.value);
      }
    );
  };

  const formatCurrencyResults = (results: PartsInventoryKPIEntry[]) => {
    if (!results) return [];
    return sortResults(results)
      .slice(0, MAX_DISPLAYED_KPIS)
      .map((item) => createCurrencyEntry(item));
  };
  const formatCountResults = (results: PartsInventoryKPIEntry[]) => {
    if (!results) return [];
    return sortResults(results)
      .slice(0, MAX_DISPLAYED_KPIS)
      .map((item) => createCountEntry(item));
  };

  const clone = cloneDeep(data);

  return {
    ...rest,
    total_costs: clone.total_costs,
    top_part_counts: formatCountResults(clone.top_parts_count),
    top_part_type_counts: formatCountResults(clone.top_part_types_count),
    top_parts_costs: formatCurrencyResults(clone.top_parts_cost),
    top_part_types_costs: formatCurrencyResults(clone.top_part_types_cost),
  };
};
