import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { t } from "i18next";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import { IconButton } from "shared/components/IconButton";
import { ConfirmationDialog } from "shared/components/Dialog";
import { Dropdown } from "shared/components/Dropdowns";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import Icon from "../../../assets/icons/priority.svg?react";

const priorityOptions = [
  {
    key: "none",
    value: t("work_orders.bulkActions.updatePriority.options.none"),
  },
  {
    key: "low",
    value: t("work_orders.bulkActions.updatePriority.options.low"),
  },
  {
    key: "medium",
    value: t("work_orders.bulkActions.updatePriority.options.medium"),
  },
  {
    key: "high",
    value: t("work_orders.bulkActions.updatePriority.options.high"),
  },
  {
    key: "critical",
    value: t("work_orders.bulkActions.updatePriority.options.critical"),
  },
];

export const BulkPriority = ({
  ids = [],
  onUpdate,
  count,
  module = "work_orders",
}) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();

  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [priority, updatePriority] = useState("");

  const priorityChangeHandler = (name, value) => {
    updatePriority(value);
  };

  const clickHandler = () => {
    setIsConfirmationVisible(true);
  };

  const cancelHandler = () => {
    setIsConfirmationVisible(false);
    updatePriority("");
  };

  const proceedHandler = () => {
    if (ids.length > 0) {
      onUpdate({ ids, priority })
        .unwrap()
        .then(() => {
          setIsConfirmationVisible(false);
          addSnackBar({
            content: t(`${module}.bulkActions.updatePriority.success`, {
              count,
              status: priority,
            }),
            type: "success",
          });
        })
        .catch(() => {
          addSnackBar({
            title: t("work_orders.bulkActions.updatePriority.fail"),
            type: "error",
          });
        });
    }
  };

  return (
    <>
      <IconButton onClick={clickHandler}>
        <StyledIcon data-tooltip-id="icon-tooltip-priority" />
        <ReactTooltip
          id="icon-tooltip-priority"
          place="bottom"
          content={t("work_orders.bulkActions.hint.priority")}
          opacity="1"
        />
      </IconButton>
      {isConfirmationVisible && (
        <ConfirmationDialog
          title={t("work_orders.bulkActions.updatePriority.confirmation.title")}
          confirmButtonLabel={t("save")}
          cancelButtonLabel={t("discardChanges")}
          description={
            <Description>
              <SelectedLabel>
                {t(
                  `${module}.bulkActions.updatePriority.confirmation.selected_${
                    count > 1 ? "other" : "one"
                  }`,
                  { count }
                )}
              </SelectedLabel>
              <FieldBox>
                <NewLabel>
                  {t(
                    "work_orders.bulkActions.updatePriority.confirmation.newPriority"
                  )}
                </NewLabel>
                <Dropdown
                  name="priority"
                  value={priority}
                  options={priorityOptions}
                  defaultOptions={[]}
                  setValue={priorityChangeHandler}
                  onSearch={() => {}}
                  placeholder={t("startTyping")}
                  withClear={false}
                  withSearch={false}
                />
              </FieldBox>
            </Description>
          }
          onProceed={proceedHandler}
          onCancel={cancelHandler}
        />
      )}
    </>
  );
};

BulkPriority.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number),
  onUpdate: PropTypes.func.isRequired,
  count: PropTypes.number,
  module: PropTypes.string,
};

const Description = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  padding-bottom: 32px;
  gap: 16px;
`;

const SelectedLabel = styled.span`
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: var(--color_grey900);
`;

const FieldBox = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`;

const NewLabel = styled.span`
  flex-shrink: 0;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: var(--color_grey900);
`;

const StyledIcon = styled(Icon)`
  outline: none;
`;
