import { t } from "i18next";
import {
  getInitialValuesForProcedureTemplateItem,
  ProcedureTemplateItemValidationSchema,
} from "modules/procedures/lib/form";
import { useCreateProcedureTemplateItemMutation } from "modules/procedures/state/proceduresApi";
import { IProcedureTemplate } from "modules/procedures/types";
import { EditorModal } from "shared/components/Modals";
import { useForm } from "shared/lib/hooks/useForm";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { Editor } from "./Editor";
import { useCaptureExceptionWithBreadcrumb } from "shared/lib/hooks";
import { button } from "@test-data";

interface TemplateItemCreateProps {
  isOpen: boolean;
  onClose: () => void;
  procedureTemplate: IProcedureTemplate;
}

export const TemplateItemCreateModal = ({
  isOpen,
  onClose,
  procedureTemplate,
}: TemplateItemCreateProps) => {
  const captureError = useCaptureExceptionWithBreadcrumb({
    showGenericErrorSnack: true,
  });
  const [createTemplateItem] = useCreateProcedureTemplateItemMutation();
  const { addSnackBar } = useSnackBar();

  const closeModal = () => {
    form.resetForm();
    onClose();
  };

  const form = useForm({
    initialValues: getInitialValuesForProcedureTemplateItem("create"),
    validationSchema: ProcedureTemplateItemValidationSchema(t),
    enableReinitialize: true,
    onSubmit: async (data) => {
      const body = {
        procedure_template_item: {
          procedure_template_id: procedureTemplate.id,
          ...data,
        },
      };

      try {
        await createTemplateItem(body).unwrap();

        addSnackBar({
          title: t("procedures.snackbars.item_created.success.title"),
          type: "success",
        });

        closeModal();
      } catch (error) {
        captureError(error, "Failed to create ProcedureTemplateItem", body);
      }
    },
  });

  return (
    <EditorModal
      isOpen={isOpen}
      isSubmitLoading={form.isSubmitting}
      onClose={closeModal}
      onSubmit={form.handleSubmit}
      label={t("procedures.items.create_title")}
      form={form}
      disabledSubmit={!form.isValid || !form.dirty}
      dataTestId={button.saveNewProcedureItem.id}
    >
      <Editor form={form} />
    </EditorModal>
  );
};
