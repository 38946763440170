import { useDisplayDate } from "shared/lib/hooks";

interface ViewProps {
  value: string | number | boolean | unknown[] | null | void;
  isDate?: boolean;
  wide?: boolean;
}

export const View = ({
  value = [],
  isDate = false,
  wide = false,
}: ViewProps) => {
  const displayDate = useDisplayDate();
  const width = wide ? "100%" : "fit-content";

  if (Array.isArray(value)) {
    return (
      <div style={{ width: width }}>
        {value.map((v, i) => {
          const displayValue = isDate ? displayDate(v, true) : v;
          return (
            <p key={i} style={{ margin: 0 }} title={v}>
              {displayValue}
            </p>
          );
        })}
      </div>
    );
  } else {
    const displayValue = isDate ? displayDate(value, true).toString() : value;

    return (
      <div style={{ width: width }}>
        <p style={{ margin: 0 }} title={value as string}>
          {displayValue}
        </p>
      </div>
    );
  }
};
