import {
  IFormattedChange,
  IHistory,
} from "modules/workOrders/lib/helpers/getHistoryContent";
import {
  Header,
  Wrapper,
  Name,
  Title,
  Content,
  ChecklistContainer,
  CreatedAt,
} from "./WorkOrderHistoryList.styled";
import { ProfileIcon } from "./ProfileIcon";
import CheckedCheckbox from "../../../assets/icons/checkedCheckbox.svg?react";
import EmptyCheckbox from "../../../assets/icons/emptyCheckbox.svg?react";
import { useDisplayDate } from "shared/lib/hooks";

interface ChecklistItemViewProps {
  change: IFormattedChange;
  history: IHistory;
}

export const ChecklistItemView = ({
  change,
  history,
}: ChecklistItemViewProps) => {
  const displayDate = useDisplayDate();
  const { modifier, modifier_name, created_at } = history;

  const name =
    modifier_name === "System" ? "Scheduled event" : modifier?.full_name;

  return (
    <Wrapper>
      <ProfileIcon modifier={modifier} />
      {change.key && (
        <Header>
          <Name>{name}</Name>
          <Title>{change?.key}</Title>
        </Header>
      )}
      <CreatedAt>{displayDate(created_at, true) as string}</CreatedAt>
      <Content>
        <ChecklistContainer
          checked={(change.to as unknown as string) === "true"}
        >
          {(change.to as unknown as string) === "true" ? (
            <CheckedCheckbox />
          ) : (
            <EmptyCheckbox />
          )}
          <div>{change.value as string}</div>
        </ChecklistContainer>
      </Content>
    </Wrapper>
  );
};
