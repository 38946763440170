import React, { useMemo } from "react";
import { FieldsAdminSettings } from "widgets/FieldsAdminSettings";
import {
  useGetAdminConfigurationQuery,
  getConfigurationFields,
} from "modules/adminFieldsConfiguration";
import { usePermission } from "app/providers/PermissionsProvider";
import { useFlags } from "launchdarkly-react-client-sdk";

const WorkOrderAdminConfigurationFields = [
  "description",
  "priority",
  "categories",
  "assign_to",
  "due_date",
  "location",
  "asset",
  "vendors",
  "checklist",
  "in_review",
  "procedures",
];

export const WorkOrdersAdminSettings = () => {
  const { adminSettingsPartPermit } = usePermission();
  const { data } = useGetAdminConfigurationQuery({ type: "work_order" });
  const { data: statusData } = useGetAdminConfigurationQuery({
    type: "work_order_status",
  });
  const { enableProcedures } = useFlags();

  const fields = useMemo(
    () =>
      enableProcedures
        ? WorkOrderAdminConfigurationFields
        : WorkOrderAdminConfigurationFields.filter(
            (field) => field !== "procedures"
          ),
    [enableProcedures]
  );

  if (!data) return null;

  const workOrderData = { ...data?.data.payload, ...statusData?.data.payload };
  const currentConfiguration = getConfigurationFields(fields, workOrderData);

  return (
    <FieldsAdminSettings
      initialConfiguration={currentConfiguration}
      type="work_order"
      additionalType="work_order_status"
      additionalField="in_review"
      hasPermission={adminSettingsPartPermit}
    />
  );
};
