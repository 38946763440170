import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Table } from "shared/components/Table";
import {
  usePageParam,
  useQuickFilterParam,
  useSortParam,
} from "shared/lib/hooks";
import { useGetLocationsQuery } from "modules/locations/state/locationsApi";
import { useLocationsTableTools } from "modules/locations/lib/useLocationsTableTools";
import { BulkActions } from "../BulkActions";
import { TopBar } from "widgets/TopBar";
import { FilterParentParam } from "modules/filters/configs/constants";
import { RowDecoratorImage } from "shared/components/Table/components/RowDecoratorImage";
import { useSearchParam } from "shared/lib/hooks";
import { NoResults } from "shared/components/NoResults";
import { Pagination } from "features/Pagination/index.jsx";
import { useIsLoading } from "shared/lib/hooks/useIsLoading";
import { useTranslation } from "react-i18next";

export const LocationsList = ({ onOpenLocation, emptyListNode }) => {
  const [selected, setSelected] = useState([]);
  const [quickFilter, setQuickFilter] = useQuickFilterParam();
  const [search] = useSearchParam();
  const [page] = usePageParam(1);
  const [sort] = useSortParam();
  const { t } = useTranslation();

  const onFilterByParentLocations = ({ id, name }) => {
    setQuickFilter({
      value: id,
      name,
      type: "location",
    });
  };

  const removeQuickFilter = () => {
    setQuickFilter(null);
  };

  const { columns, tableSettings, applySettings, areTableSettingsFetching } =
    useLocationsTableTools({
      onOpenLocation,
      onFilterByParentLocations,
      quickFilter,
    });

  const { data, isFetching } = useGetLocationsQuery({
    page,
    sort,
    search,
    per_page: tableSettings?.per_page,
    [FilterParentParam]: quickFilter?.value,
  });
  const isLoading = useIsLoading(isFetching, [search, page, sort]);

  useEffect(() => {
    if (data) {
      setSelected([]);
    }
  }, [data]);

  const noData = data?.data?.length === 0;

  if (noData && search?.length > 0) {
    return <NoResults query={search} /> ?? null;
  } else if (noData && !isLoading) {
    return emptyListNode ?? null;
  }

  return (
    <>
      <TopBar
        entity="locations"
        tableSettings={tableSettings}
        setTableSettings={applySettings}
        columns={columns}
        withExport={false}
        withFilter={false}
        quickFilter={quickFilter}
        removeQuickFilter={removeQuickFilter}
      />
      <Table
        columns={columns}
        rows={data?.data}
        highlight={search}
        showSkeleton={isLoading || areTableSettingsFetching}
        selectionModel={selected}
        onSelectionModelChange={setSelected}
        entityTranslationKey="locations.title"
        bulkActionsNode={<BulkActions selectionModel={selected} />}
        getRowIcon={(row) => <RowDecoratorImage src={row.image?.small} />}
      />
      <Pagination
        paginationData={data?.meta.pagination}
        label={t("locations.title_other")}
      />
    </>
  );
};

LocationsList.propTypes = {
  onOpenLocation: PropTypes.func,
  onPaginationChange: PropTypes.func,
  emptyListNode: PropTypes.element,
  nothingFoundNode: PropTypes.element,
};
