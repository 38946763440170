import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Urls, Name } from "shared/components/Table";
import { useDisplayDate } from "shared/lib/hooks";
import { useTableSettings } from "modules/fieldConfiguration/lib/useTableSettings";
import { StockLevel } from "../../components/StockLevel";
import { formatCurrency } from "shared/lib/helpers/common";
import { ModalLinksList } from "shared/components/ModalLinksList";
import { measurementUnits } from "modules/parts/configs/constant";

export const usePartsTableTools = ({ onOpen }) => {
  const { t } = useTranslation();
  const displayDate = useDisplayDate();

  const {
    result: { data: tableSettings, isFetching: areTableSettingsFetching },
    applySettings,
  } = useTableSettings("part");

  const columns = useMemo(() => {
    const settings = tableSettings?.data.payload;

    return [
      {
        field: "name",
        name: t("parts.columns.name"),
        width: 400,
        sortable: true,
        renderCell: (row, value) => (
          <Name onClick={() => onOpen(row.id)} value={value} />
        ),
        primary: true,
        order: 0,
      },
      {
        field: "stock_level",
        name: t("parts.columns.stockLevel"),
        width: 200,
        sortable: false,
        renderCell: ({ min_qty, desired_qty, on_hand_qty, is_non_stock }) => (
          <StockLevel
            min={min_qty}
            desired={desired_qty}
            onHand={on_hand_qty}
            nonStock={is_non_stock}
            width="84px"
          />
        ),
        filter: false,
        order: settings?.stock_level?.order,
        hidden: !settings?.stock_level?.visible,
      },
      {
        field: "part_type",
        name: t("parts.columns.partType"),
        sortQueryParam: "part_type_name",
        width: 200,
        sortable: true,
        formatValue: (value) => value?.name,
        order: settings?.part_type.order,
        hidden: !settings?.part_type.visible,
      },
      {
        field: "number",
        name: t("parts.columns.number"),
        width: 200,
        sortable: true,
        order: settings?.number.order,
        hidden: !settings?.number.visible,
      },
      {
        field: "on_hand_qty",
        name: t("parts.columns.onHand"),
        width: 200,
        type: "number",
        sortable: true,
        formatValue: (value) => (value && value !== 0 ? value : "-"),
        order: settings?.on_hand_qty.order,
        hidden: !settings?.on_hand_qty.visible,
      },
      {
        field: "available_qty",
        name: t("parts.columns.available"),
        width: 200,
        type: "number",
        sortable: true,
        renderCell: ({ available }) =>
          available && available !== 0 ? available : "-",
        order: settings?.available_qty?.order,
        hidden: !settings?.available_qty?.visible,
      },
      {
        field: "min_qty",
        name: t("parts.columns.minimum"),
        width: 200,
        type: "number",
        sortable: true,
        formatValue: (value) => (value && value !== 0 ? value : "-"),
        order: settings?.min_qty.order,
        hidden: !settings?.min_qty.visible,
      },
      {
        field: "desired_qty",
        name: t("parts.columns.desired"),
        width: 200,
        type: "number",
        sortable: true,
        formatValue: (value) => (value && value !== 0 ? value : "-"),
        order: settings?.desired_qty.order,
        hidden: !settings?.desired_qty.visible,
      },
      {
        field: "unit_cost",
        name: t("parts.columns.unitCost"),
        width: 200,
        sortable: true,
        type: "number",
        formatValue: (value) => (value == 0 ? "-" : formatCurrency(value)),
        order: settings?.unit_cost.order,
        hidden: !settings?.unit_cost.visible,
      },
      {
        field: "measurement_unit",
        name: t("parts.columns.unitMeasurement"),
        width: 200,
        sortable: true,
        formatValue: (value) => {
          const unitName = value?.id ? measurementUnits[value.id].name : "";
          return unitName;
        },
        order: settings?.measurement_unit.order,
        hidden: !settings?.measurement_unit.visible,
      },
      {
        field: "area",
        name: t("parts.columns.area"),
        width: 200,
        sortable: true,
        order: settings?.area.order,
        hidden: !settings?.area.visible,
      },
      {
        field: "vendors",
        name: t("parts.columns.vendors"),
        width: 200,
        sortable: true,
        formatValue: (vendors) => (
          <ModalLinksList
            type="vendor"
            items={vendors}
            layout="inline"
            separator=", "
          />
        ),
        order: settings?.vendors.order,
        hidden: !settings?.vendors.visible,
      },
      {
        field: "assets",
        name: t("parts.columns.assets"),
        width: 200,
        sortable: true,
        formatValue: (assets) => (
          <ModalLinksList
            type="asset"
            items={assets}
            layout="inline"
            separator=", "
          />
        ),
        order: settings?.assets.order,
        hidden: !settings?.assets.visible,
      },
      {
        field: "id",
        name: t("parts.columns.id"),
        width: 120,
        sortable: true,
        formatValue: (value) => `#${value}`,
        order: settings?.id.order,
        hidden: !settings?.id.visible,
      },
      {
        field: "description",
        name: t("parts.columns.description"),
        width: 200,
        sortable: true,
        order: settings?.description.order,
        hidden: !settings?.description.visible,
      },
      {
        field: "storeroom",
        name: t("parts.columns.storeroom"),
        width: 200,
        sortQueryParam: "storeroom_name",
        sortable: true,
        formatValue: (value) => value?.name,
        order: settings?.storeroom.order,
        hidden: !settings?.storeroom.visible,
      },
      {
        field: "lead_time",
        name: t("parts.columns.leadTime"),
        width: 200,
        sortable: true,
        order: settings?.lead_time.order,
        hidden: !settings?.lead_time.visible,
      },
      {
        field: "qr_code",
        name: t("parts.columns.code"),
        width: 200,
        sortable: true,
        order: settings?.qr_code?.order,
        hidden: !settings?.qr_code?.visible,
      },
      {
        field: "urls",
        name: t("parts.columns.urls"),
        sortQueryParam: "urls_label",
        width: 300,
        sortable: true,
        renderCell: ({ urls }) => <Urls values={urls} />,
        filter: false,
        order: settings?.urls.order,
        hidden: !settings?.urls.visible,
      },
      {
        field: "created_at",
        name: t("parts.columns.createdAt"),
        width: 200,
        sortable: true,
        formatValue: (value) => displayDate(value, true),
        order: settings?.created_at.order,
        hidden: !settings?.created_at.visible,
      },
      {
        field: "updated_at",
        name: t("parts.columns.updatedAt"),
        width: 200,
        sortable: true,
        formatValue: (value) => displayDate(value, true),
        order: settings?.updated_at.order,
        hidden: !settings?.updated_at.visible,
      },
      {
        field: "created_by_name",
        name: t("parts.columns.createdBy"),
        width: 150,
        sortable: true,
        order: settings?.created_by_name?.order,
        formatValue: (value) =>
          value && `${value.name || ""} ${value.surname || ""}`,
        hidden: !settings?.created_by_name?.visible,
      },
      {
        field: "updated_by_name",
        name: t("parts.columns.updatedBy"),
        width: 150,
        sortable: true,
        order: settings?.updated_by_name?.order,
        formatValue: (value) =>
          value && `${value.name || ""} ${value.surname || ""}`,
        hidden: !settings?.updated_by_name?.visible,
      },
    ]
      .filter(({ field }) => settings?.[field]?.visible_global !== false)
      .sort((a, b) => {
        return a.order - b.order;
      });
  }, [t, tableSettings?.data.payload, displayDate, onOpen]);

  return {
    columns,
    tableSettings: areTableSettingsFetching ? undefined : tableSettings?.data,
    areTableSettingsFetching,
    applySettings,
  };
};
