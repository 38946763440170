import * as Yup from "yup";
import { TFunction } from "i18next";
import { getDescriptionSchema } from "shared/lib/helpers/validation";
import { IProcedure } from "../types";

/***************/
/** TEMPLATES **/
/***************/

interface IProcedureTemplateCreate {
  name: string;
  description?: string;
}

const ProcedureTemplateCreateInitialValues: IProcedureTemplateCreate = {
  name: "",
  description: "",
};

export const getInitialValuesForProcedureTemplate = (
  type: string,
  defaultValues?: IProcedureTemplateCreate
) => {
  switch (type) {
    case "create":
      return { ...ProcedureTemplateCreateInitialValues, ...defaultValues };

    default:
      throw new Error(`Unsupported type: ${type}`);
  }
};

export const ProcedureTemplateValidationSchema = (t: TFunction) => {
  const DefaultSchema = {
    name: Yup.string().required(t("validation.required") as string),
    description: getDescriptionSchema(t),
  };

  return Yup.object(DefaultSchema);
};

/********************/
/** TEMPLATE ITEMS **/
/********************/
export const getInitialValuesForProcedureTemplateItem = (type: string) => {
  switch (type) {
    case "create":
      return {
        item_type: "",
        name: "",
        options: {},
      };
    default:
      throw new Error(`Unsupported type: ${type}`);
  }
};

export const ProcedureTemplateItemValidationSchema = (t: TFunction) => {
  const DefaultSchema = {
    item_type: Yup.string().required(t("validation.required") as string), // TODO: this should be an enum of some kind
    name: Yup.string().required(t("validation.required") as string),
    options: Yup.object().when("item_type", {
      is: "multiple_choice",
      then: (schema) =>
        schema.shape({
          choices: Yup.array().of(
            Yup.string().required(t("validation.required") as string)
          ),
        }),
    }),
  };

  return Yup.object(DefaultSchema);
};

/****************/
/** PROCEDURES **/
/****************/

export const BuildProcedureForm = (t: TFunction, procedure: IProcedure) => {
  let schema: { [key: string]: Yup.Schema } = {};

  procedure?.items?.forEach((item) => {
    switch (item.item_type) {
      case "text":
        schema[item.name] = Yup.string().required(
          t("validation.required") as string
        );
        break;

      case "number":
        schema[item.name] = Yup.string().required(
          t("validation.required") as string
        );
        break;

      case "date":
        schema[item.name] = Yup.date().required(
          t("validation.required") as string
        );
        break;

      case "checkbox":
        schema[item.name] = Yup.boolean()
          .oneOf([true])
          .required(t("validation.required") as string);
        break;

      case "yes_no_na":
        schema[item.name] = Yup.string()
          .required(t("validation.required") as string)
          .matches(/^(yes|no|n\/a)$/i);
        break;

      case "mutliple_choice":
        schema[item.name] = Yup.string().required(
          t("validation.required") as string
        );
        break;

      default:
        // TODO: raise exception
        break;
    }
  });
  return Yup.object(schema);
};

export const BuildProcedureInitialValues = (procedure: IProcedure) => {
  let defaultValues: { [key: string]: unknown } = {};

  procedure?.items?.forEach((item) => {
    switch (item.item_type) {
      case "checkbox":
        defaultValues[item?.name] = item?.value?.checked;
        break;
      case "date":
        defaultValues[item?.name] = item?.value?.date;
        break;
      case "multiple_choice":
        defaultValues[item?.name] = item?.value?.selected;
        break;
      default:
        defaultValues[item?.name] = item?.value?.data;
        break;
    }
  });

  return defaultValues;
};
