import { ProcedureTemplateValidationSchema } from "modules/procedures/lib/form";
import {
  useGetProcedureTemplateQuery,
  useUpdateProcedureTemplateMutation,
} from "modules/procedures/state/proceduresApi";
import { IProcedureTemplate } from "modules/procedures/types";
import { Divider } from "shared/components/Divider";
import { EditorModal } from "shared/components/Modals";
import { Hint } from "shared/components/Typography";
import { useForm } from "shared/lib/hooks/useForm";
import styled from "styled-components";
import { Editor } from "./Editor/Editor";
import { ItemEditor } from "./Editor/ItemEditor";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { useTranslation } from "react-i18next";
import { button } from "@test-data";
import { OptionType } from "shared/components/Select/SelectCreatable";
import { useCaptureExceptionWithBreadcrumb } from "shared/lib/hooks";

interface ProcedureEditProps {
  isOpen?: boolean;
  isShow?: boolean; // for backwards compatibility with SelectCreatable
  onClose?: () => void;
  procedureTemplateId?: string | number;
  onCreate?: (option: OptionType) => void;
}

export const ProcedureTemplateEdit = ({
  isOpen,
  isShow,
  onClose,
  procedureTemplateId,
  onCreate,
}: ProcedureEditProps) => {
  const {
    data,
    refetch,
    isFetching: isFetchingProcTemplate,
  } = useGetProcedureTemplateQuery(procedureTemplateId);

  const logError = useCaptureExceptionWithBreadcrumb({
    showGenericErrorSnack: true,
  });

  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();
  const procedureTemplate = data as IProcedureTemplate;
  const [updateProcedureTemplate] = useUpdateProcedureTemplateMutation();

  const templateForm = useForm({
    initialValues: {
      name: procedureTemplate?.name || "",
      description: procedureTemplate?.description || "",
    },
    validationSchema: ProcedureTemplateValidationSchema(t),
    enableReinitialize: true,
    onSubmit: async (body: object) => {
      const payload = {
        id: procedureTemplateId,
        procedure_template: body,
      };

      try {
        await updateProcedureTemplate(payload).unwrap();

        addSnackBar({
          title: t("procedures.snackbars.template_updated.success.title"),
          type: "success",
        });

        if (onCreate) {
          onCreate({
            label: procedureTemplate.name,
            value: procedureTemplate.id,
          });
        }

        if (onClose) {
          onClose();
        }
      } catch (error) {
        logError(
          error,
          t("procedures.snackbars.template_updated.error.title"),
          payload
        );
      }
    },
  });

  return (
    <EditorModal
      isOpen={isOpen || isShow}
      onClose={onClose}
      form={templateForm}
      onSubmit={templateForm.handleSubmit}
      label={t("procedures.edit", {
        label: procedureTemplate?.name,
      })}
      secondaryButtonLabel={t("discardChanges")}
      disabledSubmit={!templateForm.isValid}
      isSubmitLoading={templateForm.isSubmitting}
      dataTestId={button.saveEditProcedure.id}
    >
      <EditorContainer>
        <Editor form={templateForm} />
        <Divider />
        <Hint>{t("procedures.items.drag_hint")}</Hint>
        <ItemEditor
          procedureTemplate={procedureTemplate}
          onUpdate={refetch}
          disabled={isFetchingProcTemplate}
        />
      </EditorContainer>
    </EditorModal>
  );
};

const EditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
