import { Fragment, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { FileManager } from "modules/fileManager";
import { useGetEntitiesQuery } from "modules/entities";

import { InlineLabel } from "shared/components/widgets";
import { Input } from "shared/components/Input";
import { Select } from "shared/components/Select";
import { useSearch } from "shared/lib/hooks/useSearch";
import { getOptions } from "shared/lib/helpers";
import { Switch } from "shared/components/Switch";
import { Heading } from "react-aria-components";
import { Tooltip } from "react-tooltip";
import InformationIcon from "shared/assets/icon/information.svg?react";

export const RequestPortalEditor = ({ form }) => {
  const { t } = useTranslation();

  const [entities = [], onEntitiesSearch] = useSearch(
    useGetEntitiesQuery,
    true
  );

  const entitiesOptions = getOptions(entities);

  useEffect(() => {
    if (form.values.allow_anonymous) {
      const requiredFields = { ...form.values.required_fields, email: true };

      form.handleSetValue("required_fields", requiredFields);
    }
  }, [form.values.allow_anonymous]); // eslint-disable-line react-hooks/exhaustive-deps -- Adding form to deps array causes infinite re-render loop and we only care about changes to allow_anonymous

  return (
    <Container>
      <WrapperFileManager
        name="uploads_attributes"
        value={form.values.uploads_attributes}
        setValue={form.handleSetValue}
      />
      <Input
        name="title"
        value={form.values.title}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        placeholder={t("request_portals.form.title")}
        errorMessage={form.touched.title && form.errors.title}
        height={50}
        fontSize={20}
        required
        aria-label={t("ariaLabel.input.title")}
      />
      <InlineLabel
        label={t("request_portals.form.description")}
        labelWidth={110}
        fontSize="14px"
      >
        <Input
          name="description"
          value={form.values.description}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder={t("request_portals.form.description")}
          errorMessage={form.touched.description && form.errors.description}
          isTextArea
          aria-label={t("ariaLabel.input.description")}
        />
      </InlineLabel>
      <InlineLabel
        label={t("request_portals.form.welcome_text")}
        labelWidth={110}
        fontSize="14px"
      >
        <Input
          name="welcome_text"
          value={form.values.welcome_text}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder={t("request_portals.form.welcome_text")}
          errorMessage={form.touched.welcome_text && form.errors.welcome_text}
          height="72"
          isTextArea
          aria-label={t("ariaLabel.input.welcomeText")}
        />
      </InlineLabel>
      <InlineLabel
        label={t("request_portals.form.instructions")}
        labelWidth={110}
        fontSize="14px"
      >
        <Input
          name="instructions"
          value={form.values.instructions}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder={t("request_portals.form.instructions")}
          errorMessage={form.touched.instructions && form.errors.instructions}
          isTextArea
          aria-label={t("ariaLabel.input.instructions")}
        />
      </InlineLabel>
      <InlineLabel
        label={t("request_portals.form.entity")}
        labelWidth={110}
        fontSize="14px"
      >
        <Select
          options={entitiesOptions}
          name="entity"
          value={form.values.entity}
          setValue={form.handleSetValue}
          onBlur={form.handleBlur}
          errorMessage={form.touched.entity && form.errors.entity}
          onSearch={onEntitiesSearch}
          onTouch={form.handleSetTouched}
          ariaLabel={t("ariaLabel.input.entity")}
        />
      </InlineLabel>
      <InlineLabel
        label={t("request_portals.form.allowAnonymous")}
        labelWidth={110}
        fontSize="14px"
      >
        <Switch
          activated={form.values.allow_anonymous}
          onClick={() =>
            form.handleSetValue("allow_anonymous", !form.values.allow_anonymous)
          }
          ariaLabel={t("ariaLabel.switch.allowAnonymous")}
        />
      </InlineLabel>
      {form?.values?.required_fields && (
        <>
          <Heading style={{ marginBottom: "4px" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              {t("request_portals.form.required_fields.title")}
              <InformationIcon data-tooltip-id="icon-tooltip-required-fields" />
              <Tooltip
                id="icon-tooltip-required-fields"
                content={t("request_portals.form.required_fields.hint")}
                style={{
                  fontSize: "14px",
                  maxWidth: "200px",
                  fontWeight: "normal",
                  opacity: form.values.allow_anonymous ? 1 : 0,
                }}
              />
            </div>
          </Heading>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
            {Object.entries(form.values.required_fields).map(([key, value]) => (
              <Fragment key={key}>
                <InlineLabel
                  label={t(`request_portals.form.required_fields.${key}`)}
                  labelWidth={110}
                  fontSize="14px"
                >
                  <Switch
                    activated={value}
                    onClick={() =>
                      form.handleSetValue(`required_fields.${key}`, !value)
                    }
                    ariaLabel={t(`ariaLabel.switch.${key}`)}
                    disabled={key === "email" && form.values.allow_anonymous}
                  />
                </InlineLabel>
              </Fragment>
            ))}
          </div>
        </>
      )}
    </Container>
  );
};

const WrapperFileManager = styled(FileManager)`
  margin-bottom: 30px;
`;

const Container = styled.div`
  color: var(--color_grey900);

  & input,
  textarea {
    color: var(--color_grey900);
  }
`;
