import { useTranslation } from "react-i18next";
import { getUser } from "shared/components/Auth";
import { useGetWorkOrderStatusesQuery } from "modules/workOrders";
import { useGetUserQuery } from "modules/users";
import { addDays, startOfDay } from "date-fns";

export const useToggleFilterConfigs = (
  entity,
  hasAssignToField,
  hasDueDateField
) => {
  const { t } = useTranslation();
  const userId = getUser()?.id;

  const { data: userData } = useGetUserQuery(userId, { skip: !userId });
  const { data: statusData } = useGetWorkOrderStatusesQuery();

  const configs = [];

  if (hasAssignToField) {
    if (userData?.can_be_assigned_to_wo) {
      configs.push({
        name: t("filters.toggles.currentUser"),
        id: "currentUser",
        filters: [
          {
            field: "assign_to",
            operator: "_in",
            value: [userId],
          },
        ],
      });
    }

    configs.push({
      name: t("filters.toggles.notAssigned"),
      filters: [
        {
          field: "assign_to",
          operator: "_null",
          value: true,
        },
      ],
    });
  }

  if (entity === "work_orders") {
    const completedId = statusData?.data?.find(
      (status) => status.name === "completed"
    )?.id;
    if (hasDueDateField && typeof completedId === "number") {
      configs.push({
        name: t("filters.toggles.overdue"),
        filters: [
          {
            field: "due_date",
            operator: "_lt",
            value: startOfDay(addDays(new Date(), 1)),
          },
          {
            field: "status",
            operator: "_not_eq",
            value: completedId,
          },
        ],
      });
    }

    return configs;
  } else if (entity === "work_order_requests") {
    if (hasDueDateField) {
      configs.push({
        name: t("filters.toggles.overdue"),
        filters: [
          {
            field: "due_date",
            operator: "_lt",
            value: startOfDay(addDays(new Date(), 1)),
          },
          {
            field: "status",
            operator: "_not_in",
            value: ["approved", "declined", "completed"],
          },
        ],
      });
    }

    return configs;
  } else if (entity === "preventive_maintenances") {
    return configs;
  }

  return [];
};
