import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { IconButton } from "shared/components/IconButton";
import { ConfirmationDialog } from "shared/components/Dialog";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { useForm } from "shared/lib/hooks/useForm";
import OnHoldIcon from "../../assets/icons/bulkOnHold.svg?react";
import DeclineIcon from "../../assets/icons/bulkDecline.svg?react";
import { Input } from "shared/components/Input";

const IconMap = {
  on_hold: OnHoldIcon,
  declined: DeclineIcon,
};

const INITIAL_VALUES = { comments: "" };

export const BulkStatus = ({ requests = [], onUpdate, status, ariaLabel }) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();

  const ids = useMemo(() => {
    return requests.reduce((res, request) => {
      const { id, status: requestStatus } = request;

      const condition =
        status === "on_hold"
          ? requestStatus === "pending"
          : requestStatus === "pending" || requestStatus === "on_hold";

      if (condition) {
        res.push(id);
      }
      return res;
    }, []);
  }, [status, requests]);

  const Icon = IconMap[status];

  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  const type = status === "on_hold" ? "Put on Hold" : "Declined";

  const clickHandler = () => {
    if (!ids.length) {
      addSnackBar({
        content: t(
          `work_order_requests.bulkActions.updateStatus.snack.noSelected.${status}`
        ),
        type: "warning",
      });
    } else {
      setIsConfirmationVisible(true);
    }
  };

  const cancelHandler = () => {
    setIsConfirmationVisible(false);
    form.resetForm();
  };

  const form = useForm({
    initialValues: INITIAL_VALUES,
    enableReinitialize: true,
    onSubmit: ({ comments }) => {
      onUpdate({ ids, status, comments })
        .unwrap()
        .then(() => {
          setIsConfirmationVisible(false);
          addSnackBar({
            content: t(
              `work_order_requests.bulkActions.updateStatus.snack.success.${status}`
            ),
            type: "success",
          });
        })
        .then(window.totango.track(`Bulk Requests ${type}`, "Web - Requests"))
        .catch(() => {
          addSnackBar({
            content: t(
              "work_order_requests.bulkActions.updateStatus.snack.fail"
            ),
            type: "error",
          });
        });
    },
  });

  return (
    <>
      <IconButton onClick={clickHandler} aria-label={ariaLabel}>
        <Icon />
      </IconButton>
      {isConfirmationVisible && (
        <ConfirmationDialog
          title={t(
            `work_order_requests.bulkActions.updateStatus.title.${status}`
          )}
          confirmButtonLabel={t("save")}
          cancelButtonLabel={t("discardChanges")}
          description={
            <RequestContainer>
              <RequestsSelected>
                {t(
                  `work_order_requests.bulkActions.updateStatus.confirmation.${status}.selected_${
                    ids.length > 1 ? "many" : "one"
                  }`,
                  { count: ids.length }
                )}
              </RequestsSelected>
              <RequestCommentsTitle>
                {t("work_order_requests.form.comments")}
              </RequestCommentsTitle>
              <Input
                name="comments"
                value={form.values.comments}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                placeholder={t("work_order_requests.form.comments")}
                isTextArea
              />
            </RequestContainer>
          }
          onProceed={form.handleSubmit}
          onCancel={cancelHandler}
        />
      )}
    </>
  );
};

BulkStatus.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number),
  onUpdate: PropTypes.func.isRequired,
  count: PropTypes.number,
  status: PropTypes.string,
};

const RequestContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 32px;
  padding-bottom: 32px;
`;

const RequestCommentsTitle = styled.span`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
`;

const RequestsSelected = styled.span`
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: var(--color_grey900);
  margin-bottom: 16px;
`;
