import { TFunction } from "i18next";
import { historyFields } from "../../config/constants";
import { formatDateValueWithSlash } from "shared/lib/helpers";
import { User } from "modules/users/types/User";

export interface IChange {
  from: unknown[];
  to: unknown[];
}

export interface IHistory {
  action: unknown;
  changelog: { [key: string]: IChange };
  modifier?: User;
  modifier_name?: string;
  created_at: string | Date;
  id: string | number;
}

export interface IFormattedChange {
  key: string;
  from?: unknown[] | boolean | string | number | null;
  to?: unknown[] | boolean | string | number | null;
  value?: unknown;
  description?: string;
  wideDisplay?: boolean;
  isDate?: boolean;
}

export const getHistoryContent = (
  history: IHistory,
  t: TFunction
): IFormattedChange[] | void => {
  const { action, changelog } = history;

  if (action === "create")
    return [
      {
        key: t(`work_orders.historyAttributes.created_work_order`),
        from: [],
        to: [],
      },
    ];

  const changeDetails: IFormattedChange[] = [];

  for (const key in changelog) {
    if (historyFields.includes(key)) {
      const formattedKey = `${t(`work_orders.historyAttributes.${key}`)}:`;
      const change = changelog[key];
      let formattedChange: IFormattedChange | void;

      switch (key) {
        case "checklist":
          formattedChange = formatChecklist(change as ICheckedChange, t);
          break;
        case "due_date":
          formattedChange = formatDueDate(formattedKey, change);
          break;
        case "work_order_status":
          formattedChange = formatWorkOrderStatus(formattedKey, change, t);
          break;
        case "priority":
          formattedChange = formatPriority(formattedKey, change, t);
          break;
        case "procedure_template_assignments":
          formattedChange = formatProcedureTemplateAssignment(history, t);
          break;
        case "procedure_item":
          formattedChange = formatProcedureItem(
            change as IProcedureItemChange,
            t
          );
          break;
        case "procedure_completion":
          formattedChange = formatProcedureCompletion(change, t);
          break;
        default:
          formattedChange = {
            key: formattedKey,
            from: change.from ?? [],
            to: change.to ?? [],
          };
          break;
      }

      if (formattedChange != null) {
        changeDetails.push(formattedChange);
      }
    }
  }

  return changeDetails;
};

interface ICheckedChange extends IChange {
  from: { checked: boolean; text: string }[];
  to: { checked: boolean; text: string }[];
}

function formatChecklist(
  change: ICheckedChange,
  t: TFunction
): IFormattedChange | void {
  const index = change.to?.findIndex((toItem, index) => {
    return change.from?.some((fromItem, idx) => {
      return index === idx && toItem.checked !== fromItem.checked;
    });
  });

  if (index !== -1 && change.to?.length === change.from?.length) {
    return {
      key: `${t(`work_orders.historyAttributes.checklist_progress`)}:`,
      from: change.from[index].checked,
      to: change.to[index].checked,
      value: change.to[index].text,
    };
  } else if (change.to?.length > 0 || change.from?.length > 0) {
    const formattedChange = {
      key: `${t(`work_orders.historyAttributes.created_checklist`)}:`,
      from: [] as string[],
      to: [] as string[],
    };

    change.to.forEach((val) => formattedChange.to.push(`- ${val.text}`));
    change.from.forEach((val) => formattedChange.from.push(`- ${val.text}`));

    return formattedChange;
  }

  return;
}

function formatDueDate(
  formattedKey: string,
  change: IChange
): IFormattedChange {
  return {
    key: formattedKey,
    from:
      change.from?.length > 0
        ? change.from.map((val) => formatDateValueWithSlash(val))
        : [],
    to:
      change.to?.length > 0
        ? change.to.map((val) => formatDateValueWithSlash(val))
        : [],
  };
}

function formatWorkOrderStatus(
  formattedKey: string,
  change: IChange,
  t: TFunction
): IFormattedChange {
  return {
    key: formattedKey,
    from:
      change.from?.length > 0
        ? change.from.map((val) => t(`work_orders.status.${val}`))
        : [],
    to:
      change.to?.length > 0
        ? change.to.map((val) => t(`work_orders.status.${val}`))
        : [],
  };
}

function formatPriority(
  formattedKey: string,
  change: IChange,
  t: TFunction
): IFormattedChange {
  return {
    key: formattedKey,
    from:
      change.from?.length > 0
        ? change.from.map((val) => t(`work_orders.priority.${val}`))
        : [],
    to:
      change.to?.length > 0
        ? change.to.map((val) => t(`work_orders.priority.${val}`))
        : [],
  };
}

function formatProcedureTemplateAssignment(
  history: IHistory,
  t: TFunction
): IFormattedChange {
  const changeName =
    history.action === "destroy"
      ? t("work_orders.historyAttributes.procedure_template_remove")
      : t("work_orders.historyAttributes.procedure_template_assign");

  const change = history.changelog["procedure_template_assignments"];

  return {
    key: changeName,
    from: change.from,
    to: change.to,
  };
}

interface IProcedureItemChange extends IChange {
  item_type: string;
  item_name: string;
  procedure_name: string;
  from: {
    checked?: boolean;
    date?: string;
    selected?: string;
    data?: string;
  }[];
  to: {
    checked?: boolean;
    date?: string;
    selected?: string;
    data?: string;
  }[];
}

function formatProcedureItem(
  change: IProcedureItemChange,
  t: TFunction
): IFormattedChange {
  switch (change.item_type) {
    case "checkbox":
      return {
        key: t("procedures.history.item.title", {
          name: change["procedure_name"],
        }),
        from: change.from[0]?.checked?.toString(),
        to: change.to[0]?.checked?.toString(),
        value: change["item_name"],
      };
    case "date":
      return {
        key: t("procedures.history.item.title", {
          name: change["procedure_name"],
        }),
        description: change["item_name"],
        from: change.from[0]?.date,
        to: change.to[0]?.date,
        isDate: true,
      };
    case "multiple_choice":
      return {
        key: t("procedures.history.item.title", {
          name: change["procedure_name"],
        }),
        description: change["item_name"],
        from: change.from[0]?.selected,
        to: change.to[0]?.selected,
      };
    case "yes_no_na":
      return {
        key: t("procedures.history.item.title", {
          name: change["procedure_name"],
        }),
        description: change["item_name"],
        from: change.from[0]?.data
          ? t(
              `procedures.history.item.${change.from[0]?.data.replace(
                "/",
                "_"
              )}`
            )
          : null,
        to: change.to[0]?.data
          ? (t(
              `procedures.history.item.${change.to[0]?.data.replace("/", "_")}`
            ) as string)
          : null,
      };
    default:
      return {
        key: t("procedures.history.item.title", {
          name: change["procedure_name"],
        }),
        description: change["item_name"],
        from: change.from[0]?.data,
        to: change.to[0]?.data,
        wideDisplay: change.item_type === "text",
      };
  }
}

function formatProcedureCompletion(change: IChange, t: TFunction) {
  const { from, to } = change || {};
  let changeName = t("work_orders.historyAttributes.procedure_completion");

  if (to.length === 0 && from.length > 0) {
    changeName = t("work_orders.historyAttributes.procedure_reopen");
  }

  return {
    key: changeName,
    from,
    to,
  };
}
