import { IProcedureTemplateItemOption } from "modules/procedures/types/types";
import { Input } from "shared/components/Input";
import { Button } from "shared/components/Button";
import { CustomFormik } from "shared/lib/hooks/useForm";
import { H3, Hint } from "shared/components/Typography";
import styled from "styled-components";
import { IconButton } from "shared/components/IconButton";
import RemoveIcon from "shared/assets/icon/remove.svg?react";
import update from "immutability-helper";
import { useTranslation } from "react-i18next";
import { pattern } from "@test-data";

interface MultipleChoiceInputProps {
  form: CustomFormik;
  onChange: (options: IProcedureTemplateItemOption) => void;
}

export const MultipleChoiceInput = ({
  form,
  onChange,
}: MultipleChoiceInputProps) => {
  const { t } = useTranslation();

  return (
    <>
      <H3>{t("procedures.items.multiple_choice.header")}</H3>
      <Hint>{t("procedures.items.multiple_choice.hint")}</Hint>
      {form.values.options?.choices?.map((choice: string, index: number) => {
        // this is ugly, but Formik doesn't seem to be able to statically define the
        // dynamic nature of the MultipleChoice validation
        const error = (form.touched?.choices as unknown as boolean[])?.[index]
          ? (form.errors.options as { choices: string[] })?.choices?.[index]
          : null;

        return (
          <Option>
            <Input
              key={index}
              name={`choices[${index}]`}
              value={choice}
              onChange={(event) => {
                const choices = form.values?.options?.choices || [];
                const newChoices = [...choices];
                newChoices[index] = event.target.value;
                onChange({ choices: newChoices });
              }}
              onBlur={form.handleBlur}
              placeholder={t("procedures.items.multiple_choice.placeholder", {
                index: index + 1,
              })}
              isTextArea
              fontSize={16}
              required
              disabled={false}
              errorMessage={error}
              data-testid={pattern.choiceInputId(index + 1)}
            />
            <IconButton
              onClick={() => {
                const prevChoices = form.values?.options?.choices || [];
                onChange({
                  choices: update(prevChoices, {
                    $splice: [[index, 1]],
                  }),
                });
              }}
              data-testid={pattern.choiceDeleteId(index + 1)}
            >
              <RemoveIcon />
            </IconButton>
          </Option>
        );
      })}
      <Button
        onClick={() => {
          const choices = form.values?.options?.choices || [];
          onChange({ choices: [...choices, ""] });
        }}
      >
        {t("procedures.items.multiple_choice.add_option")}
      </Button>
    </>
  );
};

const Option = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
`;
