import { ReportActionBarProps } from "modules/reporting/types/ReportActionBarProps";
import { useReportFilterOptions } from "modules/reporting/lib/useReportFilterOptions";
import { ReportActionBar } from "modules/reporting/components/ReportActionBar";

export const CreatedVsCompletedReportActionBar = ({
  buttons,
  entity,
}: ReportActionBarProps) => {
  const filterOptions = useReportFilterOptions(entity);
  const filters = [
    filterOptions.title,
    filterOptions.id,
    filterOptions.status,
    filterOptions.createdAt,
    filterOptions.type,
    filterOptions.assignTo,
    filterOptions.categories,
    filterOptions.location,
    filterOptions.asset,
    filterOptions.vendors,
    filterOptions.createdBy,
  ];

  return (
    <ReportActionBar
      filterEntity={entity}
      filters={filters}
      buttons={buttons}
    />
  );
};
