/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Pagination } from "features/Pagination";
import { TopBar } from "widgets/TopBar";
import { useGetLocationsQuery } from "modules/locations";
import { useGetVendorsQuery } from "modules/vendors";
import { useGetAssetsQuery } from "modules/assets";
// import { useGetTeamsQuery } from "modules/teams";
import { useGetCategoriesQuery } from "modules/categories";
import { useGetTechniciansQuery } from "modules/users";
import { useGetAdminConfigurationQuery } from "modules/adminFieldsConfiguration";
import {
  useGetWorkOrderStatusesQuery,
  useGetWorkOrdersQuery,
  useGetWorkOrdersKPIsQuery,
  useExportWorkOrdersMutation,
} from "modules/workOrders";
import { Table } from "shared/components/Table";
import {
  useSearch,
  usePageParam,
  useSortParam,
  useFiltersParam,
} from "shared/lib/hooks";
import { NoResults } from "shared/components/NoResults";
import { Tabs } from "shared/components/Tabs";
import { ConfirmationDialog } from "shared/components/Dialog";

import { EmptyWorkOrderList } from "./EmptyWorkOrderList";
import { ErrorWorkOrderList } from "./ErrorWorkOrderList";
import { BulkActions } from "../BulkActions";
import { Calendar } from "../Calendar";
import { useWorkOrdersTableTools } from "modules/workOrders/lib/useWorkOrdersTableTools";
import {
  FilterPriorityOptions,
  FilterTypeOptions,
} from "../../../filters/configs/constants";
import { usePermission } from "app/providers/PermissionsProvider";
import { getStatusesOptions } from "../../lib/helpers/form";
import { isWorkOrderOverdue } from "modules/workOrders/lib/helpers/isWorkOrderOverdue";
import { RowDecoratorImage } from "shared/components/Table/components/RowDecoratorImage";
import { PriorityIconUrlMap } from "shared/components/Priority";
import { useSearchParam } from "shared/lib/hooks";
import { stringifyFilters } from "modules/filters/lib/stringifyFilters.js";
import {
  WorkOrdersKPIs,
  WorkOrdersKPIsFallback,
} from "modules/workOrders/components/WorkOrdersKPIs";
import { ErrorBoundary } from "@sentry/react";
import { useIsLoading } from "shared/lib/hooks/useIsLoading";

const defaultFilters = [
  {
    field: "status",
    operator: "_in",
    value: [1, 2, 3, 4, 6, 7, 8],
  },
];

export const List = ({
  onOpenWorkOrder = () => {},
  onOpenPM = () => {},
  withExport,
  showKPIs,
}) => {
  const { t } = useTranslation();
  const { woCalendarPermit } = usePermission();
  const [tab, setTab] = useState("list");

  const [selected, setSelected] = useState([]);
  const [updateStatusError, setUpdateStatusError] = useState(false);

  const [exportWorkOrders] = useExportWorkOrdersMutation();

  const [search] = useSearchParam();
  const [page] = usePageParam(1);
  const [sort] = useSortParam();
  const [filters] = useFiltersParam(defaultFilters);
  const stringifiedFilters = stringifyFilters(filters, "work_orders");

  const { tableSettings, applySettings, columns, areTableSettingsFetching } =
    useWorkOrdersTableTools({
      onOpen: onOpenWorkOrder,
    });

  const queryParams = {
    search,
    sort,
    ...stringifiedFilters,
  };

  const {
    data,
    error = false,
    isFetching,
  } = useGetWorkOrdersQuery({
    ...queryParams,
    page,
    per_page: tableSettings?.per_page,
  });
  const kpiRes = useGetWorkOrdersKPIsQuery(queryParams, { skip: !showKPIs });
  const isLoading = useIsLoading(isFetching, [search, page, sort, filters]);

  const { data: statusConfiguration } = useGetAdminConfigurationQuery({
    type: "work_order_status",
  });

  useEffect(() => {
    if (data) {
      setSelected([]);
    }
  }, [data]);

  const [assets, onAssetsSearch] = useSearch(useGetAssetsQuery, true);
  const [vendors, onVendorsSearch] = useSearch(useGetVendorsQuery, true);
  const [locations, onLocationsSearch] = useSearch(useGetLocationsQuery, true);
  // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
  // const [teams, onTeamsSearch] = useSearch(useGetTeamsQuery, true);
  const [categories, onCategoriesSearch] = useSearch(
    useGetCategoriesQuery,
    true
  );
  const [statuses, onStatusesSearch] = useSearch(
    useGetWorkOrderStatusesQuery,
    true
  );
  const [technicians, onTechniciansSearch] = useSearch(
    useGetTechniciansQuery,
    true,
    {},
    "full_name"
  );

  const statusesOptions = useMemo(
    () => getStatusesOptions(statuses?.data, statusConfiguration),
    [statuses, statusConfiguration]
  );

  const getFilterOptions = (field) => {
    if (field === "categories") {
      return categories?.data.map(({ id, name }) => ({ key: id, value: name }));
    }
    if (field === "location") {
      return locations?.data.map(({ id, path }) => ({ key: id, value: path }));
    }
    if (field === "asset") {
      return assets?.data.map(({ id, name }) => ({ key: id, value: name }));
    }
    if (field === "vendors") {
      return vendors?.data.map(({ id, name }) => ({ key: id, value: name }));
    }

    if (field === "assign_to") {
      return technicians?.data.reduce((acc, { id, name, surname, status }) => {
        if (status !== "invitation_sent") {
          acc.push({ key: id, value: `${name} ${surname}` });
        }
        return acc;
      }, []);
    }

    if (field === "priority") return FilterPriorityOptions;
    if (field === "type") return FilterTypeOptions;
    if (field === "status") {
      return statusesOptions?.map(({ id, name }) => ({
        key: id,
        value: t(`work_orders.status.${name}`),
      }));
    }
    return [];
  };

  const getFilterSearch = (field) => {
    if (field === "location") {
      return onLocationsSearch;
    }
    if (field === "categories") {
      return onCategoriesSearch;
    }
    if (field === "asset") {
      return onAssetsSearch;
    }
    if (field === "vendors") {
      return onVendorsSearch;
    }

    if (field === "assign_to") {
      return onTechniciansSearch;
    }

    if (field === "status") {
      return onStatusesSearch;
    }

    return () => {};
  };

  const hasFilter = Object.keys(filters).length > 0;
  const empty = data?.data?.length === 0 && !isFetching && !hasFilter;
  const notFound =
    data?.data?.length === 0 &&
    !isFetching &&
    (search?.length > 0 || hasFilter);
  const isEmptyWorkOrderList = empty && !notFound;
  const isNoResultList = empty && !isEmptyWorkOrderList;

  if (error) {
    return <ErrorWorkOrderList />;
  }

  return (
    <WOTabs
      selectedKey={tab}
      onSelectionChange={(key) => setTab(key)}
      showKPIs={showKPIs}
    >
      <Tabs.TabList aria-label="Tabs">
        <Tabs.Tab id="list">{t("work_orders.tabs.list")}</Tabs.Tab>
        <Tabs.Tab id="calendar">{t("work_orders.tabs.calendar")}</Tabs.Tab>
      </Tabs.TabList>
      {showKPIs && (
        <ErrorBoundary fallback={<WorkOrdersKPIsFallback />}>
          <WorkOrdersKPIs
            data={kpiRes?.data?.data}
            isLoading={kpiRes?.isFetching}
          />
        </ErrorBoundary>
      )}
      <TopBar
        entity="work_orders"
        columns={columns}
        tableSettings={tableSettings}
        setTableSettings={applySettings}
        filterCount={Object.keys(filters).length}
        getOptions={getFilterOptions}
        getSearch={getFilterSearch}
        onExportClick={() =>
          exportWorkOrders({ ...stringifiedFilters, search })
        }
        withExport={withExport}
        showToggleFilters={true}
        withTabSettingsAndExport={tab === "list"}
        initialFilters={defaultFilters}
      />
      <WOListTabPanel id="list">
        {isEmptyWorkOrderList && <EmptyWorkOrderList />}
        {isNoResultList && <NoResults query={search} />}
        {!isEmptyWorkOrderList && !isNoResultList && (
          <>
            <Table
              columns={columns}
              rows={data?.data}
              showSkeleton={isLoading || areTableSettingsFetching}
              highlight={search}
              notFound={notFound}
              selectionModel={selected}
              onSelectionModelChange={setSelected}
              bulkActionsNode={
                <BulkActions
                  selectionModel={selected}
                  setUpdateStatusError={setUpdateStatusError}
                />
              }
              getRowBorder={(row) =>
                isWorkOrderOverdue(row.due_date, row.status?.name)
                  ? "var(--color_corp_red)"
                  : undefined
              }
              getRowIcon={(row) => (
                <RowDecoratorImage
                  src={
                    row.priority ? PriorityIconUrlMap[row.priority] : undefined
                  }
                  width="16px"
                  height="16px"
                />
              )}
            />
            <Pagination
              paginationData={data?.meta.pagination}
              label={t("work_orders.title")}
            />
            {updateStatusError && (
              <ConfirmationDialog
                title={t(
                  "work_orders.bulkActions.updateStatus.assetValidation.title"
                )}
                confirmButtonLabel={t(
                  "work_orders.bulkActions.updateStatus.assetValidation.confirm"
                )}
                description={t(
                  "work_orders.bulkActions.updateStatus.assetValidation.description"
                )}
                onProceed={() => setUpdateStatusError(false)}
              />
            )}
          </>
        )}
      </WOListTabPanel>
      <Tabs.TabPanel id="calendar">
        {woCalendarPermit && (
          <Calendar
            onOpenWorkOrder={onOpenWorkOrder}
            onOpenPM={onOpenPM}
            filters={filters}
          />
        )}
      </Tabs.TabPanel>
    </WOTabs>
  );
};

const WOTabs = styled(Tabs.Root)`
  display: grid;
  grid-template-rows: repeat(${(p) => (p.showKPIs ? "3" : "2")}, auto) 1fr;
  height: 100%;
  box-sizing: border-box;
`;

const WOListTabPanel = styled(Tabs.TabPanel)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;
