import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AdminSettingsFormHeader } from "../../AdminSettingsFormHeader";
import { Card } from "./Card";

import styled from "styled-components";

const mapPermissionStrings = (permissionStrings) =>
  permissionStrings?.map(({ text, allow }, i) => ({
    id: i,
    text,
    access: allow,
  }));

const PermissionsMap = (t) => {
  const roles = ["admin", "limitedAdmin", "technician", "requester"];

  return roles.map((role, i) => ({
    id: i,
    title: t(`permissions.list.${role}.title`),
    description: t(`permissions.list.${role}.description`),
    permissions: mapPermissionStrings(
      t(`permissions.list.${role}.options`, {
        returnObjects: true,
      })
    ),
  }));
};

export const ViewPermissions = () => {
  const { t } = useTranslation();
  const permissions = PermissionsMap(t);
  const navigate = useNavigate();

  return (
    <>
      <AdminSettingsFormHeader
        title={t("permissions.viewPermissionsTitle")}
        type="view"
        onClose={() => navigate("/admin-settings")}
      />
      <Container>
        {permissions.map(({ id, ...permission }) => (
          <Card key={id} permission={permission} />
        ))}
      </Container>
    </>
  );
};

const Container = styled.div`
  height: calc(100vh - 113px);
  width: 900px;
  margin: 32px auto 0;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;
