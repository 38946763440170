/**
 * Checks if an IFormattedChange value (i.e., `to`/`from`) is blank.
 * Since those values can be nearly any type, function checks for null/undefined and/or emptiness
 * of arrays and strings.
 */
export const isNotBlank = (value: unknown[] | unknown | void) => {
  if (Array.isArray(value)) {
    return value != null && value.length > 0;
  }

  return value != null && value !== "";
};
