import React, { useState, useMemo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ADD_LOCATION_INITIAL_VALUE } from "modules/locations/configs/constants";
import { addLocationValidationSchema } from "modules/locations/configs/validationSchema";
import { LocationEditor } from "modules/locations/components/LocationEditor";
import { getFormatForService } from "modules/locations";
import { useAddLocationMutation } from "modules/locations";
import { FileManager } from "modules/fileManager";

import { RESPONSE_MESSAGE } from "shared/lib/constants/api";
import { useForm } from "shared/lib/hooks/useForm";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { EditorModal } from "shared/components/Modals";
import { useGetFormFieldsQuery } from "modules/formFields";
import { getOptionalFields } from "modules/locations/lib/helper";

export const LocationCreate = ({
  isShow,
  defaultValues,
  onClose,
  onOpen,
  onCreate,
  defaultLocations,
  defaultOnLocationsSearch,
}) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();

  // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
  // const [teamsSeach, setTeamsSeach] = useState("");
  const { data } = useGetFormFieldsQuery({
    type: "location",
    action_type: "upsert",
  });

  const [generateQr, setGenerateQr] = useState(false);

  const [addLocation, addLocationRes] = useAddLocationMutation();

  const onCloseModal = () => {
    onClose();
    addLocationFrom.resetForm();
    setGenerateQr(false);
  };

  // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
  // const { data: teams } = useGetTeamsQuery(
  //   isShow ? { ...(teamsSeach ? { search: teamsSeach } : {}) } : skipToken
  // );

  const locationDataForForm = useMemo(
    () => ({
      ...ADD_LOCATION_INITIAL_VALUE,
      ...defaultValues,
    }),
    [defaultValues]
  );

  const optionalFields = getOptionalFields(data);

  const addLocationFrom = useForm({
    initialValues: ADD_LOCATION_INITIAL_VALUE,
    willUpdatedValues: locationDataForForm,
    validationSchema: addLocationValidationSchema(optionalFields, t),
    onSubmit: async (body, { setFieldError }) => {
      const dataForApi = getFormatForService(body, {
        generateQr,
        creation: true,
      });
      addLocation(dataForApi)
        .unwrap()
        .then(({ data }) => {
          if (onCreate) {
            onCreate({ value: data.id, label: data.name });
          } else {
            addSnackBar({
              title: "Location created",
              content: `${data.name} was successfully created`,
              type: "success",
              primaryButtonLabel: "View location",
              secondaryButtonLabel: "Close",
              handlePrimaryButtonClick: () => onOpen(data.id),
            });
          }
          window.totango.track("New Location Created", "Web - Locations");
          onCloseModal();
        })
        .catch(({ message }) => {
          if (message.name?.[0].includes(RESPONSE_MESSAGE.already_taken)) {
            setFieldError("name", "This location name already exists");
          } else if (
            message["qr_code.value"]?.[0] === RESPONSE_MESSAGE.already_taken
          ) {
            setFieldError("code", t("m2", { ns: "errors" }));
          } else {
            addSnackBar({
              title: "Location created failed",
              content: JSON.stringify(message),
              type: "error",
              secondaryButtonLabel: "Close",
            });
          }
        });
    },
  });

  return (
    <EditorModal
      isOpen={isShow}
      label={t("locations.new")}
      onClose={onCloseModal}
      onSubmit={addLocationFrom.handleSubmit}
      disabledSubmit={
        !addLocationFrom.dirty || !!Object.values(addLocationFrom.errors).length
      }
      isSubmitLoading={addLocationRes.isLoading}
      form={addLocationFrom}
    >
      <WrapperFileManager
        name="uploads_attributes"
        value={addLocationFrom.values.uploads_attributes}
        setValue={addLocationFrom.handleSetValue}
      />
      <LocationEditor
        form={addLocationFrom}
        // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
        //teamsOptions={teams?.data}
        //onSearchTeams={setTeamsSeach}
        optionalFields={optionalFields}
        generateQr={generateQr}
        setGenerateQr={setGenerateQr}
        defaultLocations={defaultLocations}
        defaultOnLocationsSearch={defaultOnLocationsSearch}
      />
    </EditorModal>
  );
};

LocationCreate.propTypes = {
  isShow: PropTypes.bool,
  defaultValues: PropTypes.any,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  onCreate: PropTypes.func,
  defaultLocations: PropTypes.any,
  defaultOnLocationsSearch: PropTypes.any,
};

const WrapperFileManager = styled(FileManager)`
  margin-bottom: 30px;
`;
