import React from "react";
import PropTypes from "prop-types";
import { Dropdown, MultiSelectDropdown } from "shared/components/Dropdowns";
import { Input } from "shared/components/Input";
import {
  FilterBooleanOperators,
  FilterNoSearchFields,
} from "../configs/constants";

import "react-datepicker/dist/react-datepicker.css";
import { getTimezoneDateFormats } from "shared/lib/helpers";
import {
  DateRangePicker,
  SingleDatePicker,
} from "shared/components/DatePicker";
import { useTranslation } from "react-i18next";

export const FiltersComparedColumn = ({
  name,
  value,
  field,
  setValue,
  fieldType,
  operatorType,
  options = [],
  onSearch,
}) => {
  const { onlyDateFormat } = getTimezoneDateFormats();
  const { t } = useTranslation();
  if (FilterBooleanOperators.includes(operatorType)) {
    return null;
  }

  if (fieldType === "date") {
    if (operatorType === "_between") {
      const [start, end] = value || [];
      return (
        <DateRangePicker
          selected={start}
          onChange={(dates) => setValue(name, dates)}
          dateFormat={onlyDateFormat}
          startDate={start}
          endDate={end}
        />
      );
    } else {
      return (
        <SingleDatePicker
          selected={value}
          onChange={(date) => setValue(name, date)}
          dateFormat={onlyDateFormat}
        />
      );
    }
  }

  if (fieldType === "enum" && ["_eq", "_not_eq"].includes(operatorType)) {
    return (
      <Dropdown
        name={name}
        value={value}
        options={options}
        setValue={setValue}
        placeholder={t("startTyping")}
        onSearch={onSearch}
        withSearch={!FilterNoSearchFields.includes(field)}
      />
    );
  }

  if (fieldType === "enum" || fieldType === "select") {
    return (
      <MultiSelectDropdown
        name={name}
        value={value}
        options={options}
        defaultOptions={options}
        setValue={setValue}
        onSearch={onSearch}
        placeholder={t("startTyping")}
        chipWidth={30}
      />
    );
  }

  return (
    <Input
      name={name}
      value={value}
      onChange={({ target: { value } }) => setValue(name, value)}
    />
  );
};

FiltersComparedColumn.propTypes = {
  name: PropTypes.string,
  setValue: PropTypes.func,
  fieldType: PropTypes.string,
  operatorType: PropTypes.string,
  options: PropTypes.array,
};
