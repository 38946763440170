import { IProcedureItem } from "modules/procedures/types";
import { useTranslation } from "react-i18next";
import { useDisplayDate } from "./useDisplayDate";

export const useGetProcedureItemValue = () => {
  const { t } = useTranslation();
  const displayDate = useDisplayDate();

  return (item: IProcedureItem) => {
    if (item == null) return null;

    switch (item.item_type) {
      case "multiple_choice":
        return item?.value?.selected;

      case "checkbox":
        return item?.value?.checked
          ? t("procedures.items.checkbox.true")
          : t("procedures.items.checkbox.false");

      case "date":
        if (item?.value?.date == null) return null;

        return displayDate(item?.value?.date, true);

      case "yes_no_na":
        if (item?.value == null) return null;

        return t(
          `procedures.items.yes_no_na.${(item?.value?.data as string).replace(
            "/",
            ""
          )}`
        );

      default:
        return item?.value?.data;
    }
  };
};
