import { ReportKey } from "modules/reporting/types/ReportKey";
import { MaintenanceHistoryByAssigneeReportChart } from "./MaintenanceHistoryByAssigneeReportChart";
import { MaintenanceHistoryByAssigneeReportTable } from "./MaintenanceHistoryByAssigneeReportTable";
import { MaintenanceHistoryByAssigneeReportActionBar } from "./MaintenanceHistoryByAssigneeReportActionBar";
import { ReportPageLayout } from "modules/reporting/components/ReportPageLayout";
import { ReportPageTopBar } from "modules/reporting/components/ReportPageTopBar";
import { ReportPageProvider } from "modules/reporting/lib/ReportPageContext";
import { KPIGrid } from "modules/reporting/components/KPIGrid";
import { useGetChartData } from "modules/reporting/lib/useGetChartData";
import {
  MaintenanceHistoryByAssigneeChartData,
  MaintenanceHistoryByAssigneeTableData,
} from "./types";
import { useGetTableData } from "modules/reporting/lib/useGetTableData";
import {
  PDFReportDownloader,
  PDFReportDownloaderHandle,
} from "modules/reporting/components/PDFReportDownloader/PDFReportDownloader";
import { getReportFilename } from "modules/reporting/lib/getReportFilename";
import { useTranslation } from "react-i18next";
import { ExportMenu } from "modules/reporting/components/ExportMenu";
import { useDownloadReportXLS } from "modules/reporting/lib/useDownloadReportXLS";
import { useRef } from "react";
import { useMaintenanceHistoryByAssigneeKPIs } from "./useMaintenanceHistoryByAssigneeKPIs";
import { useReportPeriod } from "../../../lib/useReportPeriod";
import { usePersistentState } from "../../../lib/usePersistentState";
import { useFiltersParam } from "shared/lib/hooks";
import { stringifyFilters } from "modules/filters/lib/stringifyFilters";
import { Entity } from "modules/reporting/types/Entity";

const reportKey = ReportKey.MaintenanceHistoryByAssignee;
const entity = Entity.workOrders;

export const MaintenanceHistoryByAssigneeReport = () => {
  const { t } = useTranslation();
  const pdfDownloaderRef = useRef<PDFReportDownloaderHandle>(null);

  const [reportPeriod, setReportPeriod] = useReportPeriod();
  const kpiRes = useMaintenanceHistoryByAssigneeKPIs(reportPeriod);
  const chartRes = useGetChartData<MaintenanceHistoryByAssigneeChartData[]>(
    reportKey,
    reportPeriod
  );
  const tableRes = useGetTableData<MaintenanceHistoryByAssigneeTableData[]>(
    reportKey,
    reportPeriod
  );

  const [filters] = useFiltersParam([]);
  const stringifiedFilters = stringifyFilters(filters, entity);
  const downloadXLS = useDownloadReportXLS(
    reportKey,
    undefined,
    stringifiedFilters
  );

  const isExportReady = !chartRes.isFetching && !kpiRes.isFetching;
  const [mode, setMode] = usePersistentState(
    "total_labor_hours",
    "completed_work_orders"
  );
  return (
    <ReportPageProvider value={{ reportPeriod, setReportPeriod }}>
      <ReportPageTopBar />
      <ReportPageLayout
        reportKey={reportKey}
        kpis={<KPIGrid isLoading={kpiRes.isFetching} kpis={kpiRes.data} />}
        chart={
          <>
            <MaintenanceHistoryByAssigneeReportChart
              data={chartRes.data}
              isFetching={chartRes.isFetching}
              mode={mode}
              setMode={setMode}
            />
          </>
        }
        actionBar={
          <MaintenanceHistoryByAssigneeReportActionBar
            buttons={
              <ExportMenu
                downloadXLS={() => downloadXLS()}
                downloadPDF={() => pdfDownloaderRef.current?.doDownload()}
                isPDFReady={isExportReady}
              />
            }
            entity={entity}
          />
        }
        table={
          <MaintenanceHistoryByAssigneeReportTable
            data={tableRes.data?.data}
            paginationData={tableRes.data?.meta.pagination}
            isFetching={tableRes.isFetching}
          />
        }
      />
      <PDFReportDownloader
        ref={pdfDownloaderRef}
        filename={getReportFilename(reportKey, "pdf")}
        exportProps={{
          reportName: t(`reporting.${reportKey}.title`),
          reportPeriod: reportPeriod,
          kpis: kpiRes.data,
        }}
        charts={[
          {
            title: t(`reporting.${reportKey}.title`) as string,
            chart: (
              <div style={{ width: "1000px", height: "600px" }}>
                <MaintenanceHistoryByAssigneeReportChart
                  data={chartRes.data}
                  isFetching={chartRes.isFetching}
                  mode={mode}
                  setMode={setMode}
                />
              </div>
            ),
          },
        ]}
      />
    </ReportPageProvider>
  );
};
