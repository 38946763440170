import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Input } from "shared/components/Input";
import { InlineLabel } from "shared/components/widgets";
import { Button } from "shared/components/Button";
import { SelectCreatable } from "shared/components/Select";
import { useSearch } from "shared/lib/hooks";

import { useGetPartsQuery } from "modules/parts";
import { PartCreate } from "modules/parts/components/PartCreate";
import { measurementUnits } from "modules/parts/configs/constant";

export const WorkOrderPartEditor = ({ form, assetId, type }) => {
  const { t } = useTranslation();

  const [parts = { data: [] }, onPartsSearch] = useSearch(
    useGetPartsQuery,
    true,
    {
      format_resource: "light",
      ...(assetId ? { "extend_sort[assets_id_eq]": assetId } : {}),
    }
  );

  const partsOptions = useMemo(
    () =>
      parts?.data?.map(({ id, name, measurement_unit }) => ({
        value: id,
        label: name,
        measurement_unit,
      })),
    [parts]
  );

  const selectedPart =
    partsOptions?.find((opt) => opt.value === form.values.part?.value) || {};

  const unitMeasure = selectedPart.measurement_unit?.id
    ? measurementUnits[selectedPart.measurement_unit?.id].short_name
    : t("parts.measurement_units.fallback.short_name");

  return (
    <Container>
      <InlineLabel label={t("work_order_parts.form.part_id")} labelWidth={110}>
        <SelectCreatable
          options={partsOptions}
          name="part"
          value={form.values.part}
          setValue={form.handleSetValue}
          onBlur={form.handleBlur}
          noOptionsMessage={t("work_order_parts.form.noOptions")}
          errorMessage={form.touched.part && form.errors.part}
          onSearch={onPartsSearch}
          isDisabled={type === "edit"}
          placeholder={t("work_orders.form.startTyping")}
          optionCreateModal={<PartCreate />}
        />
      </InlineLabel>
      <InlineLabel label={t("work_order_parts.form.quantity")} labelWidth={110}>
        <Adjustment>
          <AdjustButton
            variant="secondary"
            onClick={() =>
              form.handleSetValue("quantity", Number(form.values.quantity) - 1)
            }
            disabled={form.values.quantity < 1}
          >
            -
          </AdjustButton>
          <AdjustInputContainer>
            <Input
              type="number"
              name="quantity"
              value={form.values.quantity}
              onChange={form.handleChange}
              onBlur={() => {
                if (!form.values.quantity) {
                  form.handleSetValue("quantity", 1);
                }
                form.setFieldTouched("quantity", true, false);
              }}
              onKeyDown={(e) => e.key === "e" && e.preventDefault()}
              errorMessage={form.touched.quantity && form.errors.quantity}
              endAddon={unitMeasure}
            />
          </AdjustInputContainer>
          <AdjustButton
            variant="secondary"
            onClick={() =>
              form.handleSetValue("quantity", Number(form.values.quantity) + 1)
            }
          >
            +
          </AdjustButton>
        </Adjustment>
      </InlineLabel>
    </Container>
  );
};

const Container = styled.div`
  font-weight: 500;
  padding-bottom: 30px;
`;

const Adjustment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
`;

const AdjustButton = styled(Button)`
  height: 40px;
  font-size: 18px;
`;

const AdjustInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
