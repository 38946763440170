import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_SERVER_API_URL}/api/v1`,
  }),
  tagTypes: [
    "Login",
    "SignUp",
    "ForgotPassword",
    "Invite",
    "UserInfo",
    "ConfirmationEmail",
  ],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: "/users/login",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Login"],
      transformResponse: (response, meta) => {
        const authToken = meta.response.headers.get("Authorization");
        const userData = response.data;

        const user = {
          id: userData.id,
          email: userData.email,
          name: userData.name,
          surname: userData.surname,
          token: authToken,
          entity: response?.meta?.entity?.id,
          tenant: userData.tenant,
          customerName: userData.tenant,
          language: userData.language,
          dateFormat: response?.meta?.entity?.datetime_format,
          timezone: response?.meta?.entity?.timezone,
          customerId: response?.meta?.customer?.id,
          currency: response?.meta?.entity?.currency,
          activity: {
            loginCount: response?.data?.activity?.login_count,
            invitationDetails: response?.data?.invitation_details,
          },
        };

        return { user };
      },
      transformErrorResponse: (response) => response.data,
    }),
    signUp: builder.mutation({
      query: (body) => ({
        url: "/users/signup",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["SignUp"],
      transformErrorResponse: (response) => response.data,
    }),
    inviteUser: builder.mutation({
      query: ({ token, body }) => ({
        url: "/users/invite",
        method: "POST",
        body: { ...body, token },
      }),
      invalidatesTags: ["Invite"],
      transformResponse: () => {},
      transformErrorResponse: (response) => response.data,
    }),
    forgotPassword: builder.mutation({
      query: (body) => ({
        url: "/users/forgot_password",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ForgotPassword"],
      transformErrorResponse: (response) => response.data,
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: "/users/reset_password",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["ForgotPassword"],
      transformResponse: () => {},
      transformErrorResponse: (response) => response.data,
    }),
    getUserViaToken: builder.query({
      query: (params) => ({
        url: "users/invitation_details",
        params,
      }),
      providesTags: ["UserInfo"],
    }),
    confirmEmail: builder.query({
      query: (params) => ({
        url: "/users/confirmation",
        params,
      }),
      providesTags: ["ConfirmationEmail"],
      transformErrorResponse: (response) => response.data,
    }),
    resendConfirmEmail: builder.mutation({
      query: (body) => ({
        url: "/users/confirmation",
        method: "POST",
        body,
      }),
      providesTags: ["ConfirmationEmail"],
      transformErrorResponse: (response) => response.data,
    }),
  }),
});

export const {
  useLoginMutation,
  useSignUpMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useInviteUserMutation,
  useGetUserViaTokenQuery,
  useConfirmEmailQuery,
  useResendConfirmEmailMutation,
} = authApi;

export default authApi;
