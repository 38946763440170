import React, { useContext, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Deletion } from "features/Deletion";
import { Dialog } from "shared/components/Dialog";

import {
  useDeleteWorkOrderMutation,
  useUpdateWorkOrderMutation,
  useGetWorkOrderStatusesQuery,
  usePrintWorkOrdersMutation,
} from "modules/workOrders";
import { useGetAdminConfigurationQuery } from "modules/adminFieldsConfiguration";

import { MenuItem } from "shared/components/MenuItem";
import { MenuList } from "shared/components/MenuList";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { HeaderContextMenu } from "shared/components/Modals";
import { BulkPdfExport } from "shared/components/BulkPdfExport";
import { useFloatingDropdown } from "shared/lib/hooks/useFloatingDropdown";
import { RESPONSE_MESSAGE } from "shared/lib/constants/api";

import { getStatusesOptions } from "../../lib/helpers";
import OpenIcon from "../../assets/icons/open.svg?react";
import HoldIcon from "../../assets/icons/hold.svg?react";
import CompletedIcon from "../../assets/icons/completed.svg?react";
import ProgressIcon from "../../assets/icons/progress.svg?react";
import ReviewIcon from "../../assets/icons/review.svg?react";
import WaitingCostIcon from "../../assets/icons/waitingCost.svg?react";
import WaitingInvoiceIcon from "../../assets/icons/waitingInvoice.svg?react";
import WaitingPartsIcon from "../../assets/icons/waitingParts.svg?react";
import ArrowIcon from "../../assets/icons/arrow.svg?react";
import {
  ButtonEdit,
  ButtonsDropdown,
  StyledButton,
  StyledAdditionalButton,
  ArrowButton,
  Option,
  Statuses,
} from "./WorkOrderHeader.styled";
import PrintWorkOrderExample from "../../assets/PrintWorkOrderExample.svg?react";
import { WorkOrdersPdfPage } from "../WorkOrdersPdfPage";
import { usePermission } from "app/providers/PermissionsProvider";
import { RadioButton, RadioButtonGroup } from "shared/components/RadioButton";
import { Description } from "shared/components/Typography";
import { DateTimePicker } from "shared/components/DatePicker";
import { formatISO } from "date-fns";
import { Button } from "shared/components/Button";
import { totangoStatusFormat } from "modules/workOrders/lib/helpers/totangoFormat";
import { WorkOrderActionType, WorkOrderContext } from "./WorkOrderProvider";

const StatusMap = [
  {
    value: "open",
    icon: <OpenIcon />,
    color: "status_blue",
    borderColor: "status_blue_border",
  },
  {
    value: "on_hold",
    icon: <HoldIcon />,
    color: "status_grey",
    borderColor: "grey600",
  },
  {
    value: "in_progress",
    icon: <ProgressIcon />,
    color: "status_lime",
    borderColor: "status_lime_border",
  },
  {
    value: "in_review",
    icon: <ReviewIcon />,
    color: "status_violet",
    borderColor: "status_violet_border",
  },
  {
    value: "completed",
    icon: <CompletedIcon />,
    color: "status_green",
    borderColor: "primary_text",
  },
];

const AdditionalStatusMap = [
  {
    value: "waiting_for_cost",
    icon: <WaitingCostIcon />,
    color: "status_orange",
  },
  {
    value: "waiting_for_invoice",
    icon: <WaitingInvoiceIcon />,
    color: "status_pink",
  },
  {
    value: "waiting_for_parts",
    icon: <WaitingPartsIcon />,
    color: "status_grey",
  },
];

const STATUS_UPDATE_OPTIONS = Object.freeze({
  NOW: "now",
  PAST: "past",
});

export const WorkOrderHeader = ({
  workOrder,
  onEdit,
  onClose,
  onCompletion,
}) => {
  const {
    id,
    created_by_id: createdById,
    name,
    status,
    technicians,
  } = workOrder;

  const { downtime_tracking: downtimeTracking, procedures } = workOrder || {};

  const {
    state: { allowCompletion },
    dispatch,
  } = useContext(WorkOrderContext);

  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();
  const ref = useRef();
  const [isShowMenu, setIsShowMenu] = useState(false);
  const [assetStatusError, setAssetStatusError] = useState(false);
  const [lastAttemptedStatus, setLastAttemptedStatus] = useState(null);
  const [statusUpdateOption, setStatusUpdateOption] = useState("now");
  const [statusUpdateDate, setStatusUpdateDate] = useState();

  const [deleteWorkOrder] = useDeleteWorkOrderMutation();
  const [updateWorkOrder] = useUpdateWorkOrderMutation();
  const [printWorkOrders] = usePrintWorkOrdersMutation();

  const {
    canDeleteWO,
    woExportPDFPermit,
    canUpdateWO,
    woChangeCompleteStatusPermit,
    canUpdateStatusWO,
  } = usePermission();

  const isDeletePermit = canDeleteWO(createdById, status);

  const { data: statusConfiguration } = useGetAdminConfigurationQuery({
    type: "work_order_status",
  });

  const { data } = useGetWorkOrderStatusesQuery();

  const options = useMemo(
    () =>
      getStatusesOptions(
        StatusMap,
        data,
        statusConfiguration,
        woChangeCompleteStatusPermit
      ),
    [data, statusConfiguration, woChangeCompleteStatusPermit]
  );

  const fullOptions = useMemo(
    () =>
      getStatusesOptions(
        [...StatusMap, ...AdditionalStatusMap],
        data,
        statusConfiguration,
        woChangeCompleteStatusPermit
      ),
    [data, statusConfiguration, woChangeCompleteStatusPermit]
  );

  const selectedOption = fullOptions.find((opt) => opt.key === status.id) || {};

  const { refs, floatingStyles } = useFloatingDropdown({
    isOpen: isShowMenu,
    setIsOpen: setIsShowMenu,
    itemCount: options.length,
  });

  const onBlurHandler = (e) => {
    if (!ref.current.contains(e.relatedTarget)) {
      setIsShowMenu(false);
    }
  };

  const updateWorkOrderStatus = async (
    value,
    overrideAssetStatus = false,
    updatedAssetStatus = null,
    updatedAssetStatusDate = null
  ) => {
    const selectedOption = fullOptions.find((option) => option.key === value);

    if (
      (selectedOption.value === "completed" ||
        selectedOption.value === "in_review") &&
      procedures.some((p) => p.completed_at == null) &&
      !allowCompletion
    ) {
      dispatch({
        type: WorkOrderActionType.showProcedureWarningBanner,
        value: true,
      });

      return;
    }

    let body = {
      status_id: value,
      override_asset_status: overrideAssetStatus,
      asset: {},
    };

    if (updatedAssetStatus !== null) {
      body.asset.status = updatedAssetStatus;
    }

    if (updatedAssetStatusDate !== null) {
      body.asset.datetime = updatedAssetStatusDate;
    }

    try {
      const payload = await updateWorkOrder({ id, body }).unwrap();

      if (payload?.data?.status?.name === "completed") {
        onCompletion();
      }
    } catch (error) {
      const { message } = error;

      if (message === RESPONSE_MESSAGE.offline_asset) {
        setLastAttemptedStatus(value); // enables retrying the action at a later time depending on user's input on the dialog
        setAssetStatusError(true);
      } else {
        const content = Array.isArray(message.base)
          ? t("work_orders.snack.invalidPartQty", { parts: message.base[0] })
          : message;

        addSnackBar({
          title: t("work_orders.snack.failUpdateTitle"),
          content,
          type: "error",
          secondaryButtonLabel: t("close"),
        });
      }
    }
    const newStatus = fullOptions.find((opt) => opt.key === value)?.value;
    window.totango.track(
      `WO Set to ${totangoStatusFormat(newStatus)}`,
      "Web - Work Orders"
    );

    setIsShowMenu(false);
  };

  const onSelectHandler = async (value) => {
    await updateWorkOrderStatus(value);
  };

  const onDialogCancel = () => {
    setLastAttemptedStatus(null);
    setAssetStatusError(false);
  };

  const onDialogConfirm = () => {
    setAssetStatusError(false);

    switch (statusUpdateOption) {
      case STATUS_UPDATE_OPTIONS.NOW:
        return updateWorkOrderStatus(lastAttemptedStatus, false, "online");

      case STATUS_UPDATE_OPTIONS.PAST:
        return updateWorkOrderStatus(
          lastAttemptedStatus,
          false,
          "online",
          statusUpdateDate
        );

      default:
        break;
    }
  };

  return (
    <>
      <Statuses>
        {canUpdateStatusWO(createdById, technicians, status) && (
          <ButtonsDropdown onBlur={onBlurHandler} ref={ref} tabIndex={0}>
            {AdditionalStatusMap.some(
              (s) => s.value === selectedOption.value
            ) ? (
              <StyledAdditionalButton
                variant="secondary"
                icon={selectedOption.icon}
                isSelected={status.id === selectedOption.key}
                onClick={() => onSelectHandler(selectedOption.key)}
                size="small"
              >
                {t(`work_orders.status.${selectedOption.value}`)}
              </StyledAdditionalButton>
            ) : (
              <>
                {options.map((item) => {
                  const { key, value, icon, color, borderColor } = item;
                  return (
                    <StyledButton
                      key={key}
                      variant="secondary"
                      icon={icon}
                      color={color}
                      borderColor={borderColor}
                      isSelected={status.id === key}
                      onClick={() => onSelectHandler(key)}
                      size="small"
                      ref={refs.setReference}
                    >
                      {t(`work_orders.status.${value}`)}
                    </StyledButton>
                  );
                })}
              </>
            )}
            <ArrowButton
              variant="secondary"
              onClick={() => setIsShowMenu((prev) => !prev)}
            >
              <ArrowIcon />
            </ArrowButton>
            <MenuList
              isOpen={isShowMenu}
              category={["Selected"]}
              className="statusesList"
              ref={refs.setFloating}
              style={floatingStyles}
            >
              {fullOptions.map((opt) => (
                <MenuItem
                  key={opt.key}
                  value={opt.key}
                  label={
                    <Option>
                      {opt.icon}
                      {t(`work_orders.status.${opt.value}`)}
                    </Option>
                  }
                  selected={status.id === opt.key}
                  onClick={() => onSelectHandler(opt.key)}
                />
              ))}
            </MenuList>
          </ButtonsDropdown>
        )}
      </Statuses>
      <Box>
        <div>
          {
            <ButtonEdit
              variant="secondary"
              onClick={() => onEdit(id)}
              disabled={!canUpdateWO(createdById, technicians, status)}
            >
              {t("edit")}
            </ButtonEdit>
          }
        </div>
        <HeaderContextMenu disabled={!woExportPDFPermit && !isDeletePermit}>
          {woExportPDFPermit && (
            <BulkPdfExport
              ids={id}
              type="link"
              title={t("printWorkOrders.confirmation.singleTitle")}
              resourceName="WorkOrders"
              getExportResources={printWorkOrders}
              PdfExampleSvg={PrintWorkOrderExample}
              PageComponent={WorkOrdersPdfPage}
            />
          )}
          {isDeletePermit && (
            <Deletion
              id={id}
              module={name}
              onSuccess={onClose}
              confirmation={{
                title: t("work_orders.deletionConfirmation.title"),
                description: t("work_orders.deletionConfirmation.description"),
                confirmButtonLabel: t(
                  "work_orders.deletionConfirmation.confirm"
                ),
              }}
              onDelete={deleteWorkOrder}
            />
          )}
        </HeaderContextMenu>
        {assetStatusError && (
          <Dialog
            title={t("work_orders.assetValidation.title")}
            description={
              <ModalBody>
                <Description>
                  {t("work_orders.assetValidation.description")}
                </Description>
                <RadioButtonGroup value={statusUpdateOption}>
                  <RadioButton
                    value={STATUS_UPDATE_OPTIONS.NOW}
                    onSelect={setStatusUpdateOption}
                  >
                    {t("work_orders.assetValidation.buttons.onlineNow")}
                  </RadioButton>
                  <RadioButton
                    value={STATUS_UPDATE_OPTIONS.PAST}
                    onSelect={setStatusUpdateOption}
                  >
                    {t("work_orders.assetValidation.buttons.onlineSelectDate")}
                  </RadioButton>
                </RadioButtonGroup>
                <DateTimePicker
                  selected={statusUpdateDate}
                  onChange={setStatusUpdateDate}
                  disabled={
                    statusUpdateOption !== "past" ||
                    !downtimeTracking?.started_at
                  }
                  name="status_update_ended_at"
                  showIcon
                  minDateTime={downtimeTracking?.started_at}
                  maxDateTime={formatISO(new Date())}
                />
              </ModalBody>
            }
            actions={
              <Actions>
                <Button variant="secondary" onClick={onDialogCancel}>
                  {t("cancel")}
                </Button>
                <Button
                  onClick={onDialogConfirm}
                  disabled={
                    statusUpdateOption === STATUS_UPDATE_OPTIONS.PAST &&
                    statusUpdateDate == null
                  }
                >
                  {t("apply")}
                </Button>
              </Actions>
            }
          />
        )}
      </Box>
    </>
  );
};

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
  padding: 8px 0;
`;

const Box = styled.div`
  align-items: center;
  display: flex;
  width: 91px;
  gap: 8px;
`;
