import {
  differenceInYears,
  differenceInMonths,
  differenceInWeeks,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";

import { NotTrackingIcon, OfflineIcon, OnlineIcon } from "modules/assets";
import { t } from "i18next";

import {
  StyledBadge,
  OnlineBadge,
  OfflineBadge,
} from "../components/ChangeStatusEditor/ChangeStatusEditor.styled";

export const getStatusIcon = (status, t, height) => {
  let statusIcon;
  if (status === "online") {
    statusIcon = (
      <OnlineBadge iconNode={<OnlineIcon />} height={height}>
        {t(`assets.statuses.${status}`)}
      </OnlineBadge>
    );
  } else if (status === "offline") {
    statusIcon = (
      <OfflineBadge iconNode={<OfflineIcon />} height={height}>
        {t(`assets.statuses.${status}`)}
      </OfflineBadge>
    );
  } else
    statusIcon = (
      <StyledBadge iconNode={<NotTrackingIcon />} height={height}>
        {t(`assets.statuses.${status}`)}
      </StyledBadge>
    );
  return statusIcon;
};

export function getTimePeriod(started_at, ended_at) {
  const startedDate = new Date(started_at);
  const endedDate = new Date(ended_at);

  // round the time in seconds
  startedDate.setSeconds(Math.round(startedDate.getSeconds() / 60) * 60);
  endedDate.setSeconds(Math.round(endedDate.getSeconds() / 60) * 60);

  const years = differenceInYears(endedDate, startedDate);
  const months = differenceInMonths(endedDate, startedDate) % 12;
  const weeks = differenceInWeeks(endedDate, startedDate) % 4;
  const days = differenceInDays(endedDate, startedDate) % 7;
  const hours = differenceInHours(endedDate, startedDate) % 24;
  const minutes = differenceInMinutes(endedDate, startedDate) % 60;

  let formattedTimePeriod = "";
  if (years > 0) formattedTimePeriod += `${years}${t("time_period.years")} `;
  if (months > 0) formattedTimePeriod += `${months}${t("time_period.months")} `;
  if (weeks > 0) formattedTimePeriod += `${weeks}${t("time_period.weeks")} `;
  if (days > 0) formattedTimePeriod += `${days}${t("time_period.days")} `;
  if (hours > 0) formattedTimePeriod += `${hours}${t("time_period.hours")} `;
  if (minutes > 0)
    formattedTimePeriod += `${minutes}${t("time_period.minutes")} `;

  return formattedTimePeriod.trim();
}

export const getNoteText = (status, downtimeType, note, t) => {
  let noteText = "";
  if (status === "offline" && downtimeType != null) {
    noteText = `${t(`assets.bulkActions.status.typeOptions.${downtimeType}`)}${
      note ? `: ${note}` : ""
    }`;
  } else noteText = note;

  return noteText;
};
