import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FilesPicker } from "./FilesPicker";
import { FileList } from "./FileList";
import { ImageList } from "./ImageList";
import { ImageView } from "./ImageView";
import { useLazyDownloadFileQuery } from "modules/fileManager";

import { getFullUrl } from "shared/lib/helpers";

export const FileManager = (props) => {
  const {
    className,
    imageListClassMame,
    name,
    value = [],
    setValue,
    allowedNumberOfFiles = 10,
    withZoom = true,
    withFileDownload = true,
  } = props;

  const [showImageIndex, setShowImageIndex] = useState(null);
  const [files, setFiles] = useState(value);
  const [trigger] = useLazyDownloadFileQuery();

  const imageList = files
    ? files.filter((file) => file.isImage && !file.isRemove)
    : [];
  const fileList = files
    ? files.filter((file) => !file.isImage && !file.isRemove)
    : [];

  useEffect(() => {
    setFiles(value);
  }, [value, setFiles]);

  const handlerSetFiles = (selectedFiles) => {
    setFiles(selectedFiles);
    name ? setValue(name, selectedFiles) : setValue(selectedFiles);
  };

  const handleDeleteFile = (name) => {
    const updatedFilesList = files
      .map((fileObj) => {
        const isRemoveFile = fileObj.name === name;
        if (isRemoveFile) {
          if (fileObj.file) {
            URL.revokeObjectURL(fileObj.file);
            return null;
          } else {
            return { ...fileObj, isRemove: true };
          }
        }
        return fileObj;
      })
      .filter((file) => file);
    setFiles(updatedFilesList);
    handlerSetFiles(updatedFilesList);
  };

  const handleDownloadFile = (name) => {
    const isCurrentFile = (element) => element.name === name;
    const currentFile = fileList.find(isCurrentFile);
    trigger({
      url: getFullUrl(currentFile.download_url),
      filename: currentFile.name,
    });
  };

  const handleShowFile = (name) => {
    const isCurrentFile = (element) => element.name === name;
    setShowImageIndex(files.findIndex(isCurrentFile));
  };

  return (
    <div className={className}>
      {setValue && (
        <FilesPicker
          files={files}
          setFiles={handlerSetFiles}
          allowedNumberOfFiles={allowedNumberOfFiles}
        />
      )}
      <ImageList
        images={imageList}
        onDelete={setValue ? handleDeleteFile : null}
        onView={withZoom && !setValue ? handleShowFile : null}
        className={imageListClassMame}
      />
      <FileList
        files={fileList}
        onDelete={setValue ? handleDeleteFile : null}
        onDownload={!withFileDownload || setValue ? null : handleDownloadFile}
      />
      <ImageView
        files={imageList}
        showFileIndex={showImageIndex}
        setFileIndex={setShowImageIndex}
      />
    </div>
  );
};

FileManager.propTypes = {
  name: PropTypes.string,
  setValue: PropTypes.func,
  errorMessage: PropTypes.string,
};
