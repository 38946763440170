import { t } from "i18next";
import { useGetItemTypesQuery } from "modules/procedures/state/proceduresApi";
import {
  IProcedureTemplateItemOption,
  ProcedureItemType,
} from "modules/procedures/types";
import { useMemo } from "react";
import { Input } from "shared/components/Input";
import { Select } from "shared/components/Select";
import { CustomFormik } from "shared/lib/hooks/useForm";
import styled from "styled-components";
import { MultipleChoiceInput } from "./MultipleChoiceInput";
import { container } from "@test-data";

interface EditorProps {
  form: CustomFormik;
}

export const Editor = ({ form }: EditorProps) => {
  const { data: itemTypes } = useGetItemTypesQuery(null);

  const dropdownOptions = useMemo(() => {
    if (itemTypes === undefined) {
      return [];
    }

    return Object.keys(itemTypes).map((key) => ({
      value: key,
      label: t(`procedures.items.types.${key}`),
    }));
  }, [itemTypes]);

  const currentItemTypeOption = useMemo(
    () =>
      dropdownOptions.find((option) => option.value === form.values.item_type),
    [dropdownOptions, form.values.item_type]
  );

  return (
    <Container data-testid={container.procedureTemplateItem.id}>
      <Input
        name="name"
        value={form.values.name}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        placeholder={t("procedures.items.name")}
        errorMessage={form.touched.name ? form.errors.name?.toString() : null}
        height={50}
        fontSize={20}
        required
        disabled={false}
        aria-label={t("ariaLabel.input.itemName")}
        description={t("procedures.items.name_hint")}
      />

      <Select
        options={dropdownOptions}
        name="item_type"
        value={currentItemTypeOption}
        setValue={(field, value) => form.setFieldValue(field, value?.value)}
        errorMessage={
          form.touched.item_type ? form.errors?.item_type?.toString() : null
        }
        menuPlacement="bottom"
        isSearchable={false}
        placeholder={t("procedures.items.item_type")}
        ariaLabel={t("ariaLabel.input.itemType")}
      />

      {form.values.item_type === ProcedureItemType.MULTIPLE_CHOICE && (
        <MultipleChoiceInput
          form={form}
          onChange={(options: IProcedureTemplateItemOption) => {
            form.setFieldValue("options", options);
          }}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3em;
  height: 500px;
`;
