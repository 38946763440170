import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { skipToken } from "@reduxjs/toolkit/query";
import { EditorModal } from "shared/components/Modals";
import { useGetFormFieldsQuery } from "modules/formFields";
import { useForm } from "shared/lib/hooks/useForm";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";

import { useUpdateRequestMutation, useGetRequestQuery } from "modules/requests";
import { useAddWorkOrderMutation } from "modules/workOrders";

import {
  requestValidationSchema,
  getInitialValues,
  getRequestFormatForService,
  getOptionalFields,
} from "../../lib/helpers/form";

import { RequestEditor } from "../RequestEditor";
import { useSetModal } from "widgets/ModalController";

interface RequestApproveProps {
  isShow: boolean;
  onClose: () => void;
  onOpen: (id: number | string) => void;
  requestId: number | string;
}

export const RequestApprove = ({
  isShow,
  onClose,
  onOpen,
  requestId,
}: RequestApproveProps) => {
  const { t } = useTranslation();
  const setModal = useSetModal();

  const { data: fields } = useGetFormFieldsQuery({
    type: "work_orders",
    action_type: "upsert",
  });

  const { addSnackBar } = useSnackBar();

  const optionalFields = getOptionalFields({ fields, type: "approve" });

  const [addWorkOrder, addWorkOrderRes] = useAddWorkOrderMutation();
  const [updateRequest, updateRequestRes] = useUpdateRequestMutation();

  const { data } = useGetRequestQuery(requestId ?? skipToken);

  const requestDataForForm = useMemo(
    () => getInitialValues({ type: "approve", data }),
    [data]
  );

  const { ...approveRequestForm } = useForm({
    initialValues: requestDataForForm,
    validationSchema: requestValidationSchema(t, optionalFields),
    willUpdatedValues: requestDataForForm,
    enableReinitialize: true,
    onSubmit: async (body) => {
      const workOrder = getRequestFormatForService({
        ...body,
        work_order_request_id: requestId,
        status_id: 1,
        type: "approve",
      });

      try {
        const { data } = await addWorkOrder(workOrder).unwrap();
        const { id: workOrderId, title } = data;
        await updateRequest({
          id: requestId,
          body: {
            status: "approved",
            work_order_id: workOrderId,
          },
        }).unwrap();
        window.totango.track("Request Approved", "Web - Requests");
        window.totango.track("New WO Created", "Web - Work Orders");
        addSnackBar({
          title: t("work_orders.snack.successCreationTitle", {
            module: "Work order",
          }),
          content: t("work_orders.snack.successCreationMessage", {
            name: title,
          }),
          type: "success",
          primaryButtonLabel: t("work_orders.snack.view", {
            module: "work order",
          }),
          secondaryButtonLabel: t("close"),
          handlePrimaryButtonClick: () =>
            setModal({ type: "wo", id: workOrderId }),
        });
        onOpen(requestId);
      } catch (e) {
        const { message } = (e as { message: unknown }) || {};

        addSnackBar({
          title: t("work_orders.snack.failCreationTitle"),
          content: JSON.stringify(message),
          type: "error",
          secondaryButtonLabel: t("close"),
        });
      }
    },
  });

  if (!requestId) {
    return null;
  }

  return (
    <EditorModal
      isOpen={isShow}
      label={t("work_order_requests.approve")}
      onClose={onClose}
      onSubmit={approveRequestForm.handleSubmit}
      disabledSubmit={Object.keys(approveRequestForm.errors).length > 0}
      isSubmitLoading={
        addWorkOrderRes.isLoading ||
        updateRequestRes.isLoading ||
        addWorkOrderRes.isSuccess ||
        updateRequestRes.isSuccess
      }
      form={approveRequestForm}
    >
      <ApproveTitle>{t("work_order_requests.approveTitle")}</ApproveTitle>
      <RequestEditor
        type="approve"
        form={approveRequestForm}
        optionalFields={optionalFields}
      />
    </EditorModal>
  );
};

const ApproveTitle = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 30px;
  color: var(--color_text_color);
  display: block;
`;
