import * as Yup from "yup";
import i18next from "i18next";
import { getFormDataFromJSON } from "shared/lib/helpers/formData";
import {
  getSelectSchema,
  getDescriptionSchema,
} from "shared/lib/helpers/validation";

const RequestPortalCreateInitialValues = {
  title: "",
  description: "",
  welcome_text: i18next.t("request_portals.form.default.welcome_text"),
  instructions: "",
  uploads_attributes: [],
  entity: null,
  allow_anonymous: false,
  required_fields: {
    name: true,
    surname: true,
    email: true,
    phone_number: false,
  },
};

export const getInitialValues = ({ type, data }) => {
  if (type === "create" || !data) {
    return RequestPortalCreateInitialValues;
  }

  const {
    title,
    description,
    welcome_text,
    instructions,
    entity,
    allow_anonymous,
    images = [],
    documents = [],
    required_fields = {},
  } = data;

  const requestPortalEditForm = {
    title: title ?? "",
    description: description ?? "",
    welcome_text: welcome_text ?? "none",
    instructions: instructions ?? "",
    uploads_attributes: images
      .map((image) => ({ ...image, isImage: true }))
      .concat(documents),
    entity: entity ? { value: entity.id, label: entity.name } : null,
    allow_anonymous: allow_anonymous ?? false,
    required_fields,
  };

  return requestPortalEditForm;
};

export const requestPortalValidationSchema = (t) =>
  Yup.object({
    title: Yup.string()
      .required(t("validation.required"))
      .max(255, t("validation.stringMaxLength", { stringMaxLength: 255 })),
    description: getDescriptionSchema(t),
    welcome_text: Yup.string().max(
      1000,
      t("validation.stringMaxLength", { stringMaxLength: 1000 })
    ),
    instructions: Yup.string().max(
      1000,
      t("validation.stringMaxLength", { stringMaxLength: 1000 })
    ),
    entity: getSelectSchema(t, { required: true }),
    allow_anonymous: Yup.boolean(),
  });

export const getRequestPortalFormatForService = (form) => {
  const { uploads_attributes, entity, ...otherFields } = form;

  const data = getFormDataFromJSON({
    ...otherFields,
    entity_id: entity?.value || "",
  });

  data.delete("required_fields");
  data.append("required_fields", JSON.stringify(form.required_fields));

  uploads_attributes.forEach((fileObj, index) => {
    if (fileObj.isRemove) {
      data.append(`uploads_attributes[${index}][remove]`, fileObj.id);
    } else if (fileObj.file) {
      data.append(`uploads_attributes[${index}][upload]`, fileObj.file);
    }
  });

  return data;
};
