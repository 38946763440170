import { ReportKey } from "modules/reporting/types/ReportKey";
import { BacklogByPriorityReportChart } from "./BacklogByPriorityReportChart";
import { BacklogByPriorityReportTable } from "./BacklogByPriorityReportTable";
import { useBacklogByPriorityKPIs } from "./useBacklogByPriorityKPIs";
import { BacklogByPriorityReportActionBar } from "./BacklogByPriorityReportActionBar";
import { ReportPageLayout } from "modules/reporting/components/ReportPageLayout";
import { reportPeriodCurrent } from "modules/reporting/lib/reportPeriodOptions";
import { ReportPageProvider } from "modules/reporting/lib/ReportPageContext";
import { ReportPageTopBar } from "modules/reporting/components/ReportPageTopBar";
import { KPIGrid } from "modules/reporting/components/KPIGrid";
import { useGetChartData } from "modules/reporting/lib/useGetChartData";
import {
  BacklogByPriorityChartData,
  BacklogByPriorityTableData,
} from "./types";
import { useGetTableData } from "modules/reporting/lib/useGetTableData";
import { useDownloadReportXLS } from "modules/reporting/lib/useDownloadReportXLS";
import { ExportMenu } from "modules/reporting/components/ExportMenu";
import { useTranslation } from "react-i18next";
import {
  PDFReportDownloader,
  PDFReportDownloaderHandle,
} from "modules/reporting/components/PDFReportDownloader/PDFReportDownloader";
import { getReportFilename } from "modules/reporting/lib/getReportFilename";
import { useRef } from "react";
import { useFiltersParam } from "shared/lib/hooks";
import { stringifyFilters } from "modules/filters/lib/stringifyFilters";
import { Entity } from "modules/reporting/types/Entity";

const reportKey = ReportKey.BacklogByPriority;
const entity = Entity.workOrders;

export const BacklogByPriorityReport = () => {
  const { t } = useTranslation();
  const kpiRes = useBacklogByPriorityKPIs();
  const chartRes = useGetChartData<BacklogByPriorityChartData[]>(reportKey);
  const tableRes = useGetTableData<BacklogByPriorityTableData[]>(reportKey);

  const [filters] = useFiltersParam([]);
  const stringifiedFilters = stringifyFilters(filters, entity);
  const downloadXLS = useDownloadReportXLS(
    reportKey,
    undefined,
    stringifiedFilters
  );

  const pdfDownloaderRef = useRef<PDFReportDownloaderHandle>(null);

  const isExportReady = !chartRes.isFetching && !kpiRes.isFetching;
  return (
    <ReportPageProvider
      value={{ reportPeriod: reportPeriodCurrent, setReportPeriod: () => null }}
    >
      <ReportPageTopBar />
      <ReportPageLayout
        reportKey={reportKey}
        kpis={<KPIGrid isLoading={kpiRes.isFetching} kpis={kpiRes.data} />}
        chart={
          <BacklogByPriorityReportChart
            data={chartRes.data}
            isFetching={chartRes.isFetching}
          />
        }
        actionBar={
          <BacklogByPriorityReportActionBar
            buttons={
              <ExportMenu
                downloadXLS={() => downloadXLS()}
                downloadPDF={() => pdfDownloaderRef.current?.doDownload()}
                isPDFReady={isExportReady}
              />
            }
            entity={entity}
          />
        }
        table={
          <BacklogByPriorityReportTable
            data={tableRes.data?.data}
            isFetching={tableRes.isFetching}
          />
        }
      />
      <PDFReportDownloader
        ref={pdfDownloaderRef}
        filename={getReportFilename(reportKey, "pdf")}
        exportProps={{
          reportName: t(`reporting.${reportKey}.title`),
          reportPeriod: reportPeriodCurrent,
          kpis: kpiRes.data,
        }}
        charts={[
          {
            title: t(`reporting.${reportKey}.title`) as string,
            chart: (
              <div style={{ width: "1000px", height: "600px" }}>
                <BacklogByPriorityReportChart
                  data={chartRes.data}
                  isFetching={chartRes.isFetching}
                />
              </div>
            ),
          },
        ]}
      />
    </ReportPageProvider>
  );
};
