import { t } from "i18next";

export const priorityOptions = [
  { value: "none", label: t("work_orders.priority.none") },
  {
    value: "low",
    label: t("work_orders.priority.low"),
  },
  {
    value: "medium",
    label: t("work_orders.priority.medium"),
  },
  {
    value: "high",
    label: t("work_orders.priority.high"),
  },
  {
    value: "critical",
    label: t("work_orders.priority.critical"),
  },
];

export const PortalFormOptionalFields = [
  "description",
  "priority",
  "location_id",
  "asset_id",
  "categories_ids",
  "due_date",
  "assign_to",
  "vendors_ids",
];

export const PortalCreateInitialValues = {
  title: "",
  description: "",
  notify: false,
  priority: "none",
  categories: [],
  due_date: "",
  uploads_attributes: [],
  location: null,
  asset: null,
  vendors: [],
  assign_to: [],
  portal_user_email: "",
  portal_user_name: "",
  portal_user_surname: "",
  portal_user_phone_number: "",
};
