import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { H3 } from "shared/components/Typography";

export const ListOfDetails = ({
  className,
  title = "Details",
  subTitle,
  rows,
  RightSideNode,
}) => {
  const dataRows = rows.map(({ label, value, multiline }) => (
    <Row key={label} multiline={multiline}>
      <span>{label}</span>
      <span>{value}</span>
    </Row>
  ));

  return (
    <div className={className}>
      <Header>
        <H3>{title}</H3>
        {subTitle && <Subtitle>{subTitle}</Subtitle>}
      </Header>
      <Container>
        <Table>{dataRows}</Table>
        {RightSideNode && <SideContent>{RightSideNode}</SideContent>}
      </Container>
    </div>
  );
};

ListOfDetails.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    })
  ),
  RightSideNode: PropTypes.node,
};

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;
`;

const Subtitle = styled.span`
  color: var(--color_background);
  opacity: 0.5;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin-left: auto;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  gap: 16px;
  margin-top: 18px;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  align-items: ${({ multiline }) => (multiline ? "flex-start" : "center")};

  & > *:nth-child(odd) {
    flex-basis: 110px;
    flex-grow: 0;
    flex-shrink: 0;
    ${({ multiline }) =>
      multiline
        ? css`
            margin-top: 4px;
          `
        : null}
  }

  & > *:nth-child(even) {
    flex-grow: 1;
    flex-shrink: 1;
  }

  & > span:nth-child(odd) {
    color: var(--color_grey500);
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  }

  & > span:nth-child(even) {
    color: var(--color_grey900);
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }
`;

const SideContent = styled.div`
  margin-top: 20px;
  margin-left: auto;
  flex-grow: 0;
  flex-shrink: 0;
`;
